import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "./assets/contact-details.css";
// import "./assets/modalDetails.css";
// import "./assets/modalNote.css";
// import "./assets/trigger.css";
import "./assets/global__contact_details_modal.css";
import EmailSendModal from "./modals/SendModules/EmailSend";
import SmsSendModal from "./modals/SendModules/SmsSend";
import VoiceSendModal from "./modals/SendModules/VoiceSend";
// import SmsSend from "./modals/SendModules/SmsSend";
import UpdateContactInfo from "./modals/UpdateContactInfo";
import BasicInfo from "./partition/BasicInfo";
import Header from "./partition/Header";
import MoreInfo from "./partition/MoreInfo";
import { AddNote } from "./partition/Note";
import { getContactDetails, _updateContactDetails, _updateStateField } from "./redux/contactDetailAction";


const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    /* for middleware */
    getContactDetails : (params, callback) => dispatch(getContactDetails(params, callback)),
    /* for reducer */
    _updateContactDetails: params => dispatch(_updateContactDetails(params)),
    _updateStateField: params => dispatch(_updateStateField(params)),

})

const ContactDetailApp = props => {

    const {
        contactId, contactDetails, fetchingContactDetail, deletingContact,
        showingSmsSendModal, showingEmailSendModal, showingVoiceSendModal,
        showingUpdateContactModal, tab
    } = props.contactDetailReducer

    useEffect(() => {
        props.getContactDetails({
            id: contactId
        })
    },[contactId])

    useEffect(() => {
        if(JSON.stringify(contactDetails) !== '{}'){
            let title = 'Contact Details'
            if(
                (contactDetails.first_name !== null && contactDetails.first_name !== '') && 
                (contactDetails.last_name !== null && contactDetails.last_name !== '')
            ){
                title = contactDetails.first_name + ' ' + contactDetails.last_name
            }
            else if(contactDetails.first_name !== null && contactDetails.first_name !== ''){
                title = contactDetails.first_name
            }
            else if(contactDetails.last_name !== null && contactDetails.last_name !== ''){
                title = contactDetails.last_name
            }
            else if(contactDetails.email !== null && contactDetails.email !== ''){
                title = contactDetails.email
            }
            else if(contactDetails.number !== null && contactDetails.number !== ''){
                title = contactDetails.number
            }
            props.handleTitle(title)
        }
    }, [contactDetails])

    if(contactId === null || contactId === ''){
        return null;
    }

    const handleSmsSendModal = () => {
        props._updateStateField({showingSmsSendModal: false})
    }

    const handleEmailSendModal = () => {
        props._updateStateField({showingEmailSendModal: false})
    }

    const handleVoiceSendModal = () => {
        props._updateStateField({showingVoiceSendModal: false})
    }

    return (
        <Fragment>
            <div className={`contact-details-container v2 ${(deletingContact || fetchingContactDetail) ? 'loader-container' : ''}`}>

                {/* header part */}
                <Header />

                {/* basic info part */}
                {tab === 'contact' &&
                <BasicInfo />
                }

                {tab === 'note' &&
                    <AddNote />
                }

                {/* more info part */}
                {(tab === 'contact' && !fetchingContactDetail) &&
                <MoreInfo closeModal={props.closeModal} from={(props.from !== undefined && props.from != null) ? props.from : ''}/>
                }

                {/* ------modal part------- */}

                {/* send sms */}
                {showingSmsSendModal &&
                <SmsSendModal 
                    open={showingSmsSendModal} 
                    onClose={handleSmsSendModal} data={contactDetails} 
                />
                }

                {/* send email */}
                {showingEmailSendModal &&
                <EmailSendModal 
                    open={showingEmailSendModal} 
                    onClose={handleEmailSendModal} 
                    data={contactDetails} 
                />
                }
                
                {/* send voice */}
                {showingVoiceSendModal &&
                <VoiceSendModal 
                    open={showingVoiceSendModal} 
                    onClose={handleVoiceSendModal} 
                    data={contactDetails} 
                />
                }
                {/* update contact details modal */}
                {showingUpdateContactModal &&
                <UpdateContactInfo 
                    open={showingUpdateContactModal} 
                    onClose={() => {
                        props._updateStateField({showingUpdateContactModal: false})
                    }} 
                    data={contactDetails}
                />
                }
            </div>
        </Fragment>
    )
}
const ContactDetail = connect(mapStateToProps, mapDispatchToProps)(ContactDetailApp);
export default ContactDetail;