import {Button, InputBase, makeStyles, withStyles} from "@material-ui/core";

export const ContactDetailsStyle = makeStyles((theme) => ({
    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },

    flexCenter: {
        display: "flex",
        alignItems: "center",
    },

    flexCenterBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    alignJustifyCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    primaryButton: {
        textTransform: "none",
        background: "var(--light_blue)",
        "&:hover, &:focus": {
            background: "var(--light_blue)",
        },
    },

    primaryButtonRounded: {
        textTransform: "none !important",
        background: "var(--light_blue) !important",
        "&:hover, &:focus": {
            background: "var(--light_blue) !important",
        },
        borderRadius: "25px !important",
    },

    secondaryButton: {
        color: "white",
        textTransform: "none",
        // background: "#00ff91",
        // "&:hover, &:focus": {
        //   background: "#00ff91",
        // },
        background: "#a352c2",
        "&:hover, &:focus": {
            background: "#a352c2",
        },
    },

    secondaryButtonRounded: {
        color: "white",
        textTransform: "none",
        // background: "#00ff91",
        // "&:hover, &:focus": {
        //   background: "#00ff91",
        // },
        background: "#a352c2",
        "&:hover, &:focus": {
            background: "#a352c2",
        },

        borderRadius: 25,
    },

    closeButton: {
        textTransform: "none",
        color: "#ffffff",
        background: "var(--red)",
        "&:hover, &:focus": {
            background: "var(--red)",
        },
    },

    textDarkBlue: {
        color: "var(--dark_blue)",
    },

    textBlueMapContactColor:{
        color: "#3a414a"
    },

    linkUnderLine: {
        color: "var(--light_blue)",
        textDecoration: "underline",
        fontWeight: 500,
    },

    primaryChip: {
        background: "var(--light_blue)",

        // Alternate Design
        // background: "#0e0f3f",

        // "& .MuiChip-deleteIcon": {
        //   color: "var(--light_blue)",
        // },
    },

    titleIcon: {
        cursor: "pointer",

        "& svg": {
            fontSize: 18,
        },

        "&:focus, &:hover ": {
            background: "transparent",
        },
    },

    draggableWrap: {
        position: "relative",
        "& .MuiIconButton-sizeSmall": {
            visibility: "hidden",
            position: "absolute",
            left: -25,
        },

        "&:hover .MuiIconButton-sizeSmall": {
            visibility: "visible",
        },
    },
    textBold600: {
        fontWeight: 600
    },
    bgDarkBlue: {
        backgroundColor: "#0e0f3"
    },
    dispostionButton:{
        background:'#316aff !important',
        width:'300px !important',
        "&:hover,&:focus":{
            background:'#316aff !important', 
        }
    },
    selectActionButton:{
        background:'#00ff91 !important',
        width:'300px !important',
        color:'#0e0f3f !important',
        "&:hover,&:focus":{
            background:'#00ff91 !important',
        }
    },
    footer: {
        height: "93px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap:'20px'
    },
    checkDNC:{
        color:'black',
        background:'rgb(0, 255, 145)',
        padding:'1px 14px',
        borderRadius:'20px',
        "&:hover , &:focus":{
            background:'rgb(0, 255, 145)',
        }
    }
}));

export const BasicInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: "relative",
        color: "var(--dark_blue)",
        fontSize: "14px !important",
        margin: "0 !important",
        height: "3rem !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },
    },
}))(InputBase);


export const BasicInputSelect = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: (props) => {
        const { padding } = props;

        return {
            position: "relative",
            color: "var(--dark_blue)",
            fontSize: ".8vw",
            margin: "0 !important",
            padding: padding || "10px 12px 10px !important",
            borderRadius: "4px !important",
            border: "1px solid #ced4da !important",
            transition: theme.transitions.create(["border-color", "box-shadow"]),

            "&:focus": {
                borderRadius: 4,
                boxShadow: "unset !important",
            },
        };
    },
}))(InputBase);

export const BasicButton = withStyles((theme)=>({
    root: {
        "&.MuiButton-root": {
            padding: '10px 20px',
            borderRadius: '4px'
        },
        "&.MuiButton-text": {
            color: "grey"
        },
        "&.MuiButton-contained": {
            color: "white"
        },
        "&.MuiButton-outlined": {
            color: "brown"
        }
    }
}))(Button);