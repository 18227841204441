import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import { makeStyles } from '@material-ui/core';
import { getCustomFieldData } from './redux/videoEmailAction';
import { fetchCustomField } from './redux/videoEmailApi';


const CONTACT_EMAIL = 'CONTACT EMAIL';
const FIRST_NAME = 'FIRST NAME';
const LAST_NAME = 'LAST NAME';
const MY_VIRTUAL_NUMBER = 'My virtual number';
const MY_COMPANY = 'My Company';
const MY_NAME = 'My Name';
const MY_FIRST_NAME = 'My First Name';
const MY_LAST_NAME = 'My Last Name';
const MY_PHONE = 'My Phone';
const MY_SIGNATURE = 'My Signature';
const MY_APPOINTMENT_URL = 'My Appointment URL';
const TODAY = 'Today Date';
const TOMORROW = 'Tomorrow Date';
const CONTACT_COUNTRY = 'Property Country';
const CONTACT_STATE = 'Property State';
const CONTACT_STREET_ADDRESS = 'Property Street Address';
const CONTACT_URL = 'Property URL';
const CONTACT_ZIP_CODE = 'Property Zip Code';
const CONTACT_PHONE_NUMBER = 'Contact Phone Number';
const CONTACT_CITY = 'Contact City';
const CONTACT_DEAL_VALUE = 'Contact Deal Value';
const CONTACT_BIRTH_DATE = 'Contact Birth Date';
const CONTACT_ANNIVERSARY_DATE = 'Contact Anniversary Date';
const CONTACT_COMPANY_NAME = 'Contact Company Name';

export const withOutCustomsFields = [
    {
        label : FIRST_NAME ,
        value : '[[first_name]]'
    },
    {
        label : LAST_NAME ,
        value :  '[[last_name]]'
    },
    {
        label : CONTACT_EMAIL,
        value : '[[email]]'
    },
    {
        label : CONTACT_PHONE_NUMBER,
        value : '[[contact_phone_number]]'
    },
    {
        label : MY_VIRTUAL_NUMBER,
        value : '[[virtual_number]]'
    },
    {
        label : MY_COMPANY ,
        value : '[[company]]'
    },
    {
        label : MY_NAME ,
        value : '[[my_name]]'
    },
    {
        label : MY_FIRST_NAME ,
        value : '[[my_first_name]]'
    },
    {
        label : MY_LAST_NAME ,
        value : '[[my_last_name]]'
    },
    {
        label : MY_PHONE ,
        value : '[[my_phone]]'
    },
    {
        label :MY_SIGNATURE,
        value  : '[[my_signature]]'
    },
    {
        label :MY_APPOINTMENT_URL,
        value  : '[[appointment_url]]'
    },
    {
        label : TODAY,
        value  : '[[today]]'
    },
    {
        label : TOMORROW,
        value  : '[[tomorrow]]'
    },
    {
        label : CONTACT_COUNTRY,
        value  : '[[contact_country]]'
    },
    {
        label : CONTACT_STATE,
        value : '[[contact_state]]'
    },
    {
        label : CONTACT_CITY,
        value : '[[contact_city]]'
    },
    {
        label : CONTACT_STREET_ADDRESS,
        value : '[[contact_street_address]]'
    },
    {
        label : CONTACT_COMPANY_NAME,
        value : '[[contact_company_name]]'
    },
    {
        label : CONTACT_URL,
        value : '[[contact_url]]'
    },
    {
        label :CONTACT_ZIP_CODE,
        value : '[[contact_zip_code]]'
    },
    {
        label :CONTACT_DEAL_VALUE,
        value : '[[contact_deal_value]]'
    },
    {
        label :CONTACT_BIRTH_DATE,
        value : '[[contact_birth_date]]'
    },
    {
        label :CONTACT_ANNIVERSARY_DATE,
        value : '[[contact_anniversary_date]]'
    },
    {
      value: 'blue',
      label: 'Contact Customize Fields',
      color: '#f2f2f2',
      isDisabled: true
    }

];

const useStyles = makeStyles((theme) => ({
    formControl: {
      height : "100%"
    },
    root: {
      height : "100%"
    },
    menuPaper: {
        maxHeight: 350
    }
}));

const CustomSelectBox = (props) => {
    const classes = useStyles();
    const [customFieldData, setCustomFieldData] = useState([]);

    let propsData = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        getContentAnchorEl: null,
        classes: { paper: classes.menuPaper }
    }
    useEffect(() => {
        fetchCustomField().then(res => {
            setCustomFieldData(res.data.data);
        });
    }, []);


    const handlePersonalize = (options) =>{
        // It will return the value of personalize tag
        this.props.handlePersonalize(options.value);
    }

    const renderCustomFields = () => {
        if (customFieldData.length) {
            let customFieldOptions = [<MenuItem value="system_defined" disabled={true}>System Defined</MenuItem>];

            withOutCustomsFields.map((row, index) => {
                customFieldOptions.push(
                    <MenuItem value={row.value}>{row.label}</MenuItem>
                );
            });

            customFieldOptions.push(
                <MenuItem value="customize_field" disabled={true}>Contact Customize Fields</MenuItem>
            );
            customFieldData.map((row, index) => {
                customFieldOptions.push(
                    <MenuItem value={row.personalize_tag}>{row.title}</MenuItem>
                );
            });

            return customFieldOptions;
        }
    }

    return (
        <React.Fragment>
            <FormControl 
                className={"form-control " + (props.outlined === true ? classes.formControl : '')}
            >
                <Select
                    labelId="personalize-select-label"
                    id="personalize-select"
                    value={'personalize'}
                    onChange={props.handlePersonalize}
                    variant={props.outlined === true ? 'outlined' : 'standard'}
                    className={`custom-select-container ${props.outlined === true ? classes.root : ''}`}
                    displayEmpty
                    MenuProps={propsData}
                >
                    <MenuItem value="personalize" disabled={true}>Personalize</MenuItem>
                    {renderCustomFields()}
                </Select>
            </FormControl>
        </React.Fragment>

    );

}

export default CustomSelectBox;