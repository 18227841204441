import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { BasicButton, ContactDetailsStyle } from "../ContactDetailsStyle";
import Disposition from "./disposition/Disposition";
import GlobalDropdown, {
  GlobalDropDownButton,
  GlobalDropDownItem,
} from "../../../globals/Dropdown/GlobalDropdown";
import { IconList } from "../../../../constants/IconList";
import { saveContactFromMapSidebar } from "../../../../api/mapApi";
import ModalAssignToList from "../../enhanceDataAction/ModalAssignToList";
import ModalListForStageAdd from "../../enhanceDataAction/ModalListForStageAdd";
import ModalAssignToTagNew from "../../enhanceDataAction/ModalAssignToTag";
import ModalAssignToCampaign from "../../enhanceDataAction/ModalAssignToCampaign";
import ModalAssignToPowerDialer from "../../enhanceDataAction/ModalAssignToPowerDialer";
import SelectActionForContactScreen from "./SelectActionForContactScreen";
const useStyles = makeStyles({
  disableButton: {
    color: "black !important",
  },
  saveButton: {
    background: "#006df5 !important",
    color: "white !important",
    "&:hover": {
      background: "#006df5 !important",
    },
  },
});

const Footer = (props) => {
  const { state, markerContactDetails, handleCloseModal } = props;
  const { bgDarkBlue, footer, dispostionButton, selectActionButton } =ContactDetailsStyle();
  const [openDispositionModal, setOpenDispositionModal] = useState(false);
  const [dispositionId, setDispositionId] = useState(null);
  const [dispositionName, setDispositionName] = useState("");
  const [dispositionIcon, setDispositionIcon] = useState("");
  const [isOpenActionModal, setIsOpenActionModal] = useState(false);



  const classes = useStyles();
  return (
    <Box
    sx={{display:'flex',justifyContent:'center',alignItems:'center',background: "#0e0f3f !important" ,width:'100%',height:'100%',gap:'10px'}}
    >
      <BasicButton
        className={dispostionButton}
        disableElevation
        variant="contained"
        onClick={() => {
          setOpenDispositionModal(true);
        }}
      >
        <Typography style={{ fontSize: "1vw" }}>Select Disposition</Typography>
      </BasicButton>
      <BasicButton
        className={selectActionButton}
        disableElevation
        variant="contained"
        onClick={() => {
          setIsOpenActionModal(true);
        }}
      >
        <Typography style={{ fontSize: "1vw" }}>Select an Action</Typography>
      </BasicButton>
      {/* <GlobalDropdown className="add__action_dropdown" placement="top">
          <GlobalDropDownButton>
            <BasicButton
              variant="contained"
              className={selectActionButton}
              disableElevation
            >
              <Typography
                style={{ fontSize: "1vw", color: "var(--dark_blue) !important" }}
              >
                Select an Action
              </Typography>
            </BasicButton>
          </GlobalDropDownButton>
          <GlobalDropDownItem
            onClick={handleAddToConnectModalOpen}
            title="Add Contact To CRM"
            onClickHide={true}
            icon={IconList.addToContactSVG}
          />
          <GlobalDropDownItem
            onClick={() => {
              setOpenAddToListModal(true);
            }}
            title="Add To List"
            onClickHide={true}
            icon={IconList.addToListSVG}
          />
          <GlobalDropDownItem
            onClick={() => {
              setOpenAddToCampaignModal(true);
            }}
            title="Add To Campaign"
            onClickHide={true}
            icon={IconList.addToContactSVG}
          />
          <GlobalDropDownItem
            onClick={() => {
              setOpenAddToTagModal(true);
            }}
            title="Assign Tag"
            onClickHide={true}
            icon={IconList.addTagSVG}
          />
          <GlobalDropDownItem
            onClick={() => {
              setOpenAddToStageModal(true);
            }}
            title="Add to Pipeline Stage"
            onClickHide={true}
            icon={IconList.addToPipelineSVG}
          />
          <GlobalDropDownItem
            onClick={() => {
              setOpenAddToPowerDialerModal(true);
            }}
            title="Add To Power Dialer List"
            onClickHide={true}
            icon={IconList.addToContactSVG}
          />
        </GlobalDropdown> */}

      {openDispositionModal && (
        <Disposition
          setDispositionId={setDispositionId}
          setDispositionName={setDispositionName}
          setDispositionIcon={setDispositionIcon}
          handleCloseModal={handleCloseModal}
          markerContactDetails={markerContactDetails}
          addSuccess={props.addSuccess}
          isOpen={openDispositionModal}
          onClose={() => {
            setOpenDispositionModal(false);
          }}
        />
      )}
{/* 
      {openAddToListModal && (
        <ModalAssignToList
          open={openAddToListModal}
          onClose={() => setOpenAddToListModal(false)}
          markerContactDetails={markerContactDetails}
          handleCloseModal={handleCloseModal}
        />
      )}
      {openAddToTagModal && (
        <ModalAssignToTagNew
          open={openAddToTagModal}
          onClose={() => setOpenAddToTagModal(false)}
          markerContactDetails={markerContactDetails}
          handleCloseModal={handleCloseModal}
        />
      )}

      {openAddToStageModal && (
        <ModalListForStageAdd
          open={openAddToStageModal}
          onClose={() => setOpenAddToStageModal(false)}
          markerContactDetails={markerContactDetails}
          handleCloseModal={handleCloseModal}
        />
      )}

      {openAddToCampaignModal && (
        <ModalAssignToCampaign
          dispositionId={dispositionId}
          dispositionName={dispositionName}
          dispositionIcon={dispositionIcon}
          state={state}
          markerContactDetails={markerContactDetails}
          open={openAddToCampaignModal}
          onClose={() => setOpenAddToCampaignModal(false)}
          handleCloseModal={handleCloseModal}
        />
      )}

      {openAddToPowerDialerModal && (
        <ModalAssignToPowerDialer
          open={openAddToPowerDialerModal}
          markerContactDetails={markerContactDetails}
          onClose={() => setOpenAddToPowerDialerModal(false)}
          handleCloseModal={handleCloseModal}
        />
      )} */}

      <SelectActionForContactScreen
        markerContactDetails={markerContactDetails}
        isOpenActionModal={isOpenActionModal}
        setIsOpenActionModal={setIsOpenActionModal}
        handleCloseModal={handleCloseModal}
        addSuccess={props.addSuccess}
      />
    </Box>
  );
};

export default Footer;
