import React, {useEffect, useState, useRef} from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { MenuItem, Select } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CustomAudioPlayer from './CustomAudioPlayer';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import _ApiList from '../api/ApiList';
import TimeSetting from '../assets/components/TimeSettings';
// import '../assets/smsSend.css';
import "../../assets/global__send_modal.css";
import moment from "moment";
import DynamicSkeleton from '../../../map/contactDetailsV2/common/DynamicSkeleton';
import BasicSelect from '../../../Common/custom/BasicSelect';
let IntervalTime = '';
let recordAudio;
let videoStream;
var totalSeconds = 0;

const VoiceSend = props => {
    const uploadFileDiv = useRef(null)
    const uploadFileDivData = useRef(null)

    const [virtualNumbers, setVirtualNumbers] = useState([])
    const [selectedVn, setselectedVn] = useState('')
    const [fetchingVn, setFetchingVn] = useState(true)

    const [title, setTitle] = useState('')

    const [templates, setTemplates] = useState([])
    const [fetchingTemplate, setFetchingTemplate] = useState(false)
    const [showTemplate, setShowTemplate] = useState(false)
    const [selectedAudioTemplateId, setSelectedAudioTemplateId] = useState('')
    const [selectedAudioTemplate, setSelectedAudioTemplate] = useState('')
    const [playingAudioId, setPlayingAudioId] = useState('')
    const [countTime, setCountTime] = useState(0)
    const [recording, setRecording] = useState(false)
    const [audioUrl, setAudioUrl] = useState('')
    const [audioFile, setAudioFile] = useState(null)
    const [audioFileUpload, setAudioFileUpload] = useState(false)

    const [submitting, setSubmitting] = useState(false)

    const [nextStepMap, setNextStepMap] = useState(false)

     /* time setting data */
     const [sendTime, setSendTime] = useState('1') //1 = immediate, 2 = custom
     const [date, setDate] = useState(new Date())
     const [time, setTime] = useState(null)
     const [isValidTime, setIsValidTime] = useState(true)

    useEffect(() => {
        _ApiList.getVittualNumbers().then(res => {
            setVirtualNumbers(res.data.virtual_number)
            setFetchingVn(false)
        })
        return () => {
            // stop recording if running
            if(recordAudio != undefined){
                setRecording(false)
                recordAudio.stopRecording(() => {
                    videoStream.getTracks().forEach(track => track.stop());
                }); 
            }
            // clear interval if exist
            if(IntervalTime !== ''){
                clearInterval(IntervalTime)
                IntervalTime=''
            }
        }
    }, [])

    const getTemplate = () => {
        setFetchingTemplate(true)
        _ApiList.getVoiceTemplates().then(res => {
            setFetchingTemplate(false)
            setTemplates(res.data)
        })
    }

    const handleShowNotification = (message, type="SUCCESS") => {
        window.showNotification(type, message)
    }

    const handleUseTemplate = (value) => {
        if(recording){
            handleShowNotification('Stop recording first!', 'ERROR')
            return
        }
        setSelectedAudioTemplateId('')
        setSelectedAudioTemplate('')
        setShowTemplate(value)
        if(value) getTemplate()
    }

    const handleTemplateSelect = (item) => {
        setSelectedAudioTemplateId(item.id)
        setSelectedAudioTemplate(item.file)
    }

    const handleTimesetting = (type, value) => {
        if(type === 'date'){
            setDate(value)
        }
        else if(type === 'time'){
            setTime(value)
        }
        else if(type === 'tab'){
            setSendTime(value)
        }
    }

    const renderSelectedContact = () => {
        if(!props.showSelectedContact){
            return null;
        }
        return(
            <div>
                <p>Selected Contact</p>
                <div>1224451278, 89971084, 102847</div>
            </div>
        )
    }

    const renderTimeSetting = () => {
        if(!props.showTimeSetting){
            return null;
        }
        return(
            <div className="gloabal-send-module-time-setting-container">
                <TimeSetting
                    isValidTime={(!isValidTime && time == null)}
                    timeValidationMessage={'Date and Time is required'}
                    handleTimesetting={handleTimesetting}
                />
            </div>
        )
    }

    const renderMain = () => {
        return (
          <div>
            {fetchingVn ? (
              <>
                <DynamicSkeleton count={1} />
                <DynamicSkeleton count={1} height="80px" />
                <DynamicSkeleton count={1} />
                <DynamicSkeleton count={1} height="120px" />
              </>
            ) : (
              <>
                {renderSelectedContact()}

                <div className="" style={{paddingBottom:"35px",display:"flex"}}>
                  <span style={{paddingTop:"8px",fontSize:"16px",paddingRight:"5px"}}>Select virtual number</span>
                  <div style={{width:"220px"}}>
                    {/* <Select
                      className="category_select input-field border-1 px-2"
                      displayEmpty
                      value={selectedVn}
                      onChange={(event) => setselectedVn(event.target.value)}
                      anchorEl={null}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select a virtual number
                      </MenuItem>
                      {virtualNumbers.map((item, index) => {
                        return (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select> */}
                    <BasicSelect
                        defaultText='Select a virtual Number'
                        value={selectedVn}
                        options={virtualNumbers}
                        onChange={(event) => setselectedVn(event.target.value)}
                        mapping={{
                          label: "label",
                          value: "value",
                        }}
                    />
                  </div>
                </div>

                <div className="d-flex voice-mail-toolbar-tab">
                  <div
                    className={`voice-record ${showTemplate ? "" : "active"}`}
                    onClick={() => handleUseTemplate(false)}
                  >
                    Record a message
                  </div>
                  <div
                    className={`ml-3 voice-pre-template ${
                      showTemplate ? "active" : ""
                    }`}
                    onClick={() => handleUseTemplate(true)}
                  >
                    Pre-created template
                  </div>
                </div>

                {!showTemplate && (
                  <div>
                    <div className="bg-light-blue-10 mt-3 p-3 radius-10 record_message_template">
                      <h6 className="text-dark-blue m-0 text-left">
                        Record/Upload a new audio file
                      </h6>
                      <div className="row mt-3 mb-0">
                        <div className="col s12 m8 l8 xl8 pl-0">
                          <div className="d-flex align-items-center">
                            <div
                              className="voice_mail_icon custom-cursor-pointer"
                              onClick={openMic}
                              style={
                                !recording ? { opacity: 1 } : { opacity: 0.3 }
                              }
                            >
                              <span className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                <i className="material-icons">mic_none</i>
                              </span>
                              <p className="text-gray">Record</p>
                            </div>
                            <div
                              className="voice_mail_icon ml-3 custom-cursor-pointer"
                              onClick={stopRecord}
                              style={
                                recording ? { opacity: 1 } : { opacity: 0.3 }
                              }
                            >
                              <span className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                <svg
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.7547 7.99414H8.25913C8.12756 7.99414 8.01953 8.09732 8.01953 8.23097V13.7861C8.01953 13.917 8.12687 14.0243 8.25913 14.0243H13.7547C13.8863 14.0243 13.9929 13.9177 13.9929 13.7861V8.23097C13.9922 8.09801 13.8863 7.99414 13.7547 7.99414Z"
                                    fill="#FF264A"
                                  />
                                  <path
                                    d="M11.0061 0.592163C5.2606 0.592163 0.601562 5.24981 0.601562 10.9967C0.601562 16.7437 5.2606 21.4013 11.0061 21.4013C16.7524 21.4013 21.4093 16.7437 21.4093 10.9967C21.4093 5.24981 16.7524 0.592163 11.0061 0.592163ZM11.0061 19.6632C6.21969 19.6632 2.33901 15.7825 2.33901 10.9967C2.33901 6.2089 6.21969 2.32891 11.0061 2.32891C15.7933 2.32891 19.6719 6.2089 19.6719 10.9967C19.6719 15.7825 15.7933 19.6632 11.0061 19.6632Z"
                                    fill="#FF264A"
                                  />
                                </svg>
                              </span>
                              <p className="text-gray">Stop</p>
                            </div>
                            <div
                              className="voice_mail_icon ml-3 custom-cursor-pointer"
                              style={
                                !recording ? { opacity: 1 } : { opacity: 0.3 }
                              }
                            >
                              <label className="custom-cursor-pointer bg-light-blue-10 circle d-flex justify-content-center align-items-center">
                                <input
                                  disabled={recording ? true : false}
                                  type="file"
                                  ref={uploadFileDiv}
                                  className="d-none"
                                  accept="audio/mp3, video/webm"
                                  onChange={uploadFile}
                                />
                                <svg
                                  width="27"
                                  height="22"
                                  viewBox="0 0 27 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.77493 7.41407C5.98568 2.59081 10.8771 -0.337687 15.7004 0.873064C19.4697 1.81927 22.2066 5.07698 22.4886 8.95291C25.1649 9.39426 26.9767 11.9216 26.5354 14.5979C26.143 16.9772 24.0814 18.7197 21.67 18.7101H17.5772V17.073H21.67C23.4783 17.073 24.9442 15.6071 24.9442 13.7988C24.9442 11.9905 23.4783 10.5245 21.67 10.5245C21.2179 10.5245 20.8514 10.1581 20.8514 9.70598C20.8555 5.63728 17.5605 2.33562 13.4918 2.33158C9.96971 2.32805 6.93782 4.81801 6.25653 8.2735C6.18925 8.61853 5.90925 8.8821 5.56075 8.92835C3.32291 9.24703 1.76719 11.3195 2.08581 13.5573C2.37195 15.5667 4.08765 17.0629 6.11737 17.073H9.39161V18.7101H6.11737C2.95282 18.7006 0.395231 16.1274 0.40485 12.9629C0.41283 10.3286 2.21591 8.03919 4.77493 7.41407Z"
                                    fill="#3C7EF3"
                                  />
                                  <path
                                    d="M14.0656 10.7619L17.3398 14.0361L16.1857 15.1903L14.303 13.3158V21.1657H12.6659V13.3158L10.7914 15.1903L9.6372 14.0361L12.9114 10.7619C13.2307 10.4445 13.7463 10.4445 14.0656 10.7619Z"
                                    fill="#3C7EF3"
                                  />
                                </svg>
                              </label>
                              <p className="text-gray">Upload</p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-4 alert__message">
                            <i className="material-icons text-red mr-2">
                              error_outline
                            </i>
                            <p className="text-red text-left m-0">
                              Audio duration must be between 5 seconds to 5
                              minutes
                            </p>
                          </div>
                        </div>
                        <div className="col s12 m6 l6 xl6 pl-0">
                          <div className="d-flex align-items-center">
                            <div className="audio-setup mt-3">
                              <audio
                                controls="controls"
                                id="record-audio"
                                muted
                                className="hide_audio_file d-none"
                              />
                              {countTime.toString() !== "0" && (
                                <span>{calculateTime(countTime)}</span>
                              )}
                              {audioUrl !== "" && (
                                <CustomAudioPlayer
                                  hanldePlayer={(activePlayerId) =>
                                    setPlayingAudioId(activePlayerId)
                                  }
                                  activePlayerId={playingAudioId}
                                  unique_key={"recoring_audio_player"}
                                  src={audioUrl}
                                  showDownloadOption={false}
                                />
                              )}
                              {/* <audio controls="controls" src={audioUrl} className={`${audioUrl == '' ? 'd-none' : '' }`} /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {showTemplate && (
                  <div className="voice-audio-lists  awesome__scroll_bar">
                    {fetchingTemplate ? (
                    
                        <DynamicSkeleton count={1} />
                        
                    ) : templates.length && templates.length > 0 ? (
                      templates.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center voice-audio-list"
                        >
                          <div
                            onClick={() => handleTemplateSelect(item)}
                            className="voice-mail-list-left"
                          >
                            {selectedAudioTemplateId === item.id ? (
                              <RadioButtonCheckedIcon />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </div>
                          <div className="voice-mail-list-right">
                            <p>{item.voice_title}</p>
                            <div>
                              <CustomAudioPlayer
                                hanldePlayer={(activePlayerId) =>
                                  setPlayingAudioId(activePlayerId)
                                }
                                activePlayerId={playingAudioId}
                                unique_key={index + "audio_player"}
                                src={item.file}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div style={{
                        background:"#e5e8ef",
                        borderRadius:"8px",
                        padding:"16px 13px 16px"
                      }}>No pre-created template available</div>
                    )}
                  </div>
                )}

                {renderTimeSetting()}

                <div className="modal__footer_btn globalModalTitle send-voice-mail-submit d-flex justify-content-end align-items-center">
                  <button className="btn btn-primary " onClick={handleSubmit}>
                    {props.from && props.from === "map"
                      ? "Next"
                      : submitting
                      ? "Sending"
                      : "Send"}
                  </button>
                </div>
              </>
            )}
          </div>
        );
    }

    const renderNextStepMap = () => {
        if(props.from != undefined && props.from === 'map'){
            return(
                <div>
                    <h5>Order Summary</h5>
                    <p>Below is a summary of your order. Click Send to confirm.</p>
                    <p>Sending to {props.totalContacts} address(es)</p>

                    <table>
                        <tr><th>Item</th><th>Sending</th></tr>
                        <tr><td>VOICE</td><td>{sendTime === '2'? `Scheduled` : 'Immediately'}</td></tr>
                    </table>

                    <h6>Cost Summary</h6>
                    <table>
                        <tr><th>Per Send Cost</th><th>Number of recipients</th><th>Estimated Total Cost</th></tr>
                        <tr><td>{props.perVoicemailCost}</td><td>{props.totalContacts}</td><td>{props.perVoicemailCost*props.totalContacts}</td></tr>
                    </table>

                    <div className="d-flex align-items-center justify-content-center py-3">
                        <button className="sendModulesButton cancelButton" onClick={props.onClose}>Cancel</button>
                        <button className="sendModulesButton submitButton" onClick={handleSubmit}>{submitting ? <MoreHorizIcon fontSize="small"/> : ''} Confirm & Send</button>    
                    </div>		
                </div>
            )
        }
        return null;
    }

    /* submit functions */
    const handleSubmit = () => {
        /* 
        check validation for number, date, time sending status
        */
        if(recording){
            handleShowNotification('Stop recording first!', 'ERROR')
            return
        }
        if(selectedVn === ''){
                handleShowNotification("No virtual number selected", 'ERROR')
                return false
        }

        if(showTemplate && selectedAudioTemplate === ''){
                handleShowNotification("Something went wrong on previous audio file select.", 'ERROR')
                return false
        }

        if(!audioFileUpload && !showTemplate && audioFile == null){
                handleShowNotification("No audio file record or upload", 'ERROR')
                return false
        }

        if(sendTime === 2  && time == null){
                handleShowNotification("No time selected", 'ERROR')
                return false
        }

        if(props.from === 'map' && !nextStepMap) {
            setNextStepMap(true);
            return false;
        }

        let sendFunction;

        if(props.from === 'map'){
            sendFunction = _ApiList.submitSendFromMap;
        } else {
            sendFunction = _ApiList.submitSendVoiceDefault;
        }

        if(submitting) return;
        
        setSubmitting(true);
        if(showTemplate){
            let data = {
                contact_id: props.contact_id,
                date: sendTime === 2 ? date : null,
                file: selectedAudioTemplate,
                message: "Recorded template Voice mail",
                message_type: 3,
                origin: 6,
                schedule_type: sendTime,
                time: time,
                virtual_number_id: selectedVn,
                voice_type: "pre-created-temp"
            }
            sendFunction(data).then(res => {
                setSubmitting(false)
                let response = res.data
                if(response.status === 'error'){
                    handleShowNotification(response.html, 'ERROR')
                }
                else{
                    handleShowNotification(response.html)
                    if(props.onClose !== undefined){
                        props.onClose()
                    }
                    if(props.onSubmit!== undefined){
                        props.onSubmit();
                    } 
                }
            })
        } else {
            let formData = new FormData();
            formData.append('message_type', 3);
            formData.append('voice_title', title);
            formData.append('message', 'Record Voice mail');
            formData.append('virtual_number_id', selectedVn);
            formData.append('origin', 6);
            formData.append('voice_type', 'voice_type_record');
            formData.append('source', 'record');
            formData.append('schedule_type', sendTime);
            formData.append('date', sendTime === 2 ? moment(date).format('YYYY-MM-DD') : null);
            formData.append('time', time);
            formData.append('contact_id', props.contact_id);

            if(audioFileUpload){
                // for uploading
                formData.append('file', uploadFileDivData.current.files[0]);
            }
            else{
                // for recording
                formData.append('file', audioFile);
            }

            sendFunction(formData, true).then(res => {
                setSubmitting(false)
                let response = res.data
                if(response.status === 'error'){
                    handleShowNotification(response.html, 'ERROR')
                }
                else{
                    handleShowNotification(response.html)
                    if(props.onClose !== undefined){
                        props.onClose()
                    }
                }
            })

        }
    }

    /* recording function */
    const openMic = (e) => {
        e.preventDefault();
        if(recording){
            return
        }
        let hasGetUserMedia = '';
        try {
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia({audio: true}) || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            return 0;
        }
        requestUserMedia();
    }

    const requestUserMedia = () => {
        if(IntervalTime !== ''){
            handleShowNotification('A record is in progress !', 'ERROR')
            return false
        }
        videoStream=null;
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(function (mediaStream) {

                let audio = document.getElementById('record-audio');
                    audio.srcObject=mediaStream;
                    videoStream = mediaStream;
                    audio.onloadedmetadata = function(e) {
                        audio.play();
                    };
                    
                IntervalTime = setInterval(() => {
                    if(totalSeconds == 30){
                        stopRecord()
                    }
                    else{
                        setCountTime(prevTime => prevTime + 1)
                        totalSeconds = totalSeconds + 1
                    }
                }, 1000);
                setRecording(true)
                setAudioFileUpload(false)
                setAudioUrl('')
                startRecord()
            })
            .catch(function (err) {
                return 0;
            });
    }

    const startRecord = () => {
        totalSeconds = 0;
        recordAudio = RecordRTC(videoStream, {type: 'audio/webm', mimeType: 'audio/webm', recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000});
        recordAudio.startRecording();
    }

    const stopRecord = () => {
        if(!recording){
            return
        }
        if (totalSeconds < 5) {
            handleShowNotification("Audio duration must be between 5 seconds to 5 minutes", 'ERROR')
            return false;
        }
        recordAudio.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(recordAudio.blob);
            videoStream.getTracks().forEach(track => track.stop());

            let mimeType = 'audio/webm';
            let blob = recordAudio instanceof Blob ? recordAudio : recordAudio.getBlob();
            blob = new File([blob], 'RecordRTC-audio.webm', {
                type: mimeType
            });
            setAudioFile(blob)
            setAudioUrl(videoUrl)
            setRecording(false)
            return false;
        });

        clearInterval(IntervalTime)
        IntervalTime=''
        setCountTime(0)
        totalSeconds=0
    }

    const calculateTime = () => {
        let seconds = "00",
            minutes = "00";

        seconds = pad(countTime % 60)
        minutes = pad(parseInt(countTime / 60))

        return minutes + ":" + seconds;
    }

    const pad = (val) => {
        var valString = val.toString();
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    const uploadFile = () => {
        setAudioFileUpload(true)
        if (!uploadFileDiv.current.files.length) return;
        uploadFileDivData.current.files = uploadFileDiv.current.files
        let urlCreator = window.URL || window.webkitURL;
        const urlObj = urlCreator.createObjectURL(uploadFileDiv.current.files[0]);
        setAudioUrl(urlObj)
    }

    return(
        <div className="global-email-send-module-container send_mail_modal global__send_modal_wrapper">
            <input type="file" ref={uploadFileDivData} className="d-none" accept="audio/mp3, video/webm" onChange={uploadFile} />
            {!nextStepMap ? 
                renderMain()
                :
                renderNextStepMap()
            }
        </div>
    )
}
export default VoiceSend;