import { Button, FormGroup } from '@material-ui/core'
import React, { useState } from 'react'
import GlobalModal from '../globals/Modal/GlobalModal'
import BasicInput from '../Common/custom/BasicInput'
import DynamicSkeleton from './contactDetailsV2/common/DynamicSkeleton'

export default function SearchParameter(props){

    const [neighbourCount, setNeighbourCount] = useState(1)
    const [maximumDistance, setMaximumDistance] = useState(50)
    const [submitting, setSubmitting] = useState(false)

    const changeNeighbourCount=(event)=>{
        if(event.target.value ==='' || (event.target.value >=0 && event.target.value <= 500 && !submitting)){
            setNeighbourCount(event.target.value)
        }
    }

    const changeMaximumDistance=(event)=>{
        if(event.target.value ==='' || (event.target.value >=0 && event.target.value <= 500 && !submitting)){
            setMaximumDistance(event.target.value)
        }
    }

    const searchParameter=()=>{

        if(neighbourCount <1 && maximumDistance <1 && !submitting){
            window.showNotification('ERROR',"Neighbour count or Maximum Distance can not be zero or less")
        }
        if(neighbourCount !== '' && maximumDistance !== '' && !submitting ){
            if(props.access){
                setSubmitting(true)
            }
            props.searchingParameter(neighbourCount, maximumDistance)
        }
    }


    return (
      <React.Fragment>
        <GlobalModal {...props} placement="right">
          {submitting ? (
            <DynamicSkeleton count={3} />
          ) : (
            <div>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ paddingBottom: "5px" }}>Neighbors Count</span>
                <BasicInput
                  type="number"
                  value={neighbourCount}
                  onChange={changeNeighbourCount}
                  fullWidth
                />
              </div>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ paddingBottom: "5px" }}>Maximum Distance</span>
                <BasicInput
                  type="number"
                  value={maximumDistance}
                  onChange={changeMaximumDistance}
                  fullWidth
                />
                <span className="distance_text">
                  Distance Measurement unit is Meter
                </span>
              </div>
              <div className="d-flex align-align-items-center justify-content-center">
                <Button
                  className="ppc__blue_btn search__contracts accent--bg--color w-100"
                  onClick={searchParameter}
                >
                  Search the Parameter
                </Button>
              </div>
            </div>
          )}
        </GlobalModal>
      </React.Fragment>
    );
}