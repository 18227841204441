import React, { Fragment, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Save as SaveIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import Styles from "./DispositionStyle.module.css";
import {
  BasicButton,
  BasicInput,
  BasicInputSelect,
} from "../../ContactDetailsStyle";
import { connect } from "react-redux";
import { fetchUserDispositionList } from "../../../../../actions/mapAction";
import { saveContactFromMapSidebar } from "../../../../../api/mapApi";

const useStyles = makeStyles({
  modalWrapper: {
    display: "grid",
    placeContent: "center",
  },

  modalInner: {
    width: "650px",
    height: "fit-content",
    maxHeight: " calc(100vh - 60px)",
    overflowY: "auto",
  },

  // modalInner:focus-visible:{
  //     border: none !important,
  //     box-shadow: none !important,
  //     outline: none !important,
  // }

  headerWrapper: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    padding: "16px 24px !important",
    borderBottom: "1px solid rgba(148, 157, 178, 0.24) !important",
  },

  headerTitle: {
    margin: 0,
    fontFamily: " Poppins, Arial, sans-serif",
    fontSize: "18px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
    color: "#1c1b1f",
    fontWeight: 500,
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: "1px solid rgba(148, 157, 178, 0.24)",
  },

  headerClose: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    display: "grid",
    placeContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(237, 0, 57, 0.03)",
    },
  },

  paddingWrp: {
    padding: "24px",
  },

  modalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },

  dispositionSelectInline: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  dispositionIcons: {
    maxHeight: "124px",
    overflowY: "auto",
    background: "#ddd",
    padding: "10px 10px 10px 5px",
    borderRadius: "5px",
    marginTop: "20px",
  },

  dispositionIcon: {
    margin: "0 5px",
    border: "5px solid #c5c5c5",
    borderRadius: "50%",
    transition: "all .4s linear",
  },

  selectedDispositionIcon: {
    borderColor: "var(--accent-color)",
  },
  modalHeaderTitle: {
    color: "#0e0f3f",
  },
  selectHoverChangedColor: {
    color:'#0e0f3f',
    "&.MuiListItem-button:hover": {
      backgroundColor: "#d8dffd !important",
    },
  },
  addNewButton: {
    padding: "12px 20px !important",
    width: "150px !important",
    color: "#0e0f3f !important",
    marginLeft: "10px !important",
    background: "#00ff91 !important",
    "&:hover": {
        background: "#00ff91 !important",
      },
  },
});

const icons = [
  "/assets/img/disposition/APPT-lightblue.png",
  "/assets/img/disposition/cross-black.png",
  "/assets/img/disposition/cross-red.png",
  "/assets/img/disposition/CS-blue.png",
  "/assets/img/disposition/CS-green.png",
  "/assets/img/disposition/NH-blue.png",
  "/assets/img/disposition/NH-purple.png",
  "/assets/img/disposition/NH-red.png",
  "/assets/img/disposition/DND-deepblue.png",
  "/assets/img/disposition/DND-red.png",
  "/assets/img/disposition/idea-orange.png",
  "/assets/img/disposition/idea-green.png",
  "/assets/img/disposition/money-blue.png",
  "/assets/img/disposition/money-green.png",
  "/assets/img/disposition/money-purple.png",
  "/assets/img/disposition/DND-red.png",
  "/assets/img/disposition/reload-blue.png",
  "/assets/img/disposition/reload-green.png",
  "/assets/img/disposition/reload-yellow.png",
  "/assets/img/disposition/star-black.png",
  "/assets/img/disposition/star-green.png",
  "/assets/img/disposition/star-purple.png",
  "/assets/img/disposition/sheriff-red.png",
  "/assets/img/disposition/sheriff-white.png",
];

const Disposition = ({
  isOpen,
  addSuccess,
  onClose,
  dispositionList,
  setDispositionId,
  setDispositionName,
  setDispositionIcon,
  markerContactDetails,
  handleCloseModal,
}) => {
  const [selectedDisposition, setSelectedDisposition] = useState("");
  const [addNewDisposition, setAddNewDisposition] = useState(false);
  const [newDispositionIcon, setNewDispositionIcon] = useState("");
  const [newDispositionName, setNewDispositionName] = useState("");
  const [newDispositionNameError, setNewDispositionNameError] = useState("");
  const [newDispositionIconError, setNewDispositionIconError] = useState("");
  const [selectedDispositionError, setSelectedDispositionError] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const renderDispositionMenuItems = () => {
    let items = [];
    for (let i = 0; i < dispositionList.length; i++) {
      items.push(
        <MenuItem
          className={`dropdownhelper-menuitem-class ${classes.selectHoverChangedColor}`}
          value={dispositionList[i].id}
        >
          {dispositionList[i].name}
        </MenuItem>
      );
    }
    return items;
  };
  const saveCampaignData = async () => {
    const payload = {
      disposition_id: selectedDisposition,
      dispositionName: newDispositionName,
      iconLink: newDispositionIcon,
      contactDetails: markerContactDetails,
    };
    if (selectedDisposition || newDispositionName) {
        setDisableSaveButton(true);
      await saveContactFromMapSidebar(payload).then((response) => {
        if (response.data.success) {
          addSuccess(response.data.data.data, true);
          window.showNotification("SUCCESS", response.data.message);
          onClose();
          handleCloseModal();
        } else if (
          response.data.success === false &&
          response.data.data.status === "validation-error"
        ) {
            setDisableSaveButton(false);
          window.showNotification(
            "ERROR",
            response.data.data.html[Object.keys(response.data.data.html)[0]][0]
          );
        }
      });
    }
  };

  const onSubmit = () => {
    setDispositionId(selectedDisposition);
    if (addNewDisposition) {
      if (newDispositionName.trim() === "") {
        setNewDispositionNameError("Disposition name required");
      } else {
        setNewDispositionNameError("");
        setDispositionName(newDispositionName);
      }

      if (newDispositionIcon.trim() === "") {
        setNewDispositionIconError("Disposition icon required");
      } else {
        setNewDispositionIconError("");
        setDispositionIcon(newDispositionIcon);
      }
    } else {
      if (!selectedDisposition) {
        setSelectedDispositionError("Disposition required");
      } else {
        setSelectedDispositionError("");
      }
    }
    saveCampaignData();
  };
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="disposition-modal-title"
      aria-describedby="disposition-modal-description"
      className={` global-xs-modal add_list_modal`}
      style={{ display: "grid", placeContent: "center" }}
    >
      <Paper className={classes.modalInner}>
        {/* <Box className={Styles.headerWrapper}> */}
        <Box className={classes.modalHeader}>
          <Typography style={{ fontWeight: 600 }} variant="h5">
            Contact Disposition
          </Typography>
          <Box className={classes.headerClose} onClick={onClose}>
            <CloseIcon color="error" />
          </Box>
        </Box>
        <Box className={classes.paddingWrp}>
          <Box className={classes.modalBody}>
            {!addNewDisposition && (
              <Fragment>
                <Box className={classes.dispositionSelectInline}>
                  <FormControl fullWidth>
                    <Select
                      labelId="select-dispoistion-label"
                      id="select-dispoistion"
                      name="disposition"
                      
                      value={selectedDisposition}
                      displayEmpty
                      onChange={(e) => {
                        setSelectedDisposition(e.target.value);
                        if (selectedDispositionError) {
                          setSelectedDispositionError("");
                        }
                      }}
                      input={
                        <BasicInputSelect
                          padding={"14px 12px 14px !important"}
                        />
                      }
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem
                        className="dropdownhelper-menuitem-class"
                        value={""}
                        disabled
                      >
                        Select a disposition
                      </MenuItem>
                      {renderDispositionMenuItems()}
                    </Select>
                  </FormControl>
                  <BasicButton
                   className={classes.addNewButton}
                    size="large"
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                      setAddNewDisposition(true);
                    }}
                  >
                    <Typography style={{ fontSize: ".7vw", fontWeight: 600 ,whiteSpace:'nowrap'}}>
                      Add New
                    </Typography>
                  </BasicButton>
                </Box>
                {selectedDispositionError && (
                  <Typography variant="body2" color="error">
                    {selectedDispositionError}
                  </Typography>
                )}
              </Fragment>
            )}

            {addNewDisposition && (
              <Box className={classes.newDisposition}>
                <Box className={classes.dispositionSelectInline}>
                  <Box sx={{ width: "100%" }}>
                    <BasicInput
                      fullWidth
                      placeholder="Enter disposition name"
                      value={newDispositionName}
                      onChange={(event) => {
                        setNewDispositionName(event.target.value);
                        if (newDispositionNameError) {
                          setNewDispositionNameError("");
                        }
                      }}
                    />
                  </Box>
                  <IconButton
                    size="large"
                    onClick={() => {
                      setAddNewDisposition(false);
                    }}
                  >
                    <CancelIcon fontSize="large" />
                  </IconButton>
                </Box>
                {newDispositionNameError && (
                  <Typography variant="body2" color="error">
                    {newDispositionNameError}
                  </Typography>
                )}
                <Box component="div" className={classes.dispositionIcons}>
                  {icons.map((icon) => (
                    <img
                      src={`${process.env.REACT_APP_APP_URL}${icon}`}
                      className={`${classes.dispositionIcon} ${
                        icon === newDispositionIcon
                          ? classes.selectedDispositionIcon
                          : ""
                      }`}
                      alt="disposition-icon"
                      onClick={() => {
                        setNewDispositionIcon(icon);
                        if (newDispositionIconError) {
                          setNewDispositionIconError("");
                        }
                      }}
                    />
                  ))}
                </Box>
                {newDispositionIconError && (
                  <Typography variant="body2" color="error">
                    {newDispositionIconError}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.modalFooter}>
            <BasicButton
              size="large"
              onClick={onSubmit}
              variant="contained"
              style={{ background: "#316aff" }}
              startIcon={<SaveIcon />}
            >
              <Typography style={{ fontSize: ".9vw" }}>
                {disableSaveButton ? "Saving Contact..." : "Save Contact"}
              </Typography>
            </BasicButton>
            <BasicButton
              style={{ marginLeft: "10px",background: "#3c3b3d"  }}
              size="large"
              onClick={onClose}
              variant="contained"
              color="secondary"
              startIcon={<CancelIcon />}
            >
              <Typography style={{ fontSize: ".9vw" }}>Cancel</Typography>
            </BasicButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    dispositionList: state.mapReducer.dispositionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDisposition: (params, callback) =>
      dispatch(fetchUserDispositionList(params, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Disposition);
