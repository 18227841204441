import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CreateNewCardTemplate from './CreateNewCardTemplate';
import SecondHalfStep from './SecondHalfStep'
import { getCategoryList } from './redux/cardBroadcastAction';
import Helper from './assets/Helper';
import { Skeleton } from '@material-ui/lab';
import { getMailTemplate } from './redux/cardBroadcastApi';
import Loading from './assets/Loading';
import { DropdownHelper } from './assets/DropdownHelper';


const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;

const STEPNO = 2
const NEXTSTEPNO= 3
const HALFSTEPNO = 2.5

const SecondStep = (props) => {

   const [loadingCategory, setLoadingCategory] = useState(false);
   const [loadingTemplate, setLoadingTemplate] = useState(true);
   const [templateList, setTemplateList] = useState(null);
   const [selectedCategoryId, setSelectedCategoryId] = useState('');
   // const [createNewPostcardFrom, setCreateNewPostcardFrom] = useState('');
   const [selectedTemplateImageUrl, setSelectedTemplateImageUrl] = useState('');
   const [selectedTemplateId, setSelectedTemplateId] = useState('');
   const [categoryList, setCategoryList] = useState([])

   useEffect(() => {
      updateTemplateList();
   }, [])

   const updateTemplateList = async () => {

      if(props.messageType !== GIFT){
         if(props.messageType === POSTCARD && props.postcardCategoryList != null){
            setCardRelatedData(props.postcardCategoryList)
         }else if(props.messageType === GREETING_CARD && props.greetingCardCategoryList != null){
            setCardRelatedData(props.greetingCardCategoryList)
         }else if(props.messageType === LETTER && props.letterCategoryList != null){
            setCardRelatedData(props.letterCategoryList)
         }else{
            await props.getCategoryList({ 'message_type': props.messageType }, (response) => {
               if(response.success){
                  setCardRelatedData(response.data)
               }else{
                  window.showNotification('ERROR','Something went wrong')
                  props.moveToSpecificStep( 1)
               }
            });
         }
         
      }else{
         setLoadingCategory(false)
         setCardRelatedData(props.greetingCardCategoryList)

      }
   }

   const setCardRelatedData=(responseData)=>{
      setCategoryList(responseData.template_categories)
      if(props.editableCampaign !== undefined && props.editableCampaign != null){
         setSelectedCategoryId(props.editableCampaign.template_category_id)
         setLoadingCategory(false)
         getTemplateList(props.editableCampaign.template_category_id);

      }else{
         setSelectedCategoryId(responseData.template_categories[0].id)
         setLoadingCategory(false)
         getTemplateList(responseData.template_categories[0].id);
      }
   }

   const getTemplateList = async (id) => {
      if (id != '') {
         await getMailTemplate({
            category_id: id
         }).then(res => {
            // console.log(res.data.data);
            if(props.editableCampaign != undefined && props.editableCampaign != null && templateList == null){
               let templateData = res.data.data
               setTemplateList(templateData)
               let stateChanged = false;
               for( let i=0; i<templateData.length;i++){

                  if(templateData[i].id == props.editableCampaign.template_id){
                     stateChanged = true

                     setSelectedTemplateId(templateData[i].id)
                     setSelectedTemplateImageUrl(templateData[i].image_path)
                     props.selectTemplate(templateData[i]);
                     break;
                  }
               }

               if(!stateChanged){
                  setSelectedTemplateId(templateData[0].id)
                  setSelectedTemplateImageUrl(templateData[0].image_path)
                  props.selectTemplate(templateData[0]);
               }     

            }else{
               setTemplateList(res.data.data)
               setSelectedTemplateId(res.data.data[0].id)
               setSelectedTemplateImageUrl(res.data.data[0].image_path)
               props.selectTemplate(res.data.data[0]);
            }
            
            setLoadingTemplate(false);
         })
      }
   }

   const handleCategoryDropdownChange = (value) => {
      if (value !== '' && value !== selectedCategoryId) {
         setSelectedCategoryId(value)
         let tempTemplateList = [...templateList];
         setTemplateList(tempTemplateList)
         // setLoadingTemplate(true);
         // getTemplateList(value);
      }
   }

   const selectTemplate = (e, index) => {
      e.preventDefault();
      setSelectedTemplateId(templateList[index].id)
      setSelectedTemplateImageUrl(templateList[index].image_path)
      props.selectTemplate(templateList[index]);

   }

   const changeCreateNewPostcardFrom = (from) => {
      props.setCreateNewPostcardFrom(from)
   }

   const addNewTemplate = (newTemplate) => {

      // if(selectedCategoryId == newTemplate.agency_card_category_id){
      //    setLoadingTemplate(true);
      //    setTemplateList(templateList.push(newTemplate))
      //    setLoadingTemplate(false);
      // }else{
      setSelectedCategoryId(newTemplate.agency_card_category_id);
      setLoadingTemplate(true);
      getTemplateList(newTemplate.agency_card_category_id);


      // }
   }

   const loader = () => {
      let skeloader = []
      for (let i = 0; i < 4; i++) {
         skeloader.push(

            <Skeleton animation="wave" style={{ height: '60%', width: '25%', marginLeft: 25 }} />
         )
      }

      return (
         <div className="loading-skeleton-in-direct-mail d-flex row justify-content-center" style={{ height: 350, width: 600 }}>
            {skeloader}
         </div>
      )

   }

   return (
      <React.Fragment>
         <fieldset>
            <div className="custom-form-card form-card">
               <div className="row">
                  <div className="col s12">
                     <div className="first-step-page-main">
                        {/* <!-- start design step 01 section row--> */}
                        {props.createNewPostcardFrom === '' && props.stepNo === STEPNO &&
                           <div className="row">
                              <div className="col xl7">
                                 <div className="select-template-main-area second__step__area__one">
                                    <div className="templete-opt-area">
                                       <div className="margin-none-row row">
                                          <div className="col s12">
                                             {/* <div className="custom-input deal-select-label custom-select-arrow col s8"> */}
                                             <div className="custom-input deal-select-labe col s8">
                                                <label for="lead_name" className="second__step__area__label" >Select Template</label>

                                                {(!loadingCategory && categoryList!=null) &&
                                                <DropdownHelper 
                                                   datalist={categoryList}
                                                   noneFieldRequired={true}
                                                   noneFieldTitle={props.messageType === POSTCARD ?"Choose Postcard Template Category" : 
                                                   props.messageType === LETTER ? "Choose Letter Template Category" : "Choose Greeting card Template Category"}
                                                   className=""
                                                   mapping={{ title: "title" }}
                                                   menuItemClassName=""
                                                   updateSelectedData={handleCategoryDropdownChange}
                                                   selectedValue={selectedCategoryId}
                                                   // updateDatatable={updateDatatable}
                                                   dropdownHeader={'campaignChange'}

                                                />


                                                }
                                                {/* {!loadingCategory &&
                                                   <Select className="second__step__area__select"
                                                      // labelId="demo-simple-select-placeholder-label-label"
                                                      // id="demo-simple-select-placeholder-label lead_name"
                                                      value={selectedCategoryId}
                                                      onChange={handleCategoryDropdownChange}
                                                   // displayEmpty
                                                   // className={classes.selectEmpty}
                                                   >
                                                      <MenuItem value="">
                                                         <em>Choose Postcard Template Category</em>
                                                      </MenuItem>
                                                      {props.categoryList != null &&
                                                         props.categoryList.template_categories.map((category) => (
                                                            <MenuItem value={category.id}>{category.title}</MenuItem>
                                                         ))
                                                      }

                                                   </Select>
                                                } */}
                                                {loadingCategory &&
                                                   <Loading />
                                                }
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="updated_templete-img-main templete-img-main templete__img__main awesome__scroll_bar">
                                       {/* <!-- single item --> */}
                                       {!loadingTemplate && templateList != null &&
                                          templateList.map((template, index) => {
                                             
                                             if(!Helper.isEmpty(template.image_path) && template.agency_card_category_id == selectedCategoryId){
                                                return(
                                                <div className={"tem-single-item " + (template.id == selectedTemplateId ? "is_template_selected" : "")} onClick={(e) => selectTemplate(e, index)}>
                                                <a href="javascript:void(0)"><img src={template.image_path} alt="img" /></a>
                                             </div>
                                             )
                                          }
                                             
                                          })
                                       }
                                       {loadingTemplate &&
                                          // <Loading />
                                          loader()

                                       }
                                    </div>
                                 </div>
                              </div>
                              <div className="col xl5">
                                 <div className="select-opt-right">
                                    <div className="select-opt-right-flex">
                                       {/* <!-- single item --> */}
                                       <div className="select-single-item" onClick={() => props.setCreateNewPostcardFrom('canva')}>
                                          <span>Design in Canva</span>
                                          <a href="javascript:void(0)"><img src={`${process.env.REACT_APP_APP_URL}/assets/images/c.png`} alt="" /></a>
                                       </div>
                                       {/* <!-- single item --> */}
                                       <div className="select-single-item cursor-pointer" onClick={() => props.setCreateNewPostcardFrom('computer')}>
                                          <span>Upload From Computer</span>
                                          <div className="custom-file">
                                             {/* <input type="file" className="custom-file-input" id="customFileLangHTML" /> */}
                                             <label className="custom-file-label" for="customFileLangHTML" data-browse="Bestand kiezen">
                                                <img src={`${process.env.REACT_APP_APP_URL}/assets/images/u.png`} alt="manu Image" />
                                             </label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        }
                        {/* End design step 01 section row */}
                        {props.createNewPostcardFrom !== '' && props.stepNo === STEPNO &&
                           <CreateNewCardTemplate
                              // categoryList={props.categoryList}
                              // submitNewPostcard={submitNewPostcard}
                              changeCreateNewPostcardFrom={changeCreateNewPostcardFrom}
                              createNewPostcardFrom={props.createNewPostcardFrom}
                              addNewTemplate={addNewTemplate}
                              messageType={props.messageType}
                           // newPostcardTemplateId={this.createdNewPostcardtemplate}
                           />
                        }

                        {props.stepNo == HALFSTEPNO &&
                           <SecondHalfStep
                              selectedTemplateImageUrl={selectedTemplateImageUrl}
                           />
                        }
                        {/* End design step 04 section row */}
                     </div>
                  </div>
               </div>
            </div>
            {/* ommon button multi */}
            {/* {props.createNewPostcardFrom == '' &&
            //    <div>
            //       <button className="custom-next next action-button accent--bg--color" onClick={(e) => props.moveNextStep(e, NEXTSTEPNO)}>
            //          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            //             <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
            //          </svg>
            //          <span>{props.stepNo == 2.5 ? 'Next' : 'Save & Continue'}</span>
            //       </button>
            //       <button className="custom-next custom-close modal-close action-button" onClick={(e) => props.closeModal(e)}>
            //          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            //             <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
            //          </svg>
            //          <span>Close</span>
            //       </button>
            //    </div>
            }*/}
            {/* <!-- common button multi --> */}
         </fieldset>
      </React.Fragment>

   )


}
const mapStateToProps = state => {
   return {
      postcardCategoryList: state.cardBroadcastReducer.postcardCategoryList,
      greetingCardCategoryList: state.cardBroadcastReducer.greetingCardCategoryList,
      letterCategoryList: state.cardBroadcastReducer.letterCategoryList,
   };
};

const mapDispatchToProps = dispatch => {
   return {
      getCategoryList: (params, callback) => dispatch(getCategoryList(params, callback)),
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
