import * as ACTION from "../constants/mapActionTypes";

const initialState = {
    tagList : null
};

const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_TAG_LIST:
            return {...state,tagList:action.payload}
        default:
            return state;
    }
}

export default tagReducer;
