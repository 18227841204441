import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as MAP_API from '../api/mapApi';
import * as ACTION from '../constants/mapActionTypes'
import Utils from "../helpers/Utils";

function* fetchAllContactPositionArrayWatcher() {
    yield takeEvery(ACTION.GET_ALL_CONTACTS_POSITION_ARRAY, fetchAllContactPositionArrayMiddleware);
    yield takeEvery(ACTION.GET_SEND_MESSAGE_CREDIT, getSendMessageCreditMiddleware);
}

function* fetchAllContactPositionArrayMiddleware(action) {
    const { payload } = action;

    try {
        const response = yield call(MAP_API.loadAllContactsInMap, payload.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.SET_ALL_CONTACTS_POSITION_ARRAY, payload: responseData});
        } else {
            console.log("something is wrong")
        }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }
}

function* fetchAllCampaignListWatcher() {
    yield takeEvery(ACTION.GET_CAMPAIGN_LIST, fetchAllCampaignMiddleware);
}

function* fetchAllCampaignMiddleware(action) {
    const { payload } = action;

    try {
        const response = yield call(MAP_API.getAllUserCampaign, payload.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.SET_CAMPAIGN_LIST, payload: responseData.data});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }
}

function* getSendMessageCreditMiddleware(action) {
    const { payload } = action;

    try {
        const response = yield call(MAP_API.getSendMessageCreditApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        console.log(error);
    }
}
function* fetchDispositionListWatcher() {
    yield takeEvery(ACTION.GET_DISPOSITION_LIST, fetchAllUserDispositionMiddleware);
}

function* fetchAllUserDispositionMiddleware(action) {
    const { payload } = action;

    try {
        const response = yield call(MAP_API.fetchUserDispositions, payload.payload);
        let responseData = response.data;
        
        if (responseData.success) {

            if(responseData.userDisposition != undefined && responseData.userDisposition != null){
                yield put({type: ACTION.SET_DISPOSITION_LIST, payload: responseData.userDisposition});
            }
        } else {
        }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }
}

export default function* calendarMiddleware() {
    yield all([
        fetchAllContactPositionArrayWatcher(),fetchAllCampaignListWatcher(), fetchDispositionListWatcher()
    ])
}
