const CoreConstants = {
    VIDEO_STATUS_WELCOME: 0,
    VIDEO_STATUS_WEBCAM: 1,
    VIDEO_STATUS_WARNING: 2,
    VIDEO_STATUS_PREVIEW: 3,

    MESSAGE_TEMPLATE: {
        EMAIL: 1,
        SMS: 2,
        VOICE_MAIL: 3,
        VIDEO_EMAIL: 4,
        MMS: 5,
        POST_CARD: 6,
        GREETING_CARD: 7,
        GIFT_CARD: 8,
        LETTER: 9,
    },
}

export const MESSAGE_TYPE_VIDEO_EMAIL = 14;

export default CoreConstants;