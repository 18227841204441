import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonLoader = () => {
  const skeletonItems = Array.from({ length: 20 }, (_, index) => index + 1);

  return (
    <Grid container spacing={3}>
      {skeletonItems.map((item) => (
        <Grid item xs={6} sm={4} md={3} lg={2} key={item}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Skeleton variant="rect" width={100} height={80} />
            <Skeleton variant="text" width={60} style={{ marginTop: 10 }} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default SkeletonLoader;
