const marketingUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const inboxRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`

const Api = {
    fetchVideoFolders: `${marketingUrl}/video/folders`,
    fetchVideos: `${marketingUrl}/videos`,
    deleteVideo: `${marketingUrl}/video/delete`,
    uploadVideo: `${marketingUrl}/video/upload`,
    customFields: `${inboxRootUrl}/get-custom-fields`,
    userEmailList: `${contactUrl}/user-email-list`,
    sendBulkMessage: `${contactUrl}/send-message`,
    getUserInfo: `${contactUrl}/get-user-data`,
    getMessageTemplate: `${marketingUrl}/message-templates`,
}

export default Api;