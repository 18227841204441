import * as ACTION from "./videoEmailActionTypes";

export const getVideoFolders = () => ({
    type:ACTION.FETCH_VIDEO_FOLDERS,
    payload: {}
});

export const getVideos = payload => ({
    type:ACTION.FETCH_VIDEOS,
    payload: payload
});

export const deleteVideo = (payload, callback)=> ({
    type:ACTION.DELETE_VIDEOS,
    payload: {payload, callback}
});

export const updateFolderId = payload => ({
    type:ACTION.UPDATE_FOLDER_ID,
    payload: payload
});

export const getMessageTemplates = payload => ({
    type:ACTION.FETCH_MESSAGE_TEMPLATES,
    payload: payload
});

export const getCustomFieldData = () => ({
    type: ACTION.FETCH_CUSTOM_FIELD,
    payload: {}
});

export const setUser = payload => ({
    type: ACTION.SET_USER,
    payload: payload
});