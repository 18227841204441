import React, { useState ,useEffect, useContext} from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, Button } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Select from '@material-ui/core/Select';
import { fetchAlUserCampaignList } from "../../actions/mapAction";
import {connect} from 'react-redux'
import { mapLeadProcessAction } from "../../api/mapApi";
import GlobalModal from "../globals/Modal/GlobalModal";
import Utils from "../../helpers/Utils";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import { HistoryProvider } from "../../App";

const SENDMESSAGE="1";
const ADDTOCAMPAIGN = "2";
const CREATELIST="3"

const PROCESSALLATONCE="11";
const PROCESSINDRIPMODE="12"


const SelectAction=(props) =>{


    const history = useContext(HistoryProvider);
	const [addToCampaign, setAddToCampaign] = useState(false)
	const [value, setValue] = useState(SENDMESSAGE)
	const [campaignAddOption, setCampaignAddOption] = useState(PROCESSALLATONCE)
	const [campaignList, setCampaignList] = useState([])
	const [selectedCampaignId, setSelectedCampaignId]  = useState('')

	useEffect(() => {

		if(props.storedCampaignList == null){
			props.fetchAllUserCampaignList({}, (response)=>{
				if (response.success){
					setCampaignList(response.data)
				} else {
					setCampaignList([])
				}
			})
		}else{
			setCampaignList(props.storedCampaignList)
		}
	}, [])

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const handleChangeCampaignOptions=  (event)=>{
		setCampaignAddOption(event.target.value)
	}
    
	const handleCampaignChange=(event)=>{
		setSelectedCampaignId(event.target.value)
	}

	const submit= async()=>{
		if(value === ADDTOCAMPAIGN && selectedCampaignId === ''){
			window.showNotification('ERROR',"You must select a campaign");
		} else {
			if (value === ADDTOCAMPAIGN && campaignAddOption === PROCESSALLATONCE) {
				await mapLeadProcessAction({action : 'select_campaign',campaignId : selectedCampaignId}).then(res => {
					if(res.data.success) {
						props.onClose();
						window.showNotification('SUCCESS',res.data.message);
						history.push('/user/map-orders');
					} else {
						window.showNotification('ERROR',res.data.message);
					}
				});
			} else if (value === ADDTOCAMPAIGN && campaignAddOption === PROCESSINDRIPMODE) {
				props.onSubmit(value ,'',selectedCampaignId);
			} else if(value === CREATELIST){
				await mapLeadProcessAction({action: 'process_contacts'}).then((res)=>{
					if (res.data.success) {
						window.showNotification("SUCCESS",res.data.message)
						props.onSubmit(value ,res.data.data.processId)
					} else {
						window.showNotification('ERROR',res.data.message);
					}
				})
			} else if (value === SENDMESSAGE) {
				props.onSubmit(value ,'');
			}
		}
}

	return (
		<React.Fragment>
			<GlobalModal {...props} hideFooter={true}  className="ppm_select_action_modal global-medium-modal global-xs-modal"
                    >
			<div className="ppc__search_modal_wrapper select_action_modal">
				<form>
					<FormControl component="fieldset">
						<RadioGroup
							className="global__radioFormGroup"
							aria-label="gender"
							name="gender1"
							value={value}
							onChange={handleChange}>
							<FormControlLabel
								value={SENDMESSAGE}
								control={<Radio />}
								label={
									<div className="d-flex align-items-center">
										Send A Message <span>(Email, video email, text, ringless vmail, direct mail, or a gift)</span>
										<BootstrapTooltip title="Send email/sms/gift-cards" arrow placement="top">
											<ErrorOutlineIcon className="ml-2 errorOutlineIcon" />
										</BootstrapTooltip>
									</div>
								}
							/>

							<FormControlLabel
								value={ADDTOCAMPAIGN}
								control={<Radio />}
								label={
									<div className="d-flex align-items-center">
										Add to A Campaign
										<BootstrapTooltip title="Add selected contacts to campaign" arrow placement="top">
											<ErrorOutlineIcon className="ml-2 errorOutlineIcon" />
										</BootstrapTooltip>
									</div>
								}
							/>
                            {(value === ADDTOCAMPAIGN) &&
                            <div className="global__select_form_control secondRadioOption">
                                <FormControl> 
								<Select
							native
							value={selectedCampaignId}
							onChange={handleCampaignChange}
							name="select_option">
							<option aria-label="" value="" >Select Campaign</option>

							{campaignList != null && campaignList.length>0 && campaignList.map((campaign, index)=>(
							<option value={campaign.id}>{campaign.title}</option>
							))
							}

						</Select>
    							</FormControl>
                                <RadioGroup
							className="global__radioFormGroup second_radioFormGroup"
							aria-label="gender"
							name="gender1"
							value={campaignAddOption}
							onChange={handleChangeCampaignOptions}>
                                <FormControlLabel
    								value={PROCESSALLATONCE}
    								control={<Radio />}
    								label={
    									<div className="d-flex align-items-center">
    										Process all at once
											<BootstrapTooltip title="Import all contacts to CRM" arrow placement="top">
    											<ErrorOutlineIcon className="ml-2 errorOutlineIcon" />
											</BootstrapTooltip>
    									</div>
    								}
                                    className="mt10px mt-2"
    							/>
                                
                                <FormControlLabel
    								value={PROCESSINDRIPMODE}
    								control={<Radio />}
    								label={
    									<div className="d-flex align-items-center">
    										Process in drip mode
											<BootstrapTooltip title="Import batch contacts to CRM. (Click Next button to see batch setup)" arrow placement="top">
    											<ErrorOutlineIcon className="ml-2 errorOutlineIcon" />
											</BootstrapTooltip>
    									</div>
    								}
    							/>
								</RadioGroup>
                            </div>
							
							}
							<FormControlLabel
								value={CREATELIST}
								control={<Radio />}
								label={
									<div className="d-flex align-items-center">
										Create A List
										<BootstrapTooltip title="By creating lists, you can filter contacts and add to contact book" arrow placement="top">
											<ErrorOutlineIcon className="ml-2 errorOutlineIcon" />
										</BootstrapTooltip>
									</div>
								}
							/>
						</RadioGroup>
					</FormControl>
				</form>
				<div className="d-flex align-align-items-center justify-content-center">
                <Button className="accent--bg--color ppc__blue_btn search__contracts" onClick={()=>submit()}>
					{"Next"}
				</Button>
            </div>
			</div>
			</GlobalModal>
		</React.Fragment>
	);
}

const mapStateToProps = state => {
    return {
		storedCampaignList: state.mapReducer.campaignList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAllUserCampaignList: (params, callback) => dispatch(fetchAlUserCampaignList(params, callback)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAction);
