import React, { useState } from 'react'

export default function ActionModalComponent(props){



    return (
        <React.Fragment>
            asbdjabskdbasbdkasbjda
            aksdasdasdasndlknas
        </React.Fragment>
    )
}