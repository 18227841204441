import React, { } from "react";
import { Button } from "@material-ui/core";

const STARTFROMSCRATCH=1
const QUICKDELIVERY=2

export default function SendingProcess(props) {



	return (
		<React.Fragment>
			<div className="sending_process_modal_wrapper">
				<form>
					<div className="d-flex align-align-items-center justify-content-center">
                        <Button className="ppc__blue_btn search__contracts accent--bg--color" onClick={()=>props.onSubmit(STARTFROMSCRATCH)}>Start From Scratch</Button> 
                        <Button className="ppc__blue_btn search__contracts accent--bg--color" onClick={()=>props.onSubmit(QUICKDELIVERY)}>Quick Delivery</Button>
                    </div>
				</form>
			</div>
		</React.Fragment>
	);
}
