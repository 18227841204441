import React, { Component } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import HealthCheck from '../components/HealthCheck';

// import components

import IndexOld from '../components/map/IndexOld';

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/map-finder" component={IndexOld}/>
                    <Route exact path="/map-finder/health-check" component={HealthCheck}/>

                </Switch>
            </Router>
        );
    }
}

export default Routes;