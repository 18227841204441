export const IconList = {
    search : <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 11.3633H11.71L11.43 11.0933C12.41 9.95328 13 8.47328 13 6.86328C13 3.27328 10.09 0.363281 6.5 0.363281C2.91 0.363281 0 3.27328 0 6.86328C0 10.4533 2.91 13.3633 6.5 13.3633C8.11 13.3633 9.59 12.7733 10.73 11.7933L11 12.0733V12.8633L16 17.8533L17.49 16.3633L12.5 11.3633ZM6.5 11.3633C4.01 11.3633 2 9.35328 2 6.86328C2 4.37328 4.01 2.36328 6.5 2.36328C8.99 2.36328 11 4.37328 11 6.86328C11 9.35328 8.99 11.3633 6.5 11.3633Z" fill="#3C7EF3"/>
            </svg>,
    addToContactSVG: <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3"></circle>
        <path d="M14.0977 15.6202V11.7534L19.0583 9.76926V17.6043L14.0977 15.6202Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <mask id="path-3-inside-1" fill="white">
            <path d="M13.0349 11.502H12.5547V15.864H13.0349V11.502Z"></path>
        </mask>
        <path d="M13.0349 11.502H12.5547V15.864H13.0349V11.502Z" fill="#3C7EF3"></path>
        <path d="M12.5547 11.502V10.502H11.5547V11.502H12.5547ZM13.0349 11.502H14.0349V10.502H13.0349V11.502ZM13.0349 15.864V16.864H14.0349V15.864H13.0349ZM12.5547 15.864H11.5547V16.864H12.5547V15.864ZM12.5547 12.502H13.0349V10.502H12.5547V12.502ZM12.0349 11.502V15.864H14.0349V11.502H12.0349ZM13.0349 14.864H12.5547V16.864H13.0349V14.864ZM13.5547 15.864V11.502H11.5547V15.864H13.5547Z" fill="#3C7EF3" mask="url(#path-3-inside-1)"></path>
        <path d="M10.0567 11.9004H11.4936V15.2636L10.0567 15.267C10.0565 15.267 10.0564 15.267 10.0562 15.267C9.91702 15.2669 9.78357 15.2115 9.68514 15.1131C9.58661 15.0146 9.53125 14.8809 9.53125 14.7416V12.4258C9.53125 12.2865 9.58661 12.1528 9.68514 12.0543C9.78368 11.9557 9.91732 11.9004 10.0567 11.9004Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M20.8347 12.9346C21.0122 13.1121 21.1121 13.3527 21.1125 13.6037C21.1121 13.8548 21.0122 14.0954 20.8347 14.2729L21.1882 14.6264L20.8347 14.2729C20.7708 14.3367 20.6988 14.3905 20.6211 14.4333V12.7742C20.6988 12.817 20.7708 12.8708 20.8347 12.9346Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M11.7403 19.5082L11.74 19.5082C11.6321 19.5083 11.5272 19.4731 11.4412 19.4081C11.3555 19.3433 11.2932 19.2523 11.2638 19.149L10.6525 16.9312H11.6758L12.2147 18.8867C12.2147 18.8868 12.2147 18.887 12.2148 18.8871C12.2346 18.9599 12.2374 19.0364 12.223 19.1105L12.7137 19.2062L12.223 19.1105C12.2085 19.1847 12.1771 19.2546 12.1312 19.3148C12.0853 19.3749 12.0262 19.4236 11.9584 19.4572L12.1802 19.9053L11.9584 19.4572C11.8906 19.4907 11.816 19.5082 11.7403 19.5082Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
    </svg>,

    addToListSVG: <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3"></circle>
        <path d="M20.3075 13.8228L20.3082 13.8234C20.4992 13.9834 20.5536 14.2513 20.4504 14.4755L19.6345 13.7909C19.8363 13.6517 20.1145 13.6605 20.3075 13.8228Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M16.1891 17.9758L16.9012 18.5732L15.9475 18.9868L16.1891 17.9758Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M19.3703 15.7913L18.0697 17.3304L17.2175 16.6153L18.5182 15.0763L19.3703 15.7913Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M17.135 10.597H16.5234V10.016L17.135 10.597Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M15.0144 16.5832L15.0143 16.5832L15.0124 16.5855C14.7893 16.8527 14.6286 17.1718 14.5476 17.5107L14.2203 18.8804L14.2202 18.8806C14.0828 19.4568 14.2805 20.0539 14.7338 20.4344L14.7342 20.4347C14.7634 20.4592 14.7933 20.4825 14.8238 20.5045H10.0405C9.73429 20.5045 9.48438 20.2546 9.48438 19.9483V10.0439C9.48438 9.7377 9.73429 9.48779 10.0405 9.48779H14.8213V10.8065C14.6721 10.6779 14.4777 10.6001 14.2652 10.6001H10.7446C10.2739 10.6001 9.89258 10.9814 9.89258 11.4522C9.89258 11.9229 10.2739 12.3042 10.7446 12.3042H14.2652C14.5578 12.3042 14.8159 12.1568 14.9693 11.9323C15.1226 12.1568 15.3807 12.3042 15.6734 12.3042H17.6377V13.4791L15.8186 15.6316C15.7837 15.193 15.4169 14.8482 14.9693 14.8482H10.7446C10.2739 14.8482 9.89258 15.2295 9.89258 15.7002C9.89258 16.171 10.2739 16.5523 10.7446 16.5523H14.9693C14.9942 16.5523 15.0188 16.5512 15.0432 16.5491L15.0144 16.5832ZM16.7347 20.5045L17.6087 20.1256C17.5344 20.3454 17.3259 20.5045 17.0816 20.5045H16.7347ZM16.3775 12.7359H10.7446C10.2739 12.7359 9.89258 13.1172 9.89258 13.5879C9.89258 14.0587 10.2739 14.44 10.7446 14.44H16.3775C16.8482 14.44 17.2295 14.0587 17.2295 13.5879C17.2295 13.1172 16.8482 12.7359 16.3775 12.7359ZM10.7446 18.6646H13.561C14.0318 18.6646 14.4131 18.2833 14.4131 17.8126C14.4131 17.3418 14.0318 16.9605 13.561 16.9605H10.7446C10.2739 16.9605 9.89258 17.3418 9.89258 17.8126C9.89258 18.2833 10.2739 18.6646 10.7446 18.6646Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
    </svg>,
    addTagSVG: <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3"></circle>
        <path d="M15.24 9.68308L15.2401 9.68305C15.3376 9.58554 15.4699 9.53076 15.6074 9.53076H19.8763C20.1635 9.53076 20.3959 9.76316 20.3959 10.0503V14.3188C20.3959 14.4566 20.3412 14.5891 20.2434 14.6872C20.2433 14.6873 20.2432 14.6874 20.2431 14.6875L14.687 20.2427L14.6869 20.2427C14.484 20.4456 14.1552 20.4456 13.9524 20.2427L9.68364 15.974C9.48033 15.7704 9.48065 15.4414 9.68341 15.2387L15.24 9.68308ZM16.8274 11.8346C16.8274 12.5328 17.3938 13.0993 18.092 13.0993C18.7903 13.0993 19.3567 12.5328 19.3567 11.8346C19.3567 11.1363 18.7903 10.5699 18.092 10.5699C17.3938 10.5699 16.8274 11.1363 16.8274 11.8346Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
    </svg>,
    addToPipelineSVG: <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14.5" stroke="#3C7EF3"></circle>
        <path d="M9.68403 11.5381H18.4133L17.796 12.596H10.3014L9.68403 11.5381Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M12.6575 16.7126L12.3889 16.2427H15.7108L15.4423 16.7126H12.6575Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M11.4817 14.6545L11.2132 14.1846H16.8869L16.6184 14.6545H11.4817Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
        <path d="M15.018 19.9462H13.0781V18.3003H15.018V19.9462Z" fill="#3C7EF3" stroke="#3C7EF3"></path>
    </svg>,
}