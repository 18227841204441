import { all } from "redux-saga/effects";
import contactDetailMiddleware from "../components/contactDetails/redux/contactDetailMiddleware";
import cardBroadcastMiddleware from "../components/DirectCardSend/redux/cardBroadcastMiddleware";
import videoEmailMiddleware from "../components/Video/redux/videoEmailMiddleware";
import mapMiddleware from "./mapMiddleware";
import tagMiddleware from "./tagMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            mapMiddleware(),tagMiddleware(),videoEmailMiddleware(),contactDetailMiddleware(),cardBroadcastMiddleware()
        ]
    )
}
