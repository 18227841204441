import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { saveContactFromMapSidebar } from "../../../../api/mapApi";
import {carrierLookupVerifyContactV1Api} from "../../../../api/enhanceData";

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    textAlign: "center",
    marginBottom: "8px",
  },
  contentWidth: {
    width: "600px",
  },
  validateButton: {
    width: "100%",
    background: "#006df5 !important",
    color: "white !important",
    fontSize: "18px !important",
    "&:hover,&:focus": {
      background: "#006df5 !important",
    },
  },
  cancelButton: {
    width: "100%",
    background: "#d53d3d !important",
    color: "white !important",
    fontSize: "18px !important",
    "&:hover ,&:focus": {
      background: "#d53d3d !important",
    },
  },
  afterValidationModalWidth: {
    minWidth: "500px",
  },
  doNotCall: {
    color: "white !important",
    padding: " 1px 14px !important",
    background: "#d53d3d !important",
    borderRadius: "20px !important",
    "&:hover,&:focus":{
      background: "#d53d3d !important",
    }
  },
  verifiedOk:{
    color: "black !important",
    background: "rgb(0, 255, 145) !important",
    padding: " 1px 14px !important",
    borderRadius: "20px !important",
    "&:hover,&:focus":{
      background: "rgb(0, 255, 145) !important",
    }
  }
});

const ValidationModal = ({
  openValidationModal,
  handleClose,
  markerContactDetails,
  checkDNC,
  validatePhoneNumber,
  validateEmail,
  handleCloseModal,
  skipTrace,
  phoneNumber,
  setDncStatus,
  email,
  setShowValidationIconForPhone,
  setShowEmailValidationIcon
}) => {
  const [openAfterValidationModal, setOpenAfterValidationModal] =
    useState(false);
  const [carrierResponseData, setCarrierResponseData] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [isValidatingNumber,setIsValidatingNumber]=useState(false)
  const [isCheckingDNC,setIsCheckingDNC]=useState(false)


  const postContact = () => {
    setIsValidating(true);
    setIsValidatingNumber(true)
    setIsCheckingDNC(true)
    if (checkDNC) {
      const contactDetails = { ...markerContactDetails, checkDNC: true };
      handleAddContact(contactDetails);
    }
    if (validatePhoneNumber) {
      const contactDetails = { ...markerContactDetails, validateNumber: true };
      handleAddContact(contactDetails);
    }
    if (validateEmail) {
      const contactDetails = { ...markerContactDetails, validateEmail: true };
      handleAddContact(contactDetails);
    }
  };
  const handleCloseValidationAfterModal = () => {
    setOpenAfterValidationModal(false);
    handleCloseModal();
  };

  const handleAddContact = async (contactDetails) => {
    // const contactDetails={...markerContactDetails,validateEmail:true}
 
    try {
      if(skipTrace){
        let payload={}
        if(checkDNC){
           payload = { 
            contact:{
              number:phoneNumber
            },
            lookupType:["DNC_LOOKUP"],
            requestModule:"CLEAN_DATA" 
          }; 
        }
        if(validatePhoneNumber){
          payload = { 
           contact:{
             number:phoneNumber
           },
           lookupType:["CARRIER_LOOKUP"],
           requestModule:"CLEAN_DATA" 
         }; 
       }
       if(validateEmail){
        payload = { 
         contact:{
           email:email
         },
         lookupType:["EMAIL_LOOKUP"],
         requestModule:"CLEAN_DATA" 
       }; 
     }
       
        await carrierLookupVerifyContactV1Api(payload)
        .then((response) => {
          if (response) {
          
            if(response.data[0]["email-validation-status"]==="VALID"){
              setShowEmailValidationIcon(response.data[0]["email-validation-status"])
            }
            if(response.data[0]["email-validation-status"]==="INVALID"){
              setShowEmailValidationIcon(response.data[0]["email-validation-status"])
            }
            if(response.data[0]["number-validation-status"]==='VALID'){
              setShowValidationIconForPhone(response.data[0]["number-validation-status"])
            }
            
              if (response && response["dnc-lookup-status"] === "REQUEST_DONE"){
                  if (response["dnc-validation-infos"]["dncStatus"] === "YES"){
                      setDncStatus('DO_NOT_CALL');
                  }else if (response["dnc-validation-infos"]["dncStatus"] === "NO"){
                      setDncStatus('VERIFIED_OK');
                  }
                
                  window.showNotification("SUCCESS", "Validated successfully");
              }
          } else {
              setDncStatus("NOT_VERIFIED");
              window.showNotification("ERROR", "Failed to validate");
          }
          handleClose();
      })
      .catch((err) => {
          window.showNotification("ERROR", `Something went wrong while validate`);
      })
      .finally(() => {});
      }
      else{
        const payload = { contactDetails: contactDetails };
        await saveContactFromMapSidebar(payload)
        .then((res) => {
          if (res && res.data.success) {
            setCarrierResponseData(res.data.data.data);
            setContactId(res.data.data.data.insertedData.id);

            setOpenAfterValidationModal(true);
            window.showNotification("success", res.data.message);
            handleClose();
          } else {
            window.showNotification(
              "error",
              "Something went wrong try again later"
            );
          }
        })
        .finally(() => {});
      }
      
    } catch (e) {}
    setIsValidating(false);
    setIsValidatingNumber(false)
    setIsCheckingDNC(false)
  };



  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openValidationModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.contentWidth}>
          {validateEmail && (
            <Typography variant="h4" className={classes.title}>
              {" "}
              Email Validation will cost: $0.01
            </Typography>
          )}
          {validatePhoneNumber && (
            <Typography variant="h4" className={classes.title}>
              {" "}
              Number Validation will cost: $0.0045
            </Typography>
          )}
          {checkDNC && (
            <Typography variant="h4" className={classes.title}>
              {" "}
              DNC Status Check will cost: $0.012
            </Typography>
          )}
          <Typography style={{ textAlign: "center" }}>
            These credits will be deducted from your balance.
          </Typography>
          <Box
            display={"flex"}
            sx={{
              gridColumnGap: "16px",
              paddingTop: "40px",
              paddingBottom: "16px",
            }}
          >
           {validateEmail&& <Button
              disableElevation
              className={classes.validateButton}
              onClick={postContact}
            >
              {" "}
              {isValidating ? "Validating Email..." : "Validate Email"}
            </Button>}

            {validatePhoneNumber&&<Button
              disableElevation
              className={classes.validateButton}
              onClick={postContact}
            >
              {" "}
              {isValidatingNumber ? "Validating Number..." : "Validate Number"}
            </Button>}
           {checkDNC&& <Button
              disableElevation
              className={classes.validateButton}
              onClick={postContact}
            >
              {isCheckingDNC ? "Checking DNC ..." : "Check DNC Status"}
              
            </Button>}
            <Button
              disableElevation
              className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAfterValidationModal}
        onClose={handleCloseValidationAfterModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {carrierResponseData?.carrierResponse.length !== 0 ? (
          <DialogContent className={classes.afterValidationModalWidth}>
            {
              checkDNC? <Box>
              <Grid container spacing={3}>
                    <Grid item xs={4}>
                      DNC Status
                    </Grid>
                    <Grid item xs={8}>
                     
                      <Button
                      disableElevation
                      className={` ${carrierResponseData?.carrierResponse?.dncStatus==='YES'?classes.doNotCall:classes.verifiedOk }   `}
                      variant="contained"
                    >
                      {carrierResponseData?.carrierResponse?.dncStatus==='YES'?'Do Not Call':'Verifed Ok'}
                    </Button>

                    </Grid>
               
                 </Grid>
              </Box>:
           
            <Box
              mb={"20px"}
              display={"flex"}
              alignItems={"center"}
              sx={{gridGap:'16px'}}
            >
              <Typography>Status :</Typography>
              {
                validateEmail && <Button
                disableElevation
                variant="contained"
                style={{ background: "#006df5", color: "white" }}
              >{((carrierResponseData?.status==='success' && carrierResponseData?.carrierResponse.state==='undeliverable') )?'Not Valid':'Valid'}
              </Button>
              }
                 {
                validatePhoneNumber && <Button
                disableElevation
                variant="contained"
                style={{ background: "#006df5", color: "white" }}
              >{(carrierResponseData?.status!=='success')?'Not Valid':'Valid'}
              </Button>
              }
            </Box>
           
          }
            <Grid container spacing={3}>
              {carrierResponseData?.carrierResponse?.user && (
                <>
                  <Grid item xs={4}>
                    User
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.user}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.email && (
                <>
                  <Grid item xs={4}>
                    Email
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.email}
                  </Grid>
                </>
              )}
            
              {carrierResponseData?.carrierResponse?.score>=0 && (
                <>
                  <Grid item xs={4}>
                   Score
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.score}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.state && (
                <>
                  <Grid item xs={4}>
                    State
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.state}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.domain && (
                <>
                  <Grid item xs={4}>
                    Domain
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.domain}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.reason && (
                <>
                  <Grid item xs={4}>
                    Reason
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.reason}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.duration && (
                <>
                  <Grid item xs={4}>
                    Duration
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.duration}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.mxRecord && (
                <>
                  <Grid item xs={4}>
                    MX Records
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.mxRecord}
                  </Grid>
                </>
              )}
              {carrierResponseData?.carrierResponse?.smtpProvider && (
                <>
                  <Grid item xs={4}>
                    SMTP Provider
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.smtpProvider}
                  </Grid>
                </>
              )}
                 {carrierResponseData?.carrierResponse?.phoneNumber && (
                <>
                  <Grid item xs={4}>
                    Phone Number
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.phoneNumber}
                  </Grid>
                </>
              )}
                {carrierResponseData?.carrierResponse?.city && (
                <>
                  <Grid item xs={4}>
                  City
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.city}
                  </Grid>
                </>
              )}
               {carrierResponseData?.carrierResponse?.lineType && (
                <>
                  <Grid item xs={4}>
                   Phone Type
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.lineType}
                  </Grid>
                </>
              )}
               {carrierResponseData?.carrierResponse?.network && (
                <>
                  <Grid item xs={4}>
                   Network
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.network}
                  </Grid>
                </>
              )}
                {carrierResponseData?.carrierResponse?.phone && (
                <>
                  <Grid item xs={4}>
                    Phone
                  </Grid>
                  <Grid item xs={8}>
                    {carrierResponseData?.carrierResponse?.phone}
                  </Grid>
                </>
              )}
            </Grid>
            <Box
              display={"flex"}
              sx={{
                gridColumnGap: "16px",
                paddingTop: "40px",
                paddingBottom: "16px",
              }}
            >
              <Button
                href={`/contacts/${contactId}`}
                disableElevation
                className={classes.validateButton}
                onClick={postContact}
              >
                Go To Contact
              </Button>
              <Button
                disableElevation
                className={classes.cancelButton}
                onClick={handleCloseValidationAfterModal}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        ) : (
          <>
            <Box
              className={classes.afterValidationModalWidth}
              sx={{ padding: "16px", paddingBottom: 0 }}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <Typography textAlign="center" variant="h4">
                  No Data Found
                </Typography>
              </Box>
              <Box
                display={"flex"}
                sx={{
                  gridColumnGap: "16px",
                  paddingTop: "40px",
                  paddingBottom: "16px",
                }}
              >
                <Button
                  href={`/contacts/${contactId}`}
                  disableElevation
                  className={classes.validateButton}
                  onClick={postContact}
                >
                  Go To Contact
                </Button>
                <Button
                  disableElevation
                  className={classes.cancelButton}
                  onClick={handleCloseValidationAfterModal}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ValidationModal;
