import React from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Utils from '../../helpers/Utils';

const DateTimeSetting=(props)=>{

    return (
        <React.Fragment>
            <div className="d-flex form-group justify-content-center align__item__center__v2 my-3 w-100">
                <div className='pr-2 d-flex align-items-center send__drip__eastern__time__flex1'>
                    <div>
                    <h6 className="text-dark-blue m-0 text-left mb-2">Date</h6>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                                format="MM/dd/yyyy"
                                value={props.date}
                                onChange={(date)=>props.timeSettingValue(date, 'date')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="add_activity_text_field radius-5 white" 
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="pl-2 d-flex align-items-center send__drip__eastern__time__flex1">
                    <div className={"pr-2 timepicker-wrapper"}>
                        <h6 className="text-dark-blue m-0 text-left mb-2">Time</h6>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <KeyboardTimePicker
                                    format="HH:mm"
                                    value={props.time}
                                    onChange={(date) => props.timeSettingValue(date,'time')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    className="modal_bordered_text_field radius-5 white"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                    
                </div>
                <span className="send__drip__eastern__time__v2"> {Utils.getAccountData('userTimezone')}</span>
            </div>
        </React.Fragment>
    );
    
}
export default DateTimeSetting;