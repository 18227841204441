import React, { useEffect, useState } from "react";
import _ApiList from "../../SendModules/api/ApiList";
import SmsSend from "../../SendModules/sms";
import ModalGlobalModal from "../ModalGlobalModal";

const SmsSendModal = (props) => {

    const [totalContacts,setTotalContacts] = useState(0);
    const [smsCost,setSmsCost] = useState(0);

    useEffect(() => {
        if(props.from === 'map') {
            _ApiList.getOrderSummaryForMap({type:'SMS'}).then(res => {
                if(res.data.success) {
                    setTotalContacts(res.data.data.recipientsCount);
                    setSmsCost(res.data.data.perCost);
                } else {
                    window.showNotification("ERROR",res.data.message);
                    props.onClose(false);
                }
            });
        }
    },[]);

    return(
        <ModalGlobalModal
            open={props.open}
            title="Send Sms"
            onClose={() => props.onClose(false)}
            width={800}
            hideFooter={true}
            modalClass="fixed-height-100-80 v3__send_sms global__send_module_modal"
            className="global_modal_overflow_hidden activity__modal_overflow contact__send_sms_modal"
        >
            <SmsSend
                showTimeSetting = {true}

                showSelectedContact= {false}
                selectedContactItem= {(<div>contact information</div>)}

                contact_id={props.data.id}
                contact_data={props.data}
                onSubmit={()=> (props.onSubmit !== undefined && props.onSubmit != null) ? props.onSubmit() : console.log('submit')}

                onClose={() => props.onClose(false)}
                from={props.from}
                perSmsPrice={smsCost}
                totalContacts={totalContacts}
            />
        </ModalGlobalModal>
    )

}
export default SmsSendModal;