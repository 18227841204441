import {sendRequest, sendRequestBackEnd} from "./rootApi";
import Api from "../constants/Api";

export const getContactListForDropdown = async (payload) => {
    return sendRequest("post", Api.getAllList, payload);
}

export const getUserTagListNew = async (payload) => {
    return sendRequest("post", Api.tagListNew, payload);
}

export const getPipelineList = async (payload) => {
    return sendRequest("post", Api.getPipelineList, payload);
}

export const getStageListWithPagination = async (payload) => {
    return sendRequest("post", Api.stageListWithPagination, payload);
}

export const getAllCampaignForDropdown = async (payload) => {
    return sendRequest("post", Api.getCampaignListForDropdown, payload);
}

export const getPowerDialerList = async (payload) => {
    return sendRequestBackEnd("get", Api.powerDialerList, payload);
}

export const getCarrierLookupCreditSettingsApi = async (payload) =>{
    return sendRequest("post", Api.getCarrierLookupCreditSettings, payload);
}

export const carrierLookupVerifyContactV1Api = async (payload) =>{
    return sendRequestBackEnd("post", Api.carrierLookupVerifyContactV1, payload);
}