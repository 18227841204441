import React, { useState, useEffect } from "react";
import { Save, SearchOutlined } from "@material-ui/icons";
import { icons } from "./icon";
import "./assignToCampaign.css";
import GlobalModal from "../../globals/Modal/GlobalModal";
import NewLoader from "../../Common/NewLoader";
import {
  cleanContactsActionsApi,
  saveContactFromMapSidebar,
} from "../../../api/mapApi";
import { getAllCampaignForDropdown } from "../../../api/enhanceData";

let assign_campaign_search_interval = null;

const EachCampaign = (props) => {
  const checkSelect = (id) => {
    return !!props.selectedCampaigns.includes(id);
  };

  return (
    <li>
      <div className="custom-checkbox-wrapper">
        <span
          className="custom-checkbox-icon"
          onClick={() => {
            props.selectCampaign(props.data.id);
          }}
          key={`id_${props.data.id};`}
        >
          {checkSelect(props.data.id) ? icons.check : icons.unCheck}
        </span>
        <span>{props.data.title}</span>
      </div>
    </li>
  );
};

const ModalAssignToCampaign = (props) => {
  const { markerContactDetails ,handleCloseModal} = props;
  const [runningCampaign, setRunningCampaign] = useState([]);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHaMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchText, setSearchText] = useState(false);
  useEffect(() => {
    getCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const saveCampaignData = async () => {
    const payload = {
      campaignId: selectedCampaigns[0],
      contactDetails: markerContactDetails,
    };
    await saveContactFromMapSidebar(payload).then((response) => {
      if (response.data.success) {
        window.showNotification("SUCCESS", response.data.message);
        handleCloseModal()
        props.onClose();
      } else if (
        response.data.success === false &&
        response.data.data.status === "validation-error"
      ) {
        window.showNotification(
          "ERROR",
          response.data.data.html[Object.keys(response.data.data.html)[0]][0]
        );
      }
    });
  };

  const getCampaignData = (page = currentPage) => {
    getAllCampaignForDropdown({
      page: page,
      from: "contact_list",
      searchText: searchText,
    }).then((response) => {
      let data = response?.data;

      if (page === 0) {
        setRunningCampaign(data.data);
      } else {
        let data = [...runningCampaign, ...data.data];
        setRunningCampaign(data);
      }
      if (data.data.length >= 20) {
        setHaMore(true);
        setCurrentPage(page + 1);
      } else {
        setHaMore(false);
      }
      setLoadingMore(false);
    });
  };

  const handleRadio = (value) => {
    setSubmitEnable(true);
    setSelectedCampaigns([value]);
  };

  const changeSearch = (e) => {
    if (assign_campaign_search_interval == null) {
      assign_campaign_search_interval = setTimeout(() => {
        setCurrentPage(0);
        setSearchText(e.target.value.trim());
        clearTimeout(assign_campaign_search_interval);
      }, 500);
    } else {
      clearTimeout(assign_campaign_search_interval);
      assign_campaign_search_interval = setTimeout(() => {
        setCurrentPage(0);
        setSearchText(e.target.value.trim());
        clearTimeout(assign_campaign_search_interval);
      }, 500);
    }
  };

  const handleScroll = (e) => {
    if (
      hasMore &&
      !loadingMore &&
      Math.round(e.target.scrollTop + e.target.clientHeight) >=
        Math.round(e.target.scrollHeight)
    ) {
      setLoadingMore(true);
      getCampaignData();
    }
  };

  const addToCampaign = () => {
    const payload = {
      cleanContactIds: [props.cleanContactId],
      filterType: "SEGMENT",
      action: "ADD_TO_CAMPAIGN",
      campaignIds: selectedCampaigns,
    };

    cleanContactsActionsApi(payload)
      .then((res) => {
        if (res && res.success) {
          window.showNotification("success", res.message);
          props.onClose();
          props.callback({
            success: true,
            removeItem: props.cleanContactId,
            index: props.index,
          });
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (selectedCampaigns[0]) {
      setLoading(true);
      addToCampaign();
      saveCampaignData();
    } else {
      window.showNotification("ERROR", "No campaign selected");
    }
  };

  return (
    <GlobalModal
      {...props}
      title="Assign To Campaign"
      buttonText="Add"
      buttonIcon={<Save />}
      onSubmit={handleSubmit}
      hideFooter={false}
      modalClass="manage-tag-modal modal-assign-campaign update"
      className="manage-tag-modal modal-assign-campaign create-new-list update scroll_v2 global-medium-modal enhanceDataModal"
      submitButtonDisable={loading || !submitEnable}
    >
      {loading && (
        <NewLoader loading={loading} onlyLoader={true} size={26} message={""} />
      )}
      <div className="row">
        <div className="col-md-12 contact-to-campaign">
          <div className="campaign-search-list">
            <div
              className="campaign-search form"
              // style={{ paddingLeft: "10px" }}
            >
              <div className="form-group m-0">
                <div
                  className="m-input-icon m-input-icon--right"
                  style={{ position: "relative" }}
                >
                  <input
                    onChange={changeSearch}
                    type="text"
                    className="form-control m-input customFormControlField alt"
                    placeholder="Type and search"
                  />
                  <SearchOutlined className="assign__searchIcon" />
                  <span className="m-input-icon__icon m-input-icon__icon--right">
                    <span>
                      <i className="flaticon-search-1" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="upload-single-item-main2 upload-single-item-main">
              <ul className="awesome__scroll_bar" onScroll={handleScroll}>
                {runningCampaign.map((data, index) => {
                  if (data.status === 3 || data.status === 5) {
                    return (
                      <EachCampaign
                        selectCampaign={handleRadio}
                        data={data}
                        key={index}
                        selectedCampaigns={selectedCampaigns}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </GlobalModal>
  );
};
export default ModalAssignToCampaign;
