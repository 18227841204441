import React from "react";

const CsvUploadLoader = () => {
  const cubeColor = '#379591';

  return (
    <div className="loader-container">
      {[1, 2, 3].map((_, index) => (
        <div key={index} className={`cube cube-${index + 1}`} style={{ backgroundColor: cubeColor }}></div>
      ))}
      
      <div className="progress-text">Generating...</div>

      <style>{`
        .loader-container {
          width: 300px;
          height: 300px;
          position: relative;
          perspective: 1000px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .cube {
          width: 50px;
          height: 50px;
          transform-style: preserve-3d;
          animation: rotateCube 5s infinite linear;
        }

        .cube-1 { 
          animation-delay: 0s; 
          transform: translateX(-25px) translateY(-25px); 
        }
        .cube-2 { 
          animation-delay: 0.5s; 
          transform: translateY(-25px); 
        }
        .cube-3 { 
          animation-delay: 2s; 
          transform: translateX(25px) translateY(-25px); 
        }

        @keyframes rotateCube {
          0% { transform: rotateY(0deg) rotateX(0deg); }
          100% { transform: rotateY(360deg) rotateX(360deg); }
        }

        .progress-text {
          position: absolute;
          bottom: 20px;
          font-size: 18px;
          font-weight: bold;
          color: ${cubeColor};
        }
      `}</style>
    </div>
  );
};

export default CsvUploadLoader;