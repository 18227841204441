import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import icons from "../assets/icons";
import { _updateStateField } from "../redux/contactDetailAction";

const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params))
})

const HeaderApp = (props) => {
    const {tab} = props.contactDetailReducer
    const [states, setStates] = useState({
        tab: tab
    })

    useEffect(() => {
        setStates({...states, tab: tab})
    }, [tab])

    const handleUpdateTab = (option) => {
        props._updateStateField({tab: option})
    }

    const handleModal = (modalType) => {
        if(modalType == 'sms'){
            props._updateStateField({showingSmsSendModal: true})
        }
        else if(modalType == 'email'){
            props._updateStateField({showingEmailSendModal: true})
        }
    }

    return (
      <div className="d-flex align-items-center addName__actions_wrapper">     
        <div className="addName__actions">
            <button className={states.tab == 'contact' ? "accent--bg--color" : "accent-bg-opacity"} style={states.tab == 'contact' ? {backgroundColor: '#3c7ef3', color: '#fff'}: {color: '#fff'}} onClick={() => handleUpdateTab('contact')}>{icons.fullScreenIcon} <span className="ml-3">Info</span></button>
            <button className={states.tab == 'note' ? "accent--bg--color" : "accent-bg-opacity"} style={states.tab == 'note' ? {backgroundColor: '#3c7ef3', color: '#fff'}: {color: '#fff'}} onClick={() => handleUpdateTab('note')}>{icons.editIcon} <span className="ml-3">Note</span></button>
            {/* <button onClick={() => handleModal('sms')}>{icons.SmsIcon}</button>
            <button onClick={() => handleModal('email')}>{icons.blackEnvIcon}</button> */}
        </div>
      </div>
    );
}
const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderApp);
export default Header;