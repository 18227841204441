import React, {useContext} from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import {ContactDetailsV2Context} from "../../ContactDetailsV2Reducer";

const PropertyInfo = (props) => {
    const {state, dispatch} = useContext(ContactDetailsV2Context);
    const { flexCenterBetween, flexCenter, textBlueMapContactColor, textBold600 } = ContactDetailsStyle();

    const makePropertyInfo = () => {
        let additionalDetails = "";

        if (state.dwellingType){
            additionalDetails += "Dwelling Type: ";
            additionalDetails += state.dwellingType;
        }else{
            additionalDetails += "Dwelling Type: N/A";
        }

        if (state.estimatedHomeValue){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Estimated Home Value: ";
            additionalDetails += state.estimatedHomeValue;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Estimated Home Value: N/A";
        }

        if (state.purchaseAmount){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Sale Amount: ";
            additionalDetails += state.purchaseAmount;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Sale Amount: N/A";
        }

        if (state.lengthOfResidence){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Length of Residence: ";
            additionalDetails += state.lengthOfResidence;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Length of Residence: N/A";
        }

        if (state.livingSquareFeet){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Living Square Feet: ";
            additionalDetails += state.livingSquareFeet;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Living Square Feet: N/A";
        }

        if (state.landSquareFootage){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Land Square Footage: ";
            additionalDetails += state.landSquareFootage;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Land Square Footage: N/A";
        }

        if (state.propertyPool){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Swimming Pool: ";
            additionalDetails += state.propertyPool;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Swimming Pool: N/A";
        }

        if (state.subdivisionName){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Subdivision Name: ";
            additionalDetails += state.subdivisionName;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Subdivision Name: N/A";
        }

        return additionalDetails;
    };

    return (
        <Box sx={{width:'100% !important'}}>
            <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
                <CustomTitle title={"Property Info"} sx={{ display: "flex", flex: 1, alignItems: "center" }} />
            </Box>

            <Paper variant='outlined' elevation={0} style={{ padding: 15 }}>
                <Box className={flexCenterBetween} sx={{ alignItems: "normal !important", flexWrap: "wrap", gap: 15 }}>
                    <Box>
                        <Typography variant='body1' className={`${textBlueMapContactColor} ${textBold600}`} dangerouslySetInnerHTML={{ __html: makePropertyInfo() }}>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default PropertyInfo;