const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/map`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const carrierLookupBackendApi = `${process.env.REACT_APP_CARRIER_LOOKUP_BACKEND_SERVER}/v1`
const dealModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`
const mysV2Url = `${process.env.REACT_APP_BACKEND_URL}/v2/mys`;

const Api = {
    mapFinder: `${rootUrl}/map-finder`,
    loadAllContactsInMap: `${rootUrl}/load-all-contacts-in-map`,
    getMapDataWithDisposition: `${rootUrl}/get-map-data-with-disposition`,
    loadContactsByTagForMap: `${rootUrl}/fetch-contacts-selected-tagwise`,
    fetchSingleContactForMap: `${rootUrl}/fetch-contact-info-single-map`,

    tagList: `${contactUrl}/tag/list`,
    fetchCampaignList: `${contactUrl}/campaign-list-dropdown`,
    saveContactFromMapSidebar: `${contactUrl}/add-new-contact-from-map-sidebar`,

    mapLeadFinderProcessCampaignSubmit: `${rootUrl}/map-lead-finder-process-campaign-submit`,
    mapLeadFinderProcessAction: `${rootUrl}/map-lead-finder-process-action`,
    mapOrderTitleEditFormSubmit: `${rootUrl}/map-order-title-edit-form-submit`,


    getSendMessageAllCredits: `${rootUrl}/map-lead-finder-get-all-credits`,
    getUserDispositions: `${rootUrl}/get-user-disposition-list`,
    fetchContactsWithSpecificDisposition: `${rootUrl}/fetch-contacts-with-specific-disposition`,
    fetchContactInfoByLatlng: `${contactUrl}/details-by-lat-long`,

    checkMapPackageExist: `${rootUrl}/check-map-package-exist`,
    changeContactDisposition: `${rootUrl}/change-contact-disposition`,

    cleanContactsActions: `${carrierLookupBackendApi}/clean-contacts/actions`,

    getAllList: `${contactUrl}/contact-list-dropdown`,
    tagListNew: `${contactUrl}/tag/list-optimized-new-api`,
    getCampaignListForDropdown: `${contactUrl}/campaign-list-dropdown`,

    getPipelineList: `${dealModuleUrl}/get-all-pipeline`,
    stageListWithPagination: `${contactUrl}/stage-list-with-paginate`,

    powerDialerList: `${tenantBackendV1}/power-dialer/lists`,

    getCarrierLookupCreditSettings: `${contactUrl}/carrier-lookup/get-credit-settings`,

    carrierLookupVerifyContactV1: `${carrierLookupBackendApi}/carrier/lookup/single/v1`,

    getUserMysPackageStatus: `${mysV2Url}/get-package-status`
}

export default Api;