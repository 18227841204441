import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

// import './assets/global-direct-mail.css';
// import './assets/global-direct-mail-responsive.css';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import moment from 'moment';
// import ScheduleSetting from '../CampaignDetails/ScheduleSetting';
import { followupSave, updateEmailStepSetting } from "./redux/cardBroadcastAction";
import { getBroadcastOrderSummaryForMap, getThirdPartyService, producePDF, senBroadcastFromMap } from './redux/cardBroadcastApi';
import { Skeleton } from '@material-ui/lab';
import CoreConstants from './assets/CoreConstants';
import GlobalModal from './assets/GlobalModal';
import { LastSendStep } from '../map/SendModules/LastSendStep';

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;

const DirectmailTypeConvert = {
   [POSTCARD]: CoreConstants.Timeline.CONTENT_TYPE_POST_CARD,
   [GREETING_CARD]: CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD,
   [LETTER]: CoreConstants.Timeline.CONTENT_TYPE_LETTER,
   [GIFT]: CoreConstants.Timeline.CONTENT_TYPE_GIFT
}
const mailTypeConvert = {
   [POSTCARD]: "Postcard",
   [GREETING_CARD]: "Greetingcard",
   [LETTER]: "Giftcard",
   [GIFT]: "Letter"
}

const CardMultipleStepForm = (props) => {

   // const { id } = useParams();
   const [stepNo, setStepNo] = useState(1);
   const [cardSizes, setCardSizes] = useState([]);
   const [loadingThirdPartyInfo, setLoadingThirdPartyInfo] = useState(true)
   const [selectedCardSize, setSelectedCardSize] = useState(cardSizes[0]);
   const [selectedGift, setSelectedGift] = useState(null);

   // const [loadingCategory, setLoadingCategory] = useState(false);
   const [selectedCategoryId, setSelectedCategoryId] = useState('');
   // const [loadingTemplate, setLoadingTemplate] = useState(true);
   const [selectedCategoryTitle, setSelectedCategoryTitle] = useState('');
   const [selectedTemplate, setSelectedTemplate] = useState(null);
   const [textEditorValue, setTextEditorValue] = useState('');

   const [selectedTemplateImageUrl, setSelectedTemplateImageUrl] = useState('');

   const [postcardTemplateList, setPostcardTemplateList] = useState(null);

   //For new template
   const [createNewPostcardFrom, setCreateNewPostcardFrom] = useState('');

   const [uploadedFilelink, setuploadedFilelink] = useState('');
   const [loadingHandWritingStyles, setLoadingHandWritingStyles] = useState('');

   const [selectedHandWritingId, setSelectedHandWritingId] = useState('');
   const [selectedhandwritingTitle, setSelectedhandwritingTitle] = useState('');
   //thirdstep

   const [showPDF, setShowPDF] = useState(false)
   const [loadingPDF, setLoadingPDF] = useState(true)
   const [pdfLink, setPDFLink] = useState('');
   const [letterPages, setLetterPages] = useState(['', '', '', '', '', '', '', '']);
   const [currentPage, setCurrentPage] = useState(0);

   //Fourth Step

   const [loadingCostInfo, setLoadingCostInfo] = useState(true)
   const [totalContacts, setTotalContacts] = useState(null)
   const [perCost, setPerCost] = useState(null)
   // const [selectedDate, setSelectedDate] = useState('')
   // const [selectedTime, setSelectedTime] = useState('')
   // const [sentImmediately, setSentImmediately] = useState(true);
   const [useThanksIo, setUseThanksIo] = useState(false);

   //For campaign
   const [campaignDetails, setCampaignDetails] = useState(props.campaignDetails);
   const [showAllTimeSetting, setShowAllTimeSetting] = useState(props.showAllTimeSettings);

   // const [tabValue, setTabValue] = useState(props.tabValue);
   const [hour, setHour] = useState(props.hour);
   const [min, setMin] = useState(props.min);
   const [day, setDay] = useState(props.day);
   const [recurringDay, setRecurringDay] = useState(props.recurringDay);
   const [laterHour, setLaterHour] = useState(props.laterHour);
   const [laterMin, setLaterMin] = useState(props.laterMin);
   const [time, setTime] = useState(props.time);
   const [startDate, setStartDate] = useState(props.startDate);
   const [arrSlotTimeHour1, setArrSlotTimeHour1] = useState(props.arrSlotTimeHour1);
   const [arrSlotTimeMin1, setArrSlotTimeMin1] = useState(props.arrSlotTimeMin1);
   const [greetingOption, setGreetingOption] = useState(props.greetingOption);
   const [greetingsOptionTimeMin1, setGreetingsOptionTimeMin1] = useState(props.greetingsOptionTimeMin1);
   const [greetingsOptionTimeHour1, setGreetingsOptionTimeHour1] = useState(props.greetingsOptionTimeHour1);
   const [recurringTimeHour1, setRecurringTimeHour1] = useState(props.recurringTimeHour1);
   const [recurringTimeMin1, setRecurringTimeMin1] = useState(props.recurringTimeMin1);


   // for update
   const [updateSetting, setUpdateSetting] = useState(false)
   const [editableCampaignSetting, setEditableCampaignSetting] = useState(null)
   const [editableCampaignSettingId, setEditableCampaignSettingId] = useState(null)

   const [submitSettings, setSubmitSettings] = useState(false)

   const [states, setStates] = useState({
      /* to email state */
      sendTime: 1,
      sending: false,
   })


   useEffect(() => {
      getCardInformation();
      updateCampaignSetting();
   }, []);

   const updateCampaignSetting = () => {
      if (props.editableCampaign !== undefined && props.editableCampaign != null) {

         setTextEditorValue((props.editableCampaign.message !== undefined && props.editableCampaign.message != null) ? props.editableCampaign.message : '')

         if (props.editableCampaign.template_pdf_path !== undefined && props.editableCampaign.template_pdf_path != null) {
            if (props.editableCampaign.template_pdf_path !== undefined && props.editableCampaign.template_pdf_path != null) {
               setPDFLink(props.editableCampaign.template_pdf_path)
            } else if (props.editableCampaign.pdf_url !== undefined && props.editableCampaign.pdf_url != null) {
               setPDFLink(props.editableCampaign.pdf_url)
            }
            setLoadingPDF(false)
         }
      }
   }

   const changeLetterPage = (pageNumber) => {
      let pagesArray = [...letterPages];
      pagesArray[currentPage] = textEditorValue;
      setLetterPages(pagesArray);
      setTextEditorValue(pagesArray[pageNumber]);
      setCurrentPage(pageNumber);
   }

   const getCardInformation = async () => {
      if (props.messageType === POSTCARD || props.messageType === LETTER) {
         await getThirdPartyService({ content: props.messageType })
            .then(response => {
               setUseThanksIo(response.data.useThanksIoGateway)
               if (props.messageType === POSTCARD) {
                  setEditCardSizeAndType(response)
                  // setCardSizes(response.data.sizes)
                  // //If campaign settings Edit
                  // if (props.editableCampaign !== undefined && props.editableCampaign != null) {
                  //    if (props.editableCampaign.file_type !== undefined && props.editableCampaign.file_type != null && response.data.sizes.includes(props.editableCampaign.file_type)) {
                  //       setSelectedCardSize(props.editableCampaign.file_type)
                  //    } else if (props.editableCampaign.card_size !== undefined && props.editableCampaign.card_size != null && response.data.sizes.includes(props.editableCampaign.card_size)) {
                  //       setSelectedCardSize(props.editableCampaign.card_size)
                  //    }
                  //    else {
                  //       setSelectedCardSize(response.data.sizes[0])
                  //    }
                  // } else {
                  //    setSelectedCardSize(response.data.sizes[0])
                  // }


               } else {

                  // If zendirect active
                  if (!response.data.useThanksIoGateway) {

                     setEditCardSizeAndType(response)

                     //If campaign edit

                  } else
                  //If thanksIo active
                  {
                     setCardSizes([])
                     setSelectedCardSize('')
                     setStepNo(2)
                  }
               }
            })
      }
      if (props.messageType === GREETING_CARD) {

         setStepNo(2)
      }
      if(props.messageType === GIFT || props.messageType === GREETING_CARD){
         setSelectedCardSize("5X7")

      }

      setLoadingThirdPartyInfo(false)
   }

   const setEditCardSizeAndType = (response) => {
      setCardSizes(response.data.sizes)
      if (props.editableCampaign !== undefined && props.editableCampaign != null) {

         if (props.editableCampaign.file_type !== undefined && props.editableCampaign.file_type != null && response.data.sizes.includes(props.editableCampaign.file_type)) {
            setSelectedCardSize(props.editableCampaign.file_type)
         } else if (props.editableCampaign.card_size !== undefined && props.editableCampaign.card_size != null && response.data.sizes.includes(props.editableCampaign.card_size)) {
            setSelectedCardSize(props.editableCampaign.card_size)
         }
         else {
            setSelectedCardSize(response.data.sizes[0])
         }

      } else {
         setSelectedCardSize(response.data.sizes[0])
      }
   }

   const moveNextStep = async (e, nextStep) => {
      if (e != null) {
         e.preventDefault();
      }
      if (nextStep === 2) {
         if ((cardSizes.includes(selectedCardSize) || selectedGift != null) && stepNo === 1) {
            setStepNo(nextStep);
         }
         if (props.messageType === LETTER && !useThanksIo) {
            setStepNo(3);
         }
      } else if (nextStep === 3) {

         if (stepNo === 2 && selectedTemplate != null) {
            setStepNo(2.5);
         }
         else if (stepNo === 2.5) {
            setStepNo(nextStep);
         } else {
            window.showNotification('ERROR', "Please select template")
         }
      } else if (nextStep === 4) {
         if (stepNo === 3) {
            if (loadingPDF !== true && pdfLink !== '') {

               if (props.from === undefined || props.from == null || props.from !== "addQuickTemplate") {
                  setStepNo(nextStep);
                  if(props.from === 'map-finder'){
                        getCosting(props.messageType)
                     }
                  

               } else {

                  let formData = []

                  formData['message'] = !useThanksIo && props.message_type === LETTER ? letterPages : textEditorValue;
                  formData['content'] = props.messageType;

                  formData['handwriting_style_id'] = selectedHandWritingId;
                  formData['template_category_id'] = selectedTemplate != null ? selectedTemplate.agency_card_category_id : null;
                  formData['template_id'] = selectedTemplate != null ? selectedTemplate.id : null;
                  formData['template_thumbnail_path'] = selectedTemplate != null ? selectedTemplate.image_thumb_path : null;
                  formData['file_path'] = selectedTemplate != null ? selectedTemplate.image_path : null;
                  formData['file_type'] = selectedCardSize;
                  formData['template_pdf_path'] = pdfLink;
                  formData['gift_production_id'] = props.messageType === GIFT ? selectedGift.gift_production_id : '';
                  formData['gift_product_id'] = props.messageType === GIFT ? selectedGift.gift_product_id : '';
                  formData['gift_product_sku'] = props.messageType === GIFT ? selectedGift.gift_product_sku : '';
                  formData['gift_handling_fee'] = props.messageType === GIFT ? selectedGift.gift_handling_fee : '';
                  formData['gift_price'] = props.messageType === GIFT ? selectedGift.gift_price : '';
                  formData['gift_image_url'] = props.messageType === GIFT ? selectedGift.gift_image_url : '';
                  formData['subject'] = ''
                  props.onSubmit(formData)
               }
            } else {
               window.showNotification('ERROR', 'PDF must generate properly to continue!');
            }
         }
      } else if (nextStep == 5) {
         if (stepNo == 4) {

         }
      }
   }

   const getCosting=async (type)=>{
      await getBroadcastOrderSummaryForMap({type: mailTypeConvert[type]})
                        .then(res=>{
                           if(res.data.success) {
                              setTotalContacts(res.data.data.recipientsCount);
                              setPerCost(res.data.data.perCost);
                              setLoadingCostInfo(false)
                           }else{
                              moveToSpecificStep(3)
                              window.showNotification('ERROR','Something went wrong')
                           }
                        })
   }

   const moveToSpecificStep = (step) => {
      if (step < stepNo) {
         setStepNo(step);
      }
   }

   const closeModal = (e, force = false) => {
      if(e != null){
         e.preventDefault()
      }

      if (createNewPostcardFrom !== '' && !force) {
         setCreateNewPostcardFrom('');
      } else {
         setStepNo(1);
         setSelectedCardSize('4X6')
         props.onClose();
      }
   }

   const selectTemplate = (data) => {

      // setSelectedTemplateId(postcardTemplateList[index].id)
      // setSelectedTemplateImageUrl( postcardTemplateList[index].image_path)
      setSelectedTemplate(data);
   }

   const changeTextInEditor = (text) => {
      setTextEditorValue(text)
   }
   const changeCreateNewPostcardFrom = (e, from) => {
      e.preventDefault()
      setCreateNewPostcardFrom(from)
   }

   const producePDFLink = async () => {

      let payLoad = {
         file_type: selectedCardSize
      }

      if (props.messageType === POSTCARD) {
         payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_POST_CARD
      } else if (props.messageType === GREETING_CARD) {
         payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD
      } else if (props.messageType === LETTER) {
         payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_LETTER
      } else if (props.messageType === GIFT) {
         payLoad.message_type = CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD
      }

      if (!useThanksIo && props.messageType === LETTER) {
         let pagesArray = [...letterPages];
         pagesArray[currentPage] = textEditorValue;
         payLoad.message = pagesArray;
      } else {
         payLoad.handwriting_style_id = selectedHandWritingId;
         payLoad.template_id = selectedTemplate.id;
         payLoad.template_thumbnail_path = selectedTemplate.image_thumb_path;
         payLoad.message = textEditorValue;
      }

      setLoadingPDF(true);
      await producePDF(payLoad)
         .then(res => {
            setPDFLink(res.data.pdf_path);
            setLoadingPDF(false)
         });
   }

   const sendText = async () => {

      // check validation
      // if(props.messageType !== && ''){
      //     alert("Can not save settings without subject")
      //     document.getElementById("email-setting-subject-input-field").focus();
      //     return false
      // }
      // else if(emailBody === ''){
      //     alert("Can not save settings without mail body text")
      //     // document.getElementById("email-setting-body-input-field").focus();
      //     editorRef.current.focus()
      //     return false
      // }

      // setSubmitSettings(true)
      

      if(props.from !== undefined && props.f0rm!= null && props.from!== 'map-finder'){
         let formInfo = {
            'message': !useThanksIo && props.message_type === LETTER ? letterPages : textEditorValue,
            'message_type': DirectmailTypeConvert[props.messageType],
            'handwriting_style_id': selectedHandWritingId,
            'template_category_id': selectedTemplate != null ? selectedTemplate.agency_card_category_id : null,
            'template_id': selectedTemplate != null ? selectedTemplate.id : null,
            'template_thumbnail_path': selectedTemplate != null ? selectedTemplate.image_thumb_path : null,
            'file_path': selectedTemplate != null ? selectedTemplate.image_path : null,
            'file_type': selectedCardSize,
            'template_pdf_path': pdfLink,
            'gift_production_id': props.messageType === GIFT ? selectedGift.gift_production_id : '',
            'gift_product_id': props.messageType === GIFT ? selectedGift.gift_product_id : '',
            'gift_product_sku': props.messageType === GIFT ? selectedGift.gift_product_sku : '',
            'gift_handling_fee': props.messageType === GIFT ? selectedGift.gift_handling_fee : '',
            'gift_price': props.messageType === GIFT ? selectedGift.gift_price : '',
            'gift_image_url': props.messageType === GIFT ? selectedGift.gift_image_url : '',
         }
         if(props.editableCampaign !== undefined && props.editableCampaign != null){
            props.onSubmit(formInfo, props.editableCampaign.id)
   
         }else{
            props.onSubmit(formInfo, null)
         }
      }
      else{
         await senBroadcastFromMap({
            'message': !useThanksIo && props.message_type === LETTER ? letterPages : textEditorValue,
            'type': props.messageType,
            'handwriting_style_id': selectedHandWritingId,
            'template_category_id': selectedTemplate != null ? selectedTemplate.agency_card_category_id : null,
            'template_id': selectedTemplate != null ? selectedTemplate.id : null,
            'template_thumbnail_path': selectedTemplate != null ? selectedTemplate.image_thumb_path : null,
            'gif_path': selectedTemplate != null ? selectedTemplate.image_path : null,
            'card_size': selectedCardSize,
            'file_path': pdfLink,
            'gift_production_id': props.messageType === GIFT ? selectedGift.gift_production_id : '',
            'product_id': props.messageType === GIFT ? selectedGift.gift_product_id : '',
            'gift_sku_id': props.messageType === GIFT ? selectedGift.gift_product_sku : '',
            'gift_handling_fee': props.messageType === GIFT ? selectedGift.gift_handling_fee : '',
            'gift_price': props.messageType === GIFT ? selectedGift.gift_price : '',
            'gift_image_url': props.messageType === GIFT ? selectedGift.gift_image_url : '',
            'subject': '1'
         })
         .then(response=>{
            if(response.data.status === 'success'){
               window.showNotification('SUCCESS', response.data.html)
               closeModal()
            }else{
               window.showNotification('ERROR', response.data)
            }
         })
      }
      // let formData = new FormData();

      // formData.append('message', !useThanksIo && props.message_type === LETTER ? letterPages : textEditorValue);
      // formData.append('message_type', DirectmailTypeConvert[props.messageType]);

      // formData.append('handwriting_style_id', selectedHandWritingId);
      // formData.append('template_category_id', selectedTemplate != null ? selectedTemplate.agency_card_category_id : null);
      // formData.append('template_id', selectedTemplate != null ? selectedTemplate.id : null);
      // formData.append('template_thumbnail_path', selectedTemplate != null ? selectedTemplate.image_thumb_path : null);
      // formData.append('file_path', selectedTemplate != null ? selectedTemplate.image_path : null);
      // formData.append('file_type', selectedCardSize);
      // formData.append('template_pdf_path', pdfLink);
      // formData.append('gift_production_id', props.messageType === GIFT ? selectedGift.gift_production_id : '');
      // formData.append('gift_product_id', props.messageType === GIFT ? selectedGift.gift_product_id : '');
      // formData.append('gift_product_sku', props.messageType === GIFT ? selectedGift.gift_product_sku : '');
      // formData.append('gift_handling_fee', props.messageType === GIFT ? selectedGift.gift_handling_fee : '');
      // formData.append('gift_price', props.messageType === GIFT ? selectedGift.gift_price : '');
      // formData.append('gift_image_url', props.messageType === GIFT ? selectedGift.gift_image_url : '');
      


   }

   const successFollowupAdd = (response) => {
      // props.fetchCampaignTimelines({
      //    campaignId: campaignDetails.basic.id
      // });
      props.setViewCardModal(false)
   }

   const loader = () => {
      let skeloader = []
      for (let i = 0; i < 3; i++) {
         skeloader.push(

            <Skeleton animation="wave" style={{ height: '70%', width: '25%', marginLeft: 25 }} />

         )
      }

      return (
         <div className="loading-skeleton-in-direct-mail d-flex row justify-content-center" style={{ height: 420 }}>
            {skeloader}
         </div>
      )

   }

   const footerLoader = () => {
      let skeloader = []
      for (let i = 0; i < 2; i++) {
         skeloader.push(

            <Skeleton animation="wave" style={{ height: '100%', width: '25%', marginLeft: 25 }} />

         )
      }
      return (
         <div className="loading-skeleton-in-direct-mail d-flex row justify-content-center" style={{ height: 60 }}>
            {skeloader}
         </div>
      )

   }

   const onSubmit=()=>{

      if(stepNo === 4 && !loadingThirdPartyInfo){
         sendText()
      }else if (stepNo!==4 && createNewPostcardFrom===''){
         moveNextStep(null,stepNo!==2.5 ? stepNo+1 : 3)
      }else if(stepNo!==4 && createNewPostcardFrom!==''){
         window.submitNewTemplate()
      }

   }

   let buttonText;
   let buttonIcon;
   if(stepNo === 4 ){
      buttonText = 'Set Followup Email'
      buttonIcon= <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z" fill="#3C7EF3" /></svg>
   }else if (createNewPostcardFrom!==''){
      buttonText = 'Create Template'
      buttonIcon = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" /></svg>
   }
   else if (stepNo===3 && props.from ==='inbox'){
      buttonText = 'Send'
      buttonIcon = <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z" fill="#3C7EF3" /></svg>
   }
   else {
      buttonIcon = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" /></svg>
      buttonText = 'Save & Continue'
   }

   return (

      <GlobalModal {...props}
         // className={'cardBordcastTab globalBroadcastModal alt'}
		 className={'global-card-broadcast-modal'}
         width={900}
         title={`${props.from === 'addQuickTemplate' ? 'Save' : 'Send'} ${CoreConstants.Timeline.CONTENT_TYPES[DirectmailTypeConvert[props.messageType]]}`}
      // modalBodyClass={'awesome__scroll_bar'}
         buttonText={buttonText}
         buttonIcon={buttonIcon}
         onSubmit={onSubmit}
         hideFooter={!loadingThirdPartyInfo ? false : true}
      >

         <React.Fragment>
            {!submitSettings &&

               <div className="multistep-main-content container-fluid">
                  <div className="row justify-content-center">
                     <div className="col-11 s10 md10 lg6 xl5 text-center p-0">
                        <div className="custom-card-multistep card px-0 pt-4 pb-0 mt-3 mb-3 updated_quick_reply">
                           <form id="msform">
                              {/* <!-- progressbar --> */}
                              <ul id="progressbar">

                                 {(props.messageType === POSTCARD || props.messageType === GIFT || (!useThanksIo && props.messageType === LETTER)) &&
                                    <li className="active" id="account" onClick={() => moveToSpecificStep(1)}>
                                       <strong>
                                          {props.messageType === GIFT ? 'Gift' : 'Size'}

                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <g clip-path="url(#clip0)">
                                                <path d="M15.7013 10.0004C15.7013 9.64199 15.5645 9.2836 15.2913 9.01032L6.6917 0.410771C6.14466 -0.136273 5.25772 -0.136273 4.7109 0.410771C4.16408 0.957593 4.16408 1.84435 4.7109 2.39144L12.3203 10.0004L4.71116 17.6095C4.16434 18.1565 4.16434 19.0432 4.71116 19.59C5.25799 20.1373 6.14492 20.1373 6.69197 19.59L15.2916 10.9905C15.5648 10.7171 15.7013 10.3587 15.7013 10.0004Z" fill="#546376" />
                                             </g>
                                             <defs>
                                                <clipPath id="clip0">
                                                   <rect width="20" height="20" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" />
                                                </clipPath>
                                             </defs>
                                          </svg>
                                       </strong>
                                    </li>
                                 }

                                 {((props.messageType === LETTER && useThanksIo) || (props.messageType === POSTCARD) || (props.messageType === GREETING_CARD) || (props.messageType === GIFT)) &&
                                    <li id="personal" className={(stepNo > 1 ? 'active' : '')} onClick={() => moveToSpecificStep(2)}>
                                       <strong>
                                          Design
                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <g clip-path="url(#clip0)">
                                                <path d="M15.7013 10.0004C15.7013 9.64199 15.5645 9.2836 15.2913 9.01032L6.6917 0.410771C6.14466 -0.136273 5.25772 -0.136273 4.7109 0.410771C4.16408 0.957593 4.16408 1.84435 4.7109 2.39144L12.3203 10.0004L4.71116 17.6095C4.16434 18.1565 4.16434 19.0432 4.71116 19.59C5.25799 20.1373 6.14492 20.1373 6.69197 19.59L15.2916 10.9905C15.5648 10.7171 15.7013 10.3587 15.7013 10.0004Z" fill="#546376" />
                                             </g>
                                             <defs>
                                                <clipPath id="clip0">
                                                   <rect width="20" height="20" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" />
                                                </clipPath>
                                             </defs>
                                          </svg>
                                       </strong>
                                    </li>
                                 }
                                 <li id="payment" className={(stepNo > 2.5 ? 'active' : '')} onClick={() => moveToSpecificStep(3)}>
                                    <strong>
                                       Message
                                       {(props.from === undefined || props.from == null || props.from !== "addQuickTemplate") &&

                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <g clip-path="url(#clip0)">
                                                <path d="M15.7013 10.0004C15.7013 9.64199 15.5645 9.2836 15.2913 9.01032L6.6917 0.410771C6.14466 -0.136273 5.25772 -0.136273 4.7109 0.410771C4.16408 0.957593 4.16408 1.84435 4.7109 2.39144L12.3203 10.0004L4.71116 17.6095C4.16434 18.1565 4.16434 19.0432 4.71116 19.59C5.25799 20.1373 6.14492 20.1373 6.69197 19.59L15.2916 10.9905C15.5648 10.7171 15.7013 10.3587 15.7013 10.0004Z" fill="#546376" />
                                             </g>

                                             <defs>
                                                <clipPath id="clip0">
                                                   <rect width="20" height="20" fill="white" transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)" />
                                                </clipPath>
                                             </defs>
                                          </svg>
                                       }
                                    </strong>
                                 </li>
                                 {(props.from === undefined || props.from == null || props.from !== "addQuickTemplate") &&
                                    <li id="confirm" className={(stepNo > 3 ? 'active' : '')}><strong>Send</strong></li>
                                 }
                              </ul>
                              {/* <!-- fieldsets --> */}
                              {/* <!--====================== 01 multistep form  ======================--> */}
                              {stepNo === 1 && !loadingThirdPartyInfo &&
                                 <FirstStep
                                    setSelectedCardSize={setSelectedCardSize}
                                    selectedCardSize={selectedCardSize}
                                    moveNextStep={moveNextStep}
                                    closeModal={closeModal}
                                    cardSizes={cardSizes}
                                    messageType={props.messageType}
                                    setUseThanksIo={setUseThanksIo}
                                    useThanksIo={useThanksIo}
                                    selectedGift={selectedGift}
                                    setSelectedGift={setSelectedGift}
                                    loadingThirdPartyInfo={loadingThirdPartyInfo}
                                    editableCampaign={props.editableCampaign}
                                 />
                              }
                              {stepNo === 1 && loadingThirdPartyInfo &&

                                 loader()
                              }
                              {stepNo === 1 && loadingThirdPartyInfo &&

                                 footerLoader()
                              }
                              {/* <!--====================== 01 multistep form  ======================-->
                                  <!--====================== 02 multistep form  ======================--> */}
                              {(stepNo === 2 || stepNo === 2.5) && !loadingThirdPartyInfo &&
                                 <SecondStep
                                    closeModal={closeModal}
                                    moveNextStep={moveNextStep}
                                    selectTemplate={selectTemplate}
                                    stepNo={stepNo}
                                    messageType={props.messageType}
                                    setUseThanksIo={setUseThanksIo}
                                    useThanksIo={useThanksIo}
                                    editableCampaign={props.editableCampaign}
                                    from={props.from}
                                    moveToSpecificStep={moveToSpecificStep}
                                    setCreateNewPostcardFrom={setCreateNewPostcardFrom}
                                    createNewPostcardFrom={createNewPostcardFrom}
                                 />
                              }
                              {/* <!--====================== 02 multistep form  ======================-->
                                  <!--====================== 03 multistep form  ======================--> */}
                              {stepNo === 3 && !loadingThirdPartyInfo &&
                                 <ThirdStep
                                    setSelectedHandWritingId={setSelectedHandWritingId}
                                    selectedHandWritingId={selectedHandWritingId}
                                    closeModal={closeModal}
                                    moveNextStep={moveNextStep}
                                    stepNo={stepNo}
                                    changeTextInEditor={changeTextInEditor}
                                    textEditorValue={textEditorValue}
                                    loadingPDF={loadingPDF}
                                    pdfLink={pdfLink}
                                    producePDFLink={producePDFLink}
                                    messageType={props.messageType}
                                    useThanksIo={useThanksIo}
                                    selectedCardSize={selectedCardSize}
                                    setPage={changeLetterPage}
                                    editableCampaign={props.editableCampaign}
                                    selectedTemplate={selectedTemplate}
                                    from={props.from}
                                    moveToSpecificStep={moveToSpecificStep}
                                 />

                              }
                              {/* <!--====================== 03 multistep form  ======================-->
                                  <!--====================== 04 multistep form  ======================--> */}
                              {stepNo === 4 && !loadingThirdPartyInfo &&
                                 (props.from === undefined || props.from == null || props.from !== "addQuickTemplate") &&
                                 !loadingCostInfo &&
                                 <LastSendStep 
                                    totalContacts={totalContacts}
                                    states={states}
                                    cost={perCost}
                                    onClose={props.onClose}
                                    handleSubmit={props.handleSubmit}
                                    from={'direct-mail'}
                                    />

                                 //  <FourthStep 
                                 //  closeModal={closeModal}
                                 //  moveNextStep={moveNextStep}
                                 //  stepNo={stepNo}
                                 //  selectedDate={selectedDate}
                                 //  setSelectedDate={setSelectedDate}
                                 //  selectedTime={selectedTime}
                                 //  setSelectedTime={setSelectedTime}
                                 //  sentImmediately={sentImmediately}
                                 //  setSentImmediately={setSentImmediately}
                                 //  messageType={props.messageType}
                                 //  useThanksIo={useThanksIo}
                                 //  />
                                 // <ScheduleSetting showAllTimeSetting={showAllTimeSetting} editableCampaign={props.editableCampaign} />


                              }

{stepNo === 4 && !loadingThirdPartyInfo &&
                                 (props.from === undefined || props.from == null || props.from !== "addQuickTemplate") &&
                                 loadingCostInfo &&
                                 loader()
                              }
                              {stepNo === 4 && !loadingThirdPartyInfo &&
                                 (props.from === undefined || props.from == null || props.from !== "addQuickTemplate") &&
                                 loadingCostInfo &&
                                 footerLoader()
                              }

                              {/* <!--====================== 04 multistep form  ======================--> */}
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            }
            {/* {stepNo === 4 && !loadingThirdPartyInfo &&
               <div className="massage_send_close_button d-flex justify-content-center align-items-center">
                  <a href="#" onClick={(event) => sendText(event)} className="send_massage">
                     <span>https://meet.google.com/jps-ywtw-jrd
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M7.01285 8.625H6.98715C6.1641 8.625 5.33531 7.98461 4.7302 7.51703C4.63176 7.44102 0.677305 4.54258 0 4.01293V9.9375C0 10.2856 0.138281 10.6194 0.384422 10.8656C0.630564 11.1117 0.964403 11.25 1.3125 11.25H12.6875C13.0356 11.25 13.3694 11.1117 13.6156 10.8656C13.8617 10.6194 14 10.2856 14 9.9375V4.01375C13.3312 4.53793 9.36797 7.44102 9.26953 7.51703C8.66469 7.98461 7.8359 8.625 7.01285 8.625ZM12.6875 0.75H1.3125C0.964403 0.75 0.630564 0.888281 0.384422 1.13442C0.138281 1.38056 0 1.7144 0 2.0625L0 2.58203C0.000133612 2.68159 0.0228408 2.77981 0.0664134 2.86933C0.109986 2.95884 0.17329 3.03731 0.251563 3.09883C1.08828 3.75234 1.36445 3.98477 4.99297 6.61797C5.45234 6.95156 6.36562 7.76094 7 7.75C7.63438 7.76094 8.54766 6.95156 9.00703 6.61797C12.6355 3.98477 12.9117 3.75508 13.7484 3.09883C13.8269 3.03752 13.8904 2.95909 13.934 2.86953C13.9776 2.77997 14.0002 2.68164 14 2.58203V2.0625C14 1.7144 13.8617 1.38056 13.6156 1.13442C13.3694 0.888281 13.0356 0.75 12.6875 0.75Z"
                              fill="#3C7EF3" />
                        </svg>
                     </span> Set Followup Email
                  </a>
                  <a href="#" className="close_btn">
                     <span>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M6.71964 6.71947C6.46898 6.97013 6.24461 7.15099 5.99395 6.90033L4 4.90638L2.00605 6.90033C1.75584 7.15053 1.53102 6.97013 1.28036 6.71947C1.0297 6.46881 0.849296 6.24399 1.0995 5.99378L3.09345 3.99983L1.0995 2.00588C0.849296 1.75567 1.0297 1.53085 1.28036 1.28019C1.53102 1.02953 1.75584 0.849125 2.00605 1.09933L4 3.09328L5.99395 1.09933C6.24461 0.848672 6.46898 1.02953 6.71964 1.28019C6.9703 1.53085 7.15116 1.75522 6.9005 2.00588L4.90655 3.99983L6.9005 5.99378C7.15116 6.24444 6.9703 6.46881 6.71964 6.71947Z"
                              fill="#FF264A" />
                        </svg>
                     </span> Close
                  </a>
               </div>
            } */}
            {/* {(stepNo!==4 && createNewPostcardFrom==='') &&
                              <div className="massage_send_close_button d-flex justify-content-center align-items-center">
                                 <button className="custom-next next action-button accent--bg--color" onClick={(e) =>moveNextStep(e, stepNo!==2.5 ? stepNo+1 : 3)}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                                    </svg>
                                    <span>{stepNo === 2.5 ? 'Next' : 'Save & Continue'}</span>
                                 </button>
                                 <button className="custom-next custom-close modal-close action-button" onClick={(e) => closeModal(e)}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                                    </svg>
                                    <span>Close</span>
                                 </button>
                              </div>
                              }
                              {(stepNo!==4 && createNewPostcardFrom!=='') &&

                              <div className="massage_send_close_button d-flex justify-content-center align-items-center">
            <button className="custom-next next action-button accent--bg--color" onClick={(e) => window.submitNewTemplate(e)}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                </svg>
                <span>Create Template</span>
            </button>
            <button className="custom-next custom-close modal-close action-button" onClick={(e) => changeCreateNewPostcardFrom(e, '')}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                </svg>
                <span>Close</span>
            </button>
            </div>
         } */}
         </React.Fragment>



      </GlobalModal>
   );
   //  }
}
const mapStateToProps = state => {
   return {
      categoryList: state.cardBroadcastReducer.categoryList,

      // customFieldData: state.marketingReducer.customFieldData,
      // userInfo: state.marketingReducer.userInfo,
      // campaignDetails: state.marketingReducer.campaignDetails,

      // tabValue: state.marketingReducer.tabValue,
      // hour: state.marketingReducer.hour,
      // min: state.marketingReducer.min,
      // day: state.marketingReducer.day,
      // recurringDay: state.marketingReducer.recurringDay,
      // laterHour: state.marketingReducer.laterHour,
      // laterMin: state.marketingReducer.laterMin,
      // time: state.marketingReducer.time,
      // startDate: state.marketingReducer.startDate,
      // arrSlotTimeHour1: state.marketingReducer.arrSlotTimeHour1,
      // arrSlotTimeMin1: state.marketingReducer.arrSlotTimeMin1,
      // greetingOption: state.marketingReducer.greetingOption,
      // greetingsOptionTimeMin1: state.marketingReducer.greetingsOptionTimeMin1,
      // greetingsOptionTimeHour1: state.marketingReducer.greetingsOptionTimeHour1,
      // recurringTimeHour1: state.marketingReducer.recurringTimeHour1,
      // recurringTimeMin1: state.marketingReducer.recurringTimeMin1,
   };
};

const mapDispatchToProps = dispatch => {
   return {
      //New Component


      //End New Component
      followupSave: (params, callback) => dispatch(followupSave(params, callback)),
      updateEmailStepSetting: (params, callback) => dispatch(updateEmailStepSetting(params, callback)),
      // fetchCampaignTimelines: (params) => dispatch(fetchCampaignTimelines(params)),

      // for time settings

   };
}
export default connect(mapStateToProps, mapDispatchToProps)(CardMultipleStepForm);
