import React, {useState, useEffect, useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Editor} from "@tinymce/tinymce-react";
import Select from "@material-ui/core/Select";
import {MenuItem} from '@material-ui/core';
import {ReactMultiEmail} from 'react-multi-email';
import {connect} from "react-redux";
import VideoEmail from "./VideoEmail";
import CustomSelectBox from "./CustomSelectBox";
import { getUserEmailList, getUserInfo, sendVideoEmail } from "./redux/videoEmailApi";
import { MESSAGE_TYPE_VIDEO_EMAIL } from "./CoreConstants";
import ModalGlobalModal from "../contactDetails/modals/ModalGlobalModal";
import { setUser } from "./redux/videoEmailAction";
import Utils from "../../helpers/Utils";
import _ApiList from "../contactDetails/SendModules/api/ApiList";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const App = props => {
    const editorRef = useRef(null);
    const classes = useStyles();
    const textInput = useRef(null);

    const [type, setType] = useState(1);
    const [fromEmails, setFromEmails] = useState([]);
    const [selectedFromEmail, setSelectedFromEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [emailVideoId, setEmailVideoId] = useState(null);
    const [cc, setCC] = useState([]);
    const [bcc, setBCC] = useState([]);
    const [displayCC, setDisplayCC] = useState(false);
    const [displayBCC, setDisplayBCC] = useState(false);
    const [sending, setSending] = useState(false)

    const [nextStepMap, setNextStepMap] = useState(false)

    const [totalContacts,setTotalContacts] = useState(0);
    const [smsCost,setSmsCost] = useState(0);

    useEffect(() => {
        if(props.from === 'map') {
            _ApiList.getOrderSummaryForMap({type:'VIDEO'}).then(res => {
                if(res.data.success) {
                    setTotalContacts(res.data.data.recipientsCount);
                    setSmsCost(res.data.data.perCost);
                } else {
                    window.showNotification("ERROR",res.data.message);
                    props.onClose(false);
                }
            });
        }
    },[]);

    useEffect(() => {
        loadUserInfo();
    },[])

    useEffect(() => {

        if (props.user != null) {
            if (props.user.agency.email_provider !== 0 && props.user.email_provider == 1) {
                try {
                    let email = JSON.parse(props.user.nylas_email_provider_info).email;

                    setFromEmails([{
                        value: email,
                        label: email
                    }]);

                    setSelectedFromEmail(email);
                } catch (error) {
                    setFromEmails([])
                }
            } else {
                loadEmail();
            }

            let content = '<br />' + (props.user.show_appointment_url == 1 ? (props.user.appointment_url ? props.user.appointment_url : '') : '');
            content += props.user.show_signature == 1 ? (props.user.signature ? props.user.signature : '') : '';

            setEmailBody(content);
        }
    }, [props.user])

    const loadUserInfo = () => {
        getUserInfo({}).then(response => {
            let result = response.data;
            props.storeUser(result);
        }).catch(error => {
            Utils.handleException(error);
        });
    }

    const loadEmail = () => {
        getUserEmailList({})
            .then(response => {
                let array = [];

                for (let email in response.data.data){
                    array.push({
                        value: email,
                        label: email,
                    })
                }

                if (typeof array[0] !== 'undefined') {
                    setSelectedFromEmail(array[0].value);
                }

                setFromEmails(array)
            });
    }

    const handleSubjectPersonalize = (event) => {
        let start = textInput.current.selectionStart;
        let end = textInput.current.selectionEnd;
        let text = textInput.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event.target.value + after);

        setSubject(newText);

        textInput.current.selectionStart = textInput.current.selectionEnd = start + newText.length;
        textInput.current.focus();
    }

    const handleBodyPersonalize = (event) => {
        setEmailBody(emailBody + event.target.value);
    }

    const handleGoToNext = (event) => {
        event.preventDefault();
        if(subject == ''){
            window.showNotification('ERROR',"Can not send email without subject");
            document.getElementById("email-setting-subject-input-field").focus();
            return false
        } else if(emailBody == ''){
            window.showNotification('ERROR',"Can not send email without mail body text");
            return false
        } else if (emailVideoId == null) {
            window.showNotification('ERROR',"No video selected");
            return false
        }
        if(props.from !== undefined && props.from === 'map'){
            setNextStepMap(true)
        }
    }

    const sendText = () => {
        // event.preventDefault();
        if(sending){
            return
        }

        if(subject == ''){
            window.showNotification('ERROR',"Can not send email without subject");
            document.getElementById("email-setting-subject-input-field").focus();
            return false
        } else if(emailBody == ''){
            window.showNotification('ERROR',"Can not send email without mail body text");
            return false
        } else if (emailVideoId == null) {
            window.showNotification('ERROR',"No video selected");
            return false
        }

        setSending(true)
        let formData = new FormData();

        formData.append('subject', subject);
        formData.append('messages', emailBody);
        formData.append('message_type', MESSAGE_TYPE_VIDEO_EMAIL);
        formData.append('email', selectedFromEmail);
        formData.append('checked', false);
        formData.append('schedule_type', 1);
        formData.append('time', null);
        formData.append('type', type);
        formData.append('date', null);
        formData.append('origin', 3);
        formData.append('email_video_id', emailVideoId);

        _ApiList.submitSendFromMap(formData, true).then(response => {
            if (response.data.status == 'error') {
                window.showNotification("ERROR",response.data.html);
            } else {
                window.showNotification("SUCCESS","Mail Sent successfully")
                props.onClose(false);
            }
        })
    }

    const useMessageTemplate = data => {
        console.log(data);
        setSubject(data.subject);
        setEmailBody(data.message);
        setEmailVideoId(data.campaign_video_file_for_email_id);
    }

    const renderFromEmails = () => {
        let fromEmailOptions = [];

        fromEmails.map((data, index) => {
            fromEmailOptions.push(
                <MenuItem value={data.value}>{data.label}</MenuItem>
            );
        });

        return fromEmailOptions;
    }

    const renderNextStepMap = () => {
        if(props.from == 'map'){
            return(
                <div>
                    <h5>Order Summary</h5>
                    <p>Below is a summary of your order. Click Send to confirm.</p>
                    <p>Sending to {totalContacts} address(es)</p>

                    <table>
                        <tr><th>Item</th><th>Sending</th></tr>
                        <tr><td>Video</td><td>Immediately</td></tr>
                    </table>

                    <h6>Cost Summary</h6>
                    <table>
                        <tr><th>Per Send Cost</th><th>Number of recipients</th><th>Estimated Total Cost</th></tr>
                        <tr><td>{smsCost}</td><td>{totalContacts}</td><td>{smsCost*totalContacts}</td></tr>
                    </table>

                    <div className="d-flex align-items-center justify-content-center py-3">
                        <button className="sendModulesButton cancelButton" onClick={() => props.onClose(false)}>Cancel</button>
                        <button className="sendModulesButton submitButton" onClick={sendText}>{sending ? 'Sending' : 'Confirm & Send'} </button>    
                    </div>		
                </div>
            )
        }
        return null;
    }

    const renderMain = () => {
        return(
            <>
            <div className="row mt-3 mb-0  v3__video_email_field_wrapper d-none">
                <div className="add__cc_bcc_btns">
                    {
                        !displayCC && (
                            <a href="#"
                               className="bg-light-blue radius-5 py-2 px-3 d-inline-block white-text mr-2"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setDisplayCC(true);
                               }}
                            >
                                Add CC
                            </a>
                        )
                    }
                    {
                        !displayBCC && (
                            <a href="#"
                               className="bg-light-blue-10 radius-5 py-2 px-3 d-inline-block text-light-blue"
                               onClick={(e) => {
                                   e.preventDefault();
                                   setDisplayBCC(true);
                               }}
                            >
                                Add BCC
                            </a>
                        )
                    }
                </div>
            </div>
            {displayCC &&
                <div id="cc_select_field" className="row mb-0 borderStyleWrapper">
                            <div className="row ">
                                <div className="col s2">
                                    <h5 className="text-dark-blue text-left">CC:</h5>
                                </div>
                                <div className="col s10">
                                    <ReactMultiEmail
                                        className="form-control borderStyle1px"
                                        emails={cc}
                                        onChange={(_emails) => {
                                            setCC(_emails);
                                        }}
                                        getLabel={(
                                            cc,
                                            index,
                                            removeEmail,
                                        ) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {cc}
                                                    <span data-tag-handle
                                                          onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        <div className="col m4 s1 text-left">
                            <a href="#">
                                <i id="close__cc"
                                   className="material-icons white-text circle bg-red mt-2"
                                   onClick={(e) => {
                                       setCC([]);
                                       setDisplayCC(false);
                                   }}>
                                    close
                                </i>
                            </a>
                        </div>
                    </div>
            }
            {displayBCC &&
                <div id="cc_select_field" className="row mb-0 borderStyleWrapper ">
                            <div className="row">
                                <div className="col s2">
                                    <h5 className="text-dark-blue text-left">BCC:</h5>
                                </div>
                                <div className="col s10">
                                    <ReactMultiEmail
                                        className="form-control borderStyle1px"
                                        emails={bcc}
                                        onChange={(_emails) => {
                                            setBCC(_emails);
                                        }}
                                        getLabel={(
                                            bcc,
                                            index,
                                            removeEmail,
                                        ) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {bcc}
                                                    <span data-tag-handle
                                                          onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        <div className="col m4 s1 text-left">
                            <a href="#">
                                <i id="close__cc"
                                   className="material-icons white-text circle bg-red mt-2"
                                   onClick={(e) => {
                                       setBCC([]);
                                       setDisplayBCC(false);
                                   }}>
                                    close
                                </i>
                            </a>
                        </div>
            </div>
            }
            <div className="mb-0 v3__video_email_field_wrapper"> 
                <div className="d-flex align-items-start ds__row mb-2">
                    <p className="vn-label mr-3 customLabelWidth">From:</p>
                    <div className="customWidth fromEmail">
                        <Select
                            className="borderStyle1px"
                            labelId="personalize-select-label"
                            id="personalize-select"
                            value={selectedFromEmail}
                            onChange={(e) => setSelectedFromEmail(e.target.value)}
                            displayEmpty
                        >
                            {renderFromEmails()}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="row mb-0 v3__video_email_alt v3__video_email_field_wrapper alt">
                <div className="d-flex align-items-start ds__row mb-2">
                    <p className="vn-label mr-3 customLabelWidth">Subject:</p>
                    <div className="customWidth fromEmail alt">
                        <div className="input-field subject_input selects_fields">
                            <input className="borderStyle1px" type="text" placeholder="Enter your subject" id="email-setting-subject-input-field" value={subject} onChange={(e) => setSubject(e.target.value)} ref={textInput}/>
                        </div>
                        <div className="number-input-field personiliz-input-field borderStyle1px alt">
                            <CustomSelectBox handlePersonalize={handleSubjectPersonalize}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal__scroll v3_modal__scroll">
                <VideoEmail from="inbox" emailVideoId={emailVideoId} setEmailVideoId={setEmailVideoId} useMessageTemplate={useMessageTemplate}/>

                {/*Massaging Editor*/}
                <div className="tiny-mce mt-3">
                    <div className="mb-0 row">
                        <div className="s12">
                            <Editor
                                apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={emailBody}
                                cloudChannel='5-dev'
                                textareaName='email_reply'
                                init={{
                                    height: 200,
                                    menu: {
                                        favs: {
                                            title: 'My Favorites',
                                            items: 'code visualaid | searchreplace | emoticons'
                                        }
                                    },
                                    menubar: false,
                                    convert_urls: false,
                                    toolbar:
                                        'insertfile undo redo | styleselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | link | \
                                        bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                    file_picker_types: 'file image media',
                                    forced_root_block: false
                                }}
                                onEditorChange={setEmailBody}
                            />
                            {/*<textarea id="basic-example" className="materialize-textarea" placeholder="Type a message"  onChange={(event) => setConversationText(event.target.value)} ref={textInput} value={conversationText}/>*/}
                        </div>
                    </div>
                </div>
                {/*Massage Editor Bottom Button*/}
                <div className="send-button-area v3_video_email_send_button">
                    <div className="mb-0 row">
                        <div className="col s6">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="customVideoEmailPersonalizeWrap alter input-field number-input-field personiliz-input-field v3_custom_select_wrapper">
                                    <CustomSelectBox handlePersonalize={handleBodyPersonalize}/>
                                </div>
                                {/* <div className="pl-1">
                                    <a href="#" className="quick-reply">
                                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"></path>
                                        </svg>
                                        Use Quick Reply</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col s6 d-none">
                            <div className="pr-0 send-message-btn right p-relative">
                                <div className="file-field input-field d-flex">
                                    <div className="file-path-wrapper d-flex align-items-center">
                                                            <span>
                                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M12.5 0C5.6075 0 0 5.60758 0 12.5001C0 19.3926 5.6075 25 12.5 25C19.3925 25 25 19.3926 25 12.5001C25 5.60758 19.3925 0 12.5 0ZM12.5 22.7273C6.86061 22.7273 2.27273 18.1394 2.27273 12.5001C2.27273 6.86076 6.86061 2.27273 12.5 2.27273C18.1394 2.27273 22.7273 6.86076 22.7273 12.5001C22.7273 18.1394 18.1393 22.7273 12.5 22.7273Z"
                                                                        fill="#FF6B6B"/>
                                                                    <path
                                                                        d="M12.4992 5.30273C11.6639 5.30273 10.9844 5.98273 10.9844 6.81857C10.9844 7.65364 11.6639 8.33304 12.4992 8.33304C13.3345 8.33304 14.0141 7.65364 14.0141 6.81857C14.0141 5.98273 13.3345 5.30273 12.4992 5.30273Z"
                                                                        fill="#FF6B6B"/>
                                                                    <path
                                                                        d="M12.4996 10.6045C11.8721 10.6045 11.3633 11.1133 11.3633 11.7409V18.559C11.3633 19.1866 11.8721 19.6954 12.4996 19.6954C13.1272 19.6954 13.636 19.1866 13.636 18.559V11.7409C13.636 11.1133 13.1272 10.6045 12.4996 10.6045Z"
                                                                        fill="#FF6B6B"/>
                                                                </svg>
                                                            </span>
                                        <input className="file-path validate" type="text"
                                               placeholder=" Max size 15MB, Max 5 files."/>
                                    </div>
                                    <div className="upload_file_button d-flex">
                                                            <span className="upload_file_button_icon">
                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M13.288 5.10351L6.16506 12.1474C4.98482 13.3147 3.07111 13.3147 1.89087 12.1474C0.710641 10.9802 0.710641 9.08801 1.89087 7.92077L8.30191 1.58131C9.0889 0.803315 10.3642 0.803315 11.1512 1.58131C11.9382 2.35929 11.9382 3.6211 11.1512 4.39908L4.74016 10.7386C4.34692 11.1278 3.70901 11.1278 3.31527 10.7386C2.92202 10.3498 2.92202 9.71894 3.31527 9.32967L9.01386 3.69463L8.3014 2.9902L2.60333 8.62572C1.81634 9.40372 1.81634 10.6655 2.60333 11.4435C3.39032 12.2215 4.66563 12.2215 5.45262 11.4435L11.8637 5.10401C13.0439 3.93679 13.0439 2.04461 11.8637 0.877366C10.6834 -0.289851 8.76973 -0.289851 7.58949 0.877366L0.822208 7.56904L0.846732 7.59355C-0.382548 9.15652 -0.274481 11.416 1.17844 12.8523C2.63136 14.2887 4.91528 14.3963 6.49626 13.18L6.52078 13.2046L14 5.80844L13.288 5.10351Z"
                                                                        fill="#3C7EF3"/>
                                                                </svg>
                                                            </span>
                                        <span>Attach File</span>
                                        <input type="file"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <div className="" onClick={sendText}>Next</div> */}

            <div class="massage_send_close_button d-flex justify-content-end align-items-center">
                <a href="#!" class="send_massage" onClick={(props.from !== undefined && props.from === 'map') ? handleGoToNext : sendText}>
                    <span>
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M12 8V4l8 8-8 8v-4H4V8z"></path>
                        </svg>
                    </span> 
                    {(props.from !== undefined && props.from === 'map') ? 'Next' : 'Send Message'}
                </a>
            </div>
            </>
        )
    }

    return (
        <ModalGlobalModal 
            modalClass="videoEmailModalWrapperV3 full-height-video-mail-modal" 
            open={props.open} modalScroll={true} 
            onClose={() => props.onClose(false)} 
            title="Video Email" 
            buttonText="Send Message"  
            buttonIcon={<i className="material-icons left accent--color">near_me</i>} 
            handleSubmit={sendText}
            modalClass="fixed-height-100-80 global__send_modal_wrapper"
            hideFooter={true}
        >
            {nextStepMap ? 
                renderNextStepMap():
                renderMain()
            }
            
        </ModalGlobalModal>
    );
}

const mapStateToProps = state => {
    return {
        customFieldData: state.inboxReducer.customFieldData,
        user: state.inboxReducer.user,
        selectedContact: state.inboxReducer.selectedContact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        storeUser: (params,callBack) => dispatch(setUser(params,callBack)),
    };
}


const VideoEmailModal = connect(mapStateToProps, mapDispatchToProps)(App);

export default VideoEmailModal;