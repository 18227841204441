import React from "react";
import {
    makeStyles
} from "@material-ui/core/styles";
import {
    Modal,
    Typography,
    Fade,
    Backdrop, IconButton
} from "@material-ui/core";
import {
    HighlightOff as HighlightOffIcon
} from "@material-ui/icons";
import { AlertIcon } from "./Icons";
import Utils from "../../../helpers/Utils";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: "20px",
        textAlign: "center",
        maxWidth: "515px",
        borderRadius: '6px',
        outline: 0,
        position: "relative",
    },
    pinpoint_logo: {
        border: "1px solid #333",
        borderRadius: '4px',
        padding: "10px",
        marginTop: "-60px",
        maxWidth: "120px",
        backgroundColor: "#fff"
    },
    pinpoint_title: {
        fontSize: "21px",
        lineHeight: "42px",
        marginBottom: "15px",
        fontWeight: "400",
        marginTop: "10px",
        color: "#6f7681"
    },
    pinpoint_subtitle: {
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "600",
        marginTop: "30px"
    },
    pinpoint_buttons: {
        marginTop: "20px",
        "& button": {
            margin: "10px",
            padding: "5px 15px",
            textTransform: "capitalize",
            borderRadius: "4px",
            height: "auto",
            fontWeight: "500",
            boxShadow: "unset",
            "&:hover": {
                boxShadow: "unset",
            }
        }
    },
    pinpoint_close_button: {
        position: "absolute",
        top: "-10px",
        right: "-10px",
        background: "#fff",
        padding: "0",
        border: "1px solid #ddd",
        "&:hover, &:focus": {
            background: "#fff",
            color: "#f00",
        }
    },
    contact_team: {
        color: "#e81313"
    }
}));

const PauseAlert = (props) => {
    const classes = useStyles();

    return (
        <Modal
            open={true}
            onClose={() => props.onClose()}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className={classes.modal}
            BackdropProps={{
                timeout: 1000,
            }}
            onBackdropClick={() => props.onClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
            <Fade in={true}>
                <div className={classes.paper}>
                    <IconButton className={classes.pinpoint_close_button}>
                        <HighlightOffIcon fontSize="large" onClick={props.onClose} />
                    </IconButton>
                    <AlertIcon width={76} height={70} />
                    <Typography className={classes.pinpoint_subtitle}>
                        Your geofarming access has been disabled. Please <span className={classes.contact_team}>contact our team</span> to regain access.
                    </Typography>
                    <Typography variant="h3" className={classes.pinpoint_title}>Contact Support</Typography>
                </div>
            </Fade>
        </Modal>
    );
};

export default PauseAlert;