import React from "react";
import * as ACTION from "../constants/mapActionTypes";

export const fetchAllContactsPositionArray = (payload, callback) => ({
    type: ACTION.GET_ALL_CONTACTS_POSITION_ARRAY,
    payload: {payload, callback}
});
export const fetchAllTagList = (payload, callback) => ({
    type: ACTION.GET_TAG_LIST,
    payload: {payload, callback}
});

export const fetchAlUserCampaignList = (payload, callback) => ({
    type: ACTION.GET_CAMPAIGN_LIST,
    payload: {payload, callback}
});

export const storeCampaignForMapSidebar = (payload) => ({
    type: ACTION.SET_CAMPAIGN_FOR_MAPSIDEBAR,
    payload: payload
});

export const storeDispositionForMapSidebar = (payload) => ({
    type: ACTION.SET_DISPOSITION_FOR_MAPSIDEBAR,
    payload: payload
});

export const getSendMessageCredit = (payload, callback) => ({
    type: ACTION.GET_SEND_MESSAGE_CREDIT,
    payload: {payload, callback}
});

export const fetchUserDispositionList = (payload, callback) => ({
    type: ACTION.GET_DISPOSITION_LIST,
    payload: {payload, callback}
});

export const setCarrierLookupPrice = (payload) => ({
    type: ACTION.SET_CARRIER_LOOKUP_PRICE,
    payload: payload
});