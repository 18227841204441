import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditorTinyMCE=(props)=> {


  const editorRef = useRef(null);

  const handleUpdate=(value, editor)=>{

    
    if(props.textLimit != undefined){
        let length = editor.getContent({ format: 'text' }).length;
        if(length <= props.textLimit){
            props.changetextInEditor(value)
        }
    }else{
        props.changetextInEditor(value)
    }

  }
  return (
    <>
      <Editor
        apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={props.changeTextInEditor}
        value={props.textEditorValue}
        init={{
          height: 500,
          menubar: false,
          placeholder: 'Enter a message here!',
          force_br_newlines : true,
          force_p_newlines : false,
          forced_root_block : '',
          toolbar: false,
          convert_urls: false,
          // plugins: [
          //   'advlist autolink lists link image charmap print preview anchor',
          //   'searchreplace visualblocks code fullscreen',
          //   'insertdatetime media table paste code help wordcount'
          // ],
          // toolbar: 'undo redo | formatselect | ' +
          // 'bold italic backcolor | alignleft aligncenter ' +
          // 'alignright alignjustify | bullist numlist outdent indent | ' +
          // 'removeformat | help',
          // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </>
  );
}

export default TextEditorTinyMCE;