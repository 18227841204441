import React, { useState } from "react";
import GlobalDropdown, {
  GlobalDropDownButton,
  GlobalDropDownItem,
} from "../../../../globals/Dropdown/GlobalDropdown";
import { BasicButton } from "../../ContactDetailsStyle";
import { Box, Button, Dialog, DialogContent, Typography, makeStyles } from "@material-ui/core";
import {IconList} from "../../../../../constants/IconList";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ModalAssignToList from "../../../enhanceDataAction/ModalAssignToList";
import ModalAssignToTagNew from "../../../enhanceDataAction/ModalAssignToTag";
import ModalListForStageAdd from "../../../enhanceDataAction/ModalListForStageAdd";
import ModalAssignToCampaign from "../../../enhanceDataAction/ModalAssignToCampaign";
import ModalAssignToPowerDialer from "../../../enhanceDataAction/ModalAssignToPowerDialer";
import { cleanContactsActionsApi } from "../../../../../api/mapApi";


const useStyles=makeStyles({
  disableButton:{
   color:'black'
  },
  saveButton:{
    background: "#006df5",
    color: "white",
    "&:hover":{
      background: "#006df5",
    }
  }
})

const SkipTraceActionButton = ({markerContactDetails,handleCloseModal,state,addSuccess,cleanContactId}) => {
  const [openAddToListModal, setOpenAddToListModal] = useState(false);
  const [openAddToTagModal, setOpenAddToTagModal] = useState(false);
  const [openAddToStageModal, setOpenAddToStageModal] = useState(false);
  const [openAddToCampaignModal, setOpenAddToCampaignModal] = useState(false);
  const [openAddToPowerDialerModal, setOpenAddToPowerDialerModal] =useState(false);
  const [openAddToConnectCRMModal, setOpenAddToConnectCRMModal] =
    React.useState(false);
  const [disableSaveButton, setDisableSetButton] = useState(false);
  const handleAddToConnectModalOpen = () => {
    setOpenAddToConnectCRMModal(true);
  };
  const handleClose = () => {
    setOpenAddToConnectCRMModal(false);
};
const handleAddContact = async () => {
  setDisableSetButton(true);
  const payload = {
    action: "ADD_TO_CRM",
    cleanContactIds: [cleanContactId],
    filterType: "SEGMENT",
  };
  try {
    await cleanContactsActionsApi(payload)
      .then((res) => {
        if (res && res.success) {
          window.showNotification("success","Contact Added Successfully");
          handleClose()
          handleCloseModal()
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          );
        }
      })
     
  } catch (e) {}
};
const classes=useStyles()
  return (
    <div style={{display:'flex',justifyContent:'end',marginTop:'16px'}}>
      <GlobalDropdown className="add__action_dropdown" placement="top">
        <GlobalDropDownButton>
          <BasicButton
            variant="contained"
            endIcon={<ExpandMoreIcon/>}
            color="default"
            disableElevation
            style={{ marginLeft: "10px", background:'#3f51b5',"&.focus":{
                background:'#3f51b5'
            } }}
          >
            <Typography style={{ fontSize: ".7vw", color: "white" }}>
              Actions
            </Typography>
          </BasicButton>
        </GlobalDropDownButton>
        <GlobalDropDownItem
          onClick={handleAddToConnectModalOpen}
          title="Add Contact To CRM"
          onClickHide={true}
          icon={IconList.addToContactSVG}
        />
        <GlobalDropDownItem
          onClick={() => {
            setOpenAddToListModal(true);
          }}
          title="Add To List"
          onClickHide={true}
          icon={IconList.addToListSVG}
        />
        <GlobalDropDownItem
          onClick={() => {
            setOpenAddToCampaignModal(true);
          }}
          title="Add To Campaign"
          onClickHide={true}
          icon={IconList.addToContactSVG}
        />
        <GlobalDropDownItem
          onClick={() => {
            setOpenAddToTagModal(true);
          }}
          title="Assign Tag"
          onClickHide={true}
          icon={IconList.addTagSVG}
        />
        <GlobalDropDownItem
          onClick={() => {
            setOpenAddToStageModal(true);
          }}
          title="Add to Pipeline Stage"
          onClickHide={true}
          icon={IconList.addToPipelineSVG}
        />
        <GlobalDropDownItem
          onClick={() => {
            setOpenAddToPowerDialerModal(true);
          }}
          title="Add To Power Dialer List"
          onClickHide={true}
          icon={IconList.addToContactSVG}
        />
      </GlobalDropdown>
      {openAddToListModal && (
          <ModalAssignToList
            open={openAddToListModal}
            onClose={() => setOpenAddToListModal(false)}
            markerContactDetails={markerContactDetails}
            handleCloseModal={handleCloseModal}
          />
        )}
        {openAddToTagModal && (
          <ModalAssignToTagNew
            open={openAddToTagModal}
            onClose={() => setOpenAddToTagModal(false)}
            markerContactDetails={markerContactDetails}
            handleCloseModal={handleCloseModal}
          />
        )}

        {openAddToStageModal && (
          <ModalListForStageAdd
            open={openAddToStageModal}
            onClose={() => setOpenAddToStageModal(false)}
            markerContactDetails={markerContactDetails}
            handleCloseModal={handleCloseModal}
          />
        )}

        {openAddToCampaignModal && (
          <ModalAssignToCampaign
            state={state}
            markerContactDetails={markerContactDetails}
            open={openAddToCampaignModal}
            onClose={() => setOpenAddToCampaignModal(false)}
            handleCloseModal={handleCloseModal}
          />
        )}

        {openAddToPowerDialerModal && (
          <ModalAssignToPowerDialer
            open={openAddToPowerDialerModal}
            markerContactDetails={markerContactDetails}
            onClose={() => setOpenAddToPowerDialerModal(false)}
            handleCloseModal={handleCloseModal}
          />
        )}
         <Dialog
          open={openAddToConnectCRMModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ width: "400px" }}>
            <Typography variant="h5" align="center" style={{fontWeight:'600'}}>
              Do you want to connect the contact to CRM ?
            </Typography>
          </DialogContent>
          <Box display={"flex"} justifyContent={"center"} py={2} style={{gap:'16px'}}>
            {/* <Button onClick={handleClose} variant="contain" color="primary" disableElevation >
            Agree
          </Button> */}
            <Button
              onClick={handleAddContact}
              variant="contained"
              className={classes.saveButton}
            >
              {disableSaveButton?'Saving...':'Yes'}
            </Button>

            <Button
              onClick={handleClose}
              style={{ background: "#d53d3d", color: "white" }}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </Dialog>
    </div>
  );
};

export default SkipTraceActionButton;
