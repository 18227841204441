const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const contactRootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const contactDetailsApiList = {
    getContactDetails: `${contactRootUrl}/details`,
    customFields: `${contactRootUrl}/get-custom-fields`,
    getAllTags: `${rootUrl}/get/tag/list`,
    getContactTagList: `${contactRootUrl}/get-contact-tag-list`,
    getAllCampaignByContact: `${rootUrl}/campaign/list/contact`,
    getEligibleCampaignForContact: `${rootUrl}/campaign/not-in-current-contact/list`,
    moveCampaignContact: `${rootUrl}/campaign/contact/move`,
    campaignSingleContactRun: `${rootUrl}/campaign/single-contact/run`,
    campaignSingleContactPause: `${rootUrl}/campaign/single-contact/pause`,
    campaignSingleContactUnsubscribed: `${rootUrl}/campaign/contacts/unsubscribe`,

    leadSource: `${rootUrl}/lead-sources`,
    changeContactSourceType: `${rootUrl}/contact-source-type/change/source`,

    getPeopleActivity: `${rootUrl}/get/people/activity`,
    markAsDone: `${rootUrl}/contact-activity-mark-as-done`,
    submitUserActivity: `${rootUrl}/user-activity-submit-task`,
    
    contactDeleteFromStage: `${rootUrl}/contact/delete/from/stage`,

    getConversation: `${rootUrl}/get/people/conversation`,
    addNote: `${rootUrl}/note-add-submit`,
    updateNote: `${rootUrl}/update-timeline-note`,

    getVirtualNumber: `${rootUrl}/get-virtual-number-by-user`,
    sendConversation: `${rootUrl}/campaign/contacts/conversation`,

    updateContactField: `${rootUrl}/contact/update/field`,
    contactSingleCustomFieldUpdate: `${rootUrl}/contact/single/custom-field/update`,
    addContactTags: `${rootUrl}/contact/add-tags`,
    removeContactTag: `${rootUrl}/contact/remove-tags`,

    sendBulkEmail: `${rootUrl}/send/bulk-email`,
    getProfileData: `${rootUrl}/single/profile`,
    getOtherEmailByContact: `${rootUrl}/get-other-email-by-contact`,

    subUserListForContactDropdown: `${contactRootUrl}/sub-users`,
    getSearchContacts: `${contactRootUrl}/search-contacts`,
    saveActivity:`${contactRootUrl}/save-activity`,


}
export default contactDetailsApiList;