

const Helper={

    getIconTextFromContact:(contact)=>{
        
        let firstName = (contact.first_name != undefined && contact.first_name != null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name != undefined && contact.last_name != null) ? contact.last_name.trim() : ''
        
        if(firstName != '' && lastName != ''){
            return (firstName.substring(0,1)+lastName.substring(0,1)).toUpperCase()
        }else if(firstName != ''){
            return firstName.substring(0,2).toUpperCase()
        }else if(lastName != ''){
            return lastName.substring(0,2).toUpperCase()
        }else {
            return 'N/A'
        }
    },

    isEmpty:(text)=>{
        if(text === undefined || text== null || text=== ''){
            return true
        }else{
            return false
        }
    },

    incomeConvert:(value)=>{

		switch (value) {

			case 'A':
				return 'Under $10K';

			case 'B':
				return 	'$10-19,999';

			case 'C':
				return 	'$20-29,999';
	
			case 'D':
				return 	 '$30-39,999' ;
		
			case 'E':
				return 	 '$40-49,999';
			case 'F':
				return 	 '$50-59,999';
			case 'G':
				return 	 '$60-69,999';
			case 'H':
				return 	 '$70-79,999';
			case 'I':
				return 	'$80-89,999' ;
			case 'J':
				return 	  '$90-99,999';
			case 'K':
				return 	 '$100-149,999';
			case 'L':
				return 	 '$150-174,999';
			case 'M':
				return 	 '$175-199,999';
			case 'N':
				return 	 '$200-249,999';
			case 'O':
				return 	 '$250-274,999' ;
			case 'P':
				return 	 '$275-299,999' ;
			case 'Q':
				return 	 '$300-349,999' ;
			case 'R':
				return 	 '$350-374,999' ;
			case 'S':
				return 	 '$375-399,999' ;
			case 'T':
				return 	 '$400-449,999' ;
			case 'U':
				return 	 '$450-474,999' ;
			case 'V':
				return 	 '$475-499,999' ;
			case 'W':
				return 	 '$500-549,999' ;
			case 'X':
				return 	 '$575-599,999' ;
			case 'Y':
				return 	 '$600-649,999' ;
			case 'Z':
				return 	 '$650+' ;
			default :
				return value;
		}
	},

    homeOwnerFlag:(value)=>{

		switch (value) {

			case 'Y':
				return 'Yes';

			case 'N':
				return 	'No';
			default :
				return 'No';
		}
	},

	propertyPool:(value)=>{

		switch (value) {
			case 'True':
				return 'Yes';
			case 'Y':
				return 'Yes';
			case 'N':
				return 	'No';
			default :
				return 'No';}
		},
	maritalStatus:(value)=>{

		switch (value) {
			case 'M':
				return 'Married';
			default :
				return 'Single';
		}
	},

	dwellingType: (value) => {
		switch (value){
			case 'S':
				return 'Single Family Estimated';
			case 'A':
				return 'Apartment';
			default:
				return '';
		}
	}
}

export default Helper;