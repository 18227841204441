const icons = {
  fullScreenIcon: <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 .75V0H.375A.375.375 0 0 0 0 .375V3h.75V1.279l3.484 3.484.529-.53L1.279.75H3zM11.625 0H9v.75h1.721L7.237 4.234l.529.529 3.484-3.484V3H12V.375A.375.375 0 0 0 11.625 0zM11.25 10.721 7.766 7.237l-.529.53 3.484 3.483H9V12h2.625a.375.375 0 0 0 .375-.375V9h-.75v1.721zM4.234 7.234.75 10.72V9H0v2.625c0 .207.168.375.375.375H3v-.75H1.279l3.484-3.484-.53-.532z" fill="#546376"/></svg>,
  editIcon: <svg width="15" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)" fill="#3C7EF3"><path d="M14.703 1.9 13.433.629a1.018 1.018 0 0 0-1.438 0l-.922.922a.255.255 0 0 0 0 .359l2.349 2.349a.255.255 0 0 0 .36 0l.921-.922a1.018 1.018 0 0 0 0-1.438zm-3.57 6.182L10.09 9.123a.32.32 0 0 0-.091.222v2.65H1.667V3.664H7.65a.32.32 0 0 0 .221-.092L8.914 2.53a.313.313 0 0 0-.221-.534H1.25A1.25 1.25 0 0 0 0 3.246v9.166a1.25 1.25 0 0 0 1.25 1.25h9.167a1.25 1.25 0 0 0 1.25-1.25V8.303a.312.312 0 0 0-.534-.221z"/><path d="M12.833 4.845a.254.254 0 0 1 0 .36l-5.687 5.687-2.417.268a.505.505 0 0 1-.56-.56l.268-2.416 5.688-5.688a.255.255 0 0 1 .36 0l2.348 2.349z"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(0 .332)" d="M0 0h15v13.333H0z"/></clipPath></defs></svg>,
  SmsIcon: <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#546376"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12zM7 9h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z"/></svg>,
  blackEnvIcon: <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M6.679 9.168h-.025c-.783 0-1.573-.61-2.149-1.055C4.41 8.04.645 5.28 0 4.776v5.642a1.25 1.25 0 0 0 1.25 1.25h10.833a1.25 1.25 0 0 0 1.25-1.25V4.776c-.637.5-4.411 3.264-4.505 3.337-.576.445-1.365 1.055-2.15 1.055zm5.404-7.5H1.25A1.25 1.25 0 0 0 0 2.918v.495a.627.627 0 0 0 .24.492c.796.622 1.06.844 4.515 3.352.438.317 1.308 1.088 1.912 1.078.604.01 1.474-.76 1.911-1.078 3.456-2.508 3.719-2.727 4.516-3.352a.623.623 0 0 0 .24-.492v-.495a1.25 1.25 0 0 0-1.25-1.25z" fill="#546376"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h13.333v13.333H0z"/></clipPath></defs></svg>,
  blackCallIcon: <svg width="20" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.371 2.83a.394.394 0 0 0-.286.079.322.322 0 0 0-.126.252l-.003.23a.335.335 0 0 0 .09.231c.06.065.142.108.231.12a3.092 3.092 0 0 1 1.709.796c.463.432.763.992.853 1.593.013.084.058.16.128.216a.39.39 0 0 0 .248.084l.247-.003a.387.387 0 0 0 .149-.032.36.36 0 0 0 .121-.085.33.33 0 0 0 .085-.268 3.652 3.652 0 0 0-1.142-2.149A4.125 4.125 0 0 0 7.371 2.83zM7.377.99a.39.39 0 0 0-.278.084.32.32 0 0 0-.12.248l-.003.23a.336.336 0 0 0 .096.238c.063.065.15.106.242.115a5.164 5.164 0 0 1 3.047 1.349c.82.766 1.332 1.772 1.445 2.842.01.087.054.168.124.226a.39.39 0 0 0 .255.09l.246-.004a.386.386 0 0 0 .266-.111.33.33 0 0 0 .09-.26c-.132-1.29-.747-2.504-1.737-3.427A6.198 6.198 0 0 0 7.376.991zM6.94 5.013l-.003.237c-.001.075.025.15.073.21a.38.38 0 0 0 .192.13c.154.044.293.124.408.23.114.107.199.237.247.38a.356.356 0 0 0 .138.18.395.395 0 0 0 .226.069l.254-.004c.056 0 .11-.013.16-.036a.355.355 0 0 0 .125-.098.327.327 0 0 0 .06-.293 1.849 1.849 0 0 0-.52-.842 2.046 2.046 0 0 0-.902-.486.396.396 0 0 0-.315.057.342.342 0 0 0-.105.118.322.322 0 0 0-.038.149zM5.798 9.723c.82 1.602 2.209 2.897 3.925 3.663l1.077-1.233a.52.52 0 0 1 .28-.164.563.563 0 0 1 .334.029l2.467 1.003a.527.527 0 0 1 .265.231.465.465 0 0 1 .046.333l-.505 2.127a.478.478 0 0 1-.183.272.542.542 0 0 1-.327.105C7.547 16.042 2.95 11.746 2.9 6.501a.464.464 0 0 1 .112-.305.521.521 0 0 1 .292-.17l2.278-.473a.564.564 0 0 1 .358.043.52.52 0 0 1 .248.248l1.075 2.302a.463.463 0 0 1-.145.572l-1.32 1.005z" fill="#546376"/></svg>,
  mapMarkerIcon: <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 1.61c-2.21 0-4 1.783-4 3.991 0 3.82 4 8.8 4 8.8s4-4.981 4-8.8A3.994 3.994 0 0 0 8 1.61zm0 6.2a2.16 2.16 0 1 1 0-4.32 2.16 2.16 0 0 1 0 4.32z" fill="#546376"/></svg>,
  crossIconModal: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z" fill="white"/>
                        <path d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z" fill="white"/>
                    </svg>,
  caretIcon: <svg width="12" height="5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 0H0l6 5 6-5z" fill="#133159"/></svg>,
  PlaceholderCircle: <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="25" fill="#E5E8EF"/></svg>,
  whiteCirclePlusIcon: <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="#fff"/><path d="M13.846 10c0 .354-.03.641-.385.641h-2.82v2.82c0 .354-.287.385-.641.385-.355 0-.641-.031-.641-.385v-2.82h-2.82c-.354 0-.385-.287-.385-.641 0-.354.031-.641.385-.641h2.82v-2.82c0-.354.286-.385.641-.385.354 0 .641.03.641.385v2.82h2.82c.354 0 .385.287.385.641z" className="accent--fill--color" /></svg>,
  



}
export default icons;