import axios from "axios";
import { getCookie } from "../../../helpers/Cookie";
import contactDetailsApiList from "./ApiList";

/* middleware api methods */
export const getContactDetailsApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getContactDetails, payload);
}

export const updateContactFieldApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.updateContactField, payload);
}

export const fetchCustomFieldApi = async () => {
    return sendRequest("post", contactDetailsApiList.customFields);
}

export const updateContactCustomFieldApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.contactSingleCustomFieldUpdate, payload);
}

export const getAllTagsApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getAllTags, payload);
}

export const addContactTagsApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.addContactTags, payload);
}

export const getContactTagListApi = async (payload)=>{
    return sendRequest("post", contactDetailsApiList.getContactTagList, payload);
}

export const getAllCampaignByContactApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getAllCampaignByContact, payload);
}

export const getEligibleCampaignsApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getEligibleCampaignForContact, payload);
}

export const moveCampaignContactApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.moveCampaignContact, payload);
}

export const campaignSingleContactRunApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.campaignSingleContactRun, payload);
}

export const campaignSingleContactPauseApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.campaignSingleContactPause, payload);
}

export const campaignSingleContactUnsubscribedApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.campaignSingleContactUnsubscribed, payload);
}

export const getLeadSourceApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.leadSource, payload);
}

export const updateContactSourceApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.changeContactSourceType, payload);
}

export const getPeopleActivityApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getPeopleActivity, payload);
}

export const markAsDoneApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.markAsDone, payload);
}

export const submitUserActivityApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.submitUserActivity, payload);
}

export const deleteContactFromStageApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.contactDeleteFromStage, payload);
}

export const getConversationApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getConversation, payload);
}

export const storeNoteApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.addNote, payload);
}

export const updateNoteApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.updateNote, payload);
}

export const getVirtualNumberApi = async () => {
    return sendRequest("post", contactDetailsApiList.getVirtualNumber);
}

export const sendConversationApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.sendConversation, payload);
}

export const sendBulkEmailApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.sendBulkEmail, payload);
}

export const getProfileDataApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getProfileData, payload);
}

export const getOtherEmailByContactApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.getOtherEmailByContact, payload);
}

export const removeContactTagApi = async (payload) => {
    return sendRequest("POST", contactDetailsApiList.removeContactTag, payload);
}

export const getContacts = async (payload) => {
    return sendRequest("post", contactDetailsApiList.getSearchContacts, payload);
}

export const saveActivity = async (payload) => {
    return sendRequest("post", contactDetailsApiList.saveActivity, payload);
}

export const getSubUserListForContactlist = async (payload) => {
    return sendRequest("post", contactDetailsApiList.subUserListForContactDropdown, payload);
}


/* root api */
export const sendRequest = (method, url, payload = [], config = []) => {
    let data = [];
    try{
        payload.append('api-key', getCookie(process.env.REACT_APP_ACCESS_TOKEN))
        data = payload;
    } catch (error) {
        data = {...payload, ...{'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)}};
    }
    return axios.request({
        method: method,
        url: url,
        data: data,
        params: (method === 'get' || method === 'GET') ? data : null
    });
}