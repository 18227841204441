import React, {useContext, useState} from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import {ContactDetailsV2Context} from "../../ContactDetailsV2Reducer";

const Address = (props) => {
    const {state, dispatch} = useContext(ContactDetailsV2Context);
    const { flexCenterBetween, flexCenter, secondaryButtonRounded, textDarkBlue, titleIcon, textBold600 } = ContactDetailsStyle();

    const makeAddress = () => {
        let address = "";

        if (state.address){
            address += state.address + "<br/>";
        }

        if (state.city){
            address += state.city;
        }

        if (state.state){
            if (address !== ""){
                address += ", ";
            }

            address += state.state;
        }

        if (state.zipCode){
            if (address !== ""){
                address += " ";
            }

            address += state.zipCode;
        }

        return address;
    };

    return (
        <Box sx={{width:'100% !important'}}>
            <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
                <CustomTitle title={"Address"} sx={{ display: "flex", flex: 1, alignItems: "center" }} />
            </Box>

         
            <Paper variant='outlined' elevation={0} style={{ padding: 15 }}>
            <Box className={flexCenterBetween} sx={{ alignItems: "normal !important", flexWrap: "wrap", gap: 15 }}>
                <Box>
                    <Typography variant='body1' style={{height:'auto'}} className={`${textDarkBlue} ${textBold600}`} dangerouslySetInnerHTML={{ __html: makeAddress() }}>
                    </Typography>
                    <Typography variant='subtitle2' color='textSecondary'>
                        Latitude: {state.latitude} Longitude: {state.longitude}
                    </Typography>
                </Box>
            </Box>
        </Paper>
          
        </Box>
    );
};

export default Address;