/* middleware types */
export const FETCH_CONTACT_DETAILS = 'FETCH_CONTACT_DETAILS'

/* reducer types */
export const UPDATE_STATE_FIELD = 'UPDATE_STATE_FIELD';
export const UPDATE_CONTACT_ID = 'UPDATE_CONTACT_ID';
export const UPDATE_CONTACT_DETAILS = 'UPDATE_CONTACT_DETAILS';
export const DELETE_CONTACT = 'DELETE_CONTACT';

//Get User Info
export const GET_SUBUSER_LIST = 'GET_SUBUSER_LIST'
export const SET_SUBUSER_LIST = 'SET_SUBUSER_LIST'