import React, {useState} from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
const DEFAULT_FILE_LIMIT = 1;
const ImageUploadInput = (props) => {

    const [images,setImages] = useState([]);

    const handleChange = (file) => {
        if(file.length === 0) {
            return false;
        }
        if((props.filesLimit !== undefined && props.filesLimit == 1) || DEFAULT_FILE_LIMIT === 1) {
            props.callBack(file);
            return false;
        }

        let uploadedImages = images;
        if(images.length > 0)
            uploadedImages.push(file);
        else
            uploadedImages = file;
        setImages(uploadedImages);
        if(uploadedImages.length > 0)
            props.callBack(uploadedImages);
    };

    const handleDelete = (prevImage) => {
        if((props.filesLimit !== undefined && props.filesLimit == 1) || DEFAULT_FILE_LIMIT === 1) {
            props.callBack(null);
            return false;
        } else {
            let previousFiles = [images];
            const indexToRemove = previousFiles.indexOf(prevImage);
            previousFiles.splice(indexToRemove, 1);
            setImages(previousFiles);
            props.callBack(previousFiles);
        }
    };

    const showNotification = (message,variant) => {
        window.showNotification(variant.toUpperCase(),message);
    }

    return (
        <DropzoneArea
            acceptedFiles={['image/*']}
            filesLimit={props.filesLimit !== undefined ? props.filesLimit : DEFAULT_FILE_LIMIT}
            maxFileSize={props.maxFileSize !== undefined ? props.maxFileSize : 5000000}
            dropzoneText={"Drag and drop an image here or click"}
            onChange={handleChange}
            onDelete={handleDelete}
            showAlerts={false}
            onAlert={showNotification}
        />
    );
}
export default ImageUploadInput;