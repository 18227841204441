import * as ACTION from "./contactDetailActionTypes";

const initialState = {
    contactId: null,
    contactDetails: {},
    selectedContactIndex: null,
    loggedOnUser: null,
    fullContactDetails: null,

    customFieldData: {},
    contactCustomFieldData: {},
    userTags: {},
    contactTags: {},
    availableCampaigns: {},
    contactCampaigns: {},
    allSources: {},
    contactSource: '',
    contactAppointments: {},
    contactTasks: {},

    fetchingContactDetail: true,
    deletingContact: false,
    fetchingCustomField: false,
    fetchingTag: false,
    fetchingCampaign: false,
    fetchingSource: false,
    fetchningAppointment: false,
    fetchingTask: false,

    reloadCotactDetails: false,

    /* modal state */
    showingSmsSendModal: false,
    showingEmailSendModal: false,
    showingVoiceSendModal: false,
    showingUpdateContactModal: false,

    /* tab options */
    tab: 'contact', //note

    subUserList: null,

};

const contactDetailReducer = (state = initialState, action) => {
    const {type, payload} = action 
    switch (type) {

        case ACTION.UPDATE_STATE_FIELD:
            return {...state, ...payload} //field array index must be related with state index

        case ACTION.UPDATE_CONTACT_ID:
            return {...state, contactId : payload, fetchingContactDetail: true}

        case ACTION.UPDATE_CONTACT_DETAILS:
            return {
                ...state, 
                contactDetails : payload.data.people != undefined ? payload.data.people : {}, 
                contactCustomFieldData : payload.data.people != undefined ? payload.data.people.all_contact_custom_fields : {}, 
                fetchingContactDetail: false,
                loggedOnUser: payload.data.loggedOnUser,
                fullContactDetails: payload.data
            }

        case ACTION.DELETE_CONTACT:
            return {...state, fetchingContactDetail: true, deletingContact: false, contactId : null, contactDetails: {}}
            
        case ACTION.SET_SUBUSER_LIST:
            return { ...state, subUserList: action.payload }
        default:
            return state;
    }
}
export default contactDetailReducer;