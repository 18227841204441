import React from 'react';
import 'react-day-picker/lib/style.css';
import GlobalModal from '../globals/Modal/GlobalModal';
import ContactDetail from '../contactDetails';

export default class ContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            title: 'Loading...'
        }
    }

    toggle() {
        // call reducer to unset contact id, contact details
        this.props.hideModal();
    }

    closeAndRefresh = () => {
        this.props.hideModal();
        this.props.changeUserData()
    }

    handleTitle = (title) => {
        this.setState({title})
    }

    render() {
        return (
            <GlobalModal
                className="global_modal_master_piece alternative noFooter contact_details_update"
                open={this.props.isOpen}
                title={this.state.title} 
                onClose={this.toggle}
                hideFooter={true}
                customWidth={'800px'}
            >
                <ContactDetail 
                    closeModal={this.closeAndRefresh} 
                    from={(this.props.from !== undefined && this.props.from != null) ? this.props.from : ''}
                    handleTitle={this.handleTitle}
                />
          </GlobalModal>
        );
    }
}