import {createContext, useReducer} from "react";

export const ContactDetailsV2Context = createContext({});

export const Actions = {
  SET_STATE: "SET_STATE"
};

const initialState = {
    latitude: "",
    longitude: "",
    firstName: "",
    lastName: "",
    email: "",
    emailValidationStatus: "",
    number: "",
    numberValidationStatus: "",
    dncStatus: "",
    numberType: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    homeOwnerFlag: "",
    residentialPropertyOwned: null,
    income: "",
    financialCreditRating: null,
    maritalStatus: "",
    occupation: "",
    age: null,
    childrenUpto18: "",
    children0to3: "",
    boatOwner: "",
    rvOwner: "",
    motorcycleOwner: "",
    lengthOfResidence: null,
    estimatedHomeValue: null,
    propertySaleDate: null,
    purchaseAmount: null,
    livingSquareFeet: null,
    landSquareFootage: null,
    occupationCode: null,
    subdivisionName: null,
    autoXDate: null,
    autoXDateSource: null,
    propertyPool: null,
    residentialPropertiesOwned: null,
    dateOfBirth: null,
    dwellingType: null,
    mortgageLoanTypeCode: null,
    mortgageAmount: null,
    secondMortgageAmount: null,
    mortgageInterestRate: null,
    lenderName: null
};


const reducer = (state,action) => {
    switch (action.type){
        case Actions.SET_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const ContactDetailsV2Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (<ContactDetailsV2Context.Provider value={{ state, dispatch}}>{children}</ContactDetailsV2Context.Provider>);
};