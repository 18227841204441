import React, {useContext, useEffect, useState} from "react";
import {Save} from "@material-ui/icons";
import ContactDetailsV2Styles from "./ContactDetailsV2.module.css";
import Utils from "../../../helpers/Utils";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {EnhancedDataButtonBackground, icons} from "../../../constants/icons";
import {Actions, ContactDetailsV2Context, ContactDetailsV2Provider} from "./ContactDetailsV2Reducer";
import ContactDetailsTab from "./ContactDetailsTab";
import SkipTraceResultsTab from "./SkipTraceResultsTab";
import Helper from "../../globals/Helper";
import ModGlobalModal from "./common/ModGlobalModal";
import Footer from "./common/Footer";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { BasicButton } from "./ContactDetailsStyle";
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../../globals/Dropdown/GlobalDropdown";
import ModalAssignToPowerDialer from "../enhanceDataAction/ModalAssignToPowerDialer";
import VideoCallIcon from '@material-ui/icons/VideoCall';
import CustomGlobalModal from "./common/CustomGlobalModal";

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;

const useStyles=makeStyles({
    enhanceData:{
        color:'black !important',
        background:'rgb(0, 255, 145) !important',
        padding:'1px 14px !important',
        borderRadius:'20px !important',
        "&:hover , &:focus":{
            background:'rgb(0, 255, 145) !important',
        }
    },
    contactDetailsModal:{
      maxWidth:'740px',
    },
    headerRightSide:{
      display:'flex',
      alignItems:'center',
      gap:'10px'
    },
    headerRightSideSpan:{
      display:'flex',
      cursor:'pointer'
    }
})

const App = (props) => {
    const {state, dispatch} = useContext(ContactDetailsV2Context);
    const [activeTab, setActiveTab] = useState(0);
    const [validationInfos, setValidationInfos] = useState([]);
    const [isSkipTraced, setIsSkipTraced] = useState(false);
    const [mailOptionVisible, setMailOptionVisible] = useState(null);
    const [openAddToPowerDialerModal, setOpenAddToPowerDialerModal] = useState(false);
   

    useEffect(()=>{
        if(props.savedContactDetails){
            let firstName = Helper.isEmpty(props.savedContactDetails.first_name) ? "" : props.savedContactDetails.first_name;

            let lastName = Helper.isEmpty(props.savedContactDetails.last_name) ? "" : props.savedContactDetails.last_name;

            let number = Helper.isEmpty(props.savedContactDetails.number) ? "" : props.savedContactDetails.number;

            let email = Helper.isEmpty(props.savedContactDetails.email) ? "" : props.savedContactDetails.email;

            let address = Helper.isEmpty(props.savedContactDetails.address) ? "" : props.savedContactDetails.address;

            let city = Helper.isEmpty(props.savedContactDetails.city) ? "" : props.savedContactDetails.city;

            let state = Helper.isEmpty(props.savedContactDetails.state) ? "" : props.savedContactDetails.state;

            let zipCode = Helper.isEmpty(props.savedContactDetails.zip) ? "" : props.savedContactDetails.zip;

            let country = Helper.isEmpty(props.savedContactDetails.country) ? "US" : props.savedContactDetails.country;

            let latitude = Helper.isEmpty(props.savedContactDetails.latitude) ? "" : props.savedContactDetails.latitude;

            let longitude = Helper.isEmpty(props.savedContactDetails.longitude) ? "" : props.savedContactDetails.longitude;

            dispatch({
                type: Actions.SET_STATE,
                payload: {
                    firstName,
                    lastName,
                    email,
                    number,
                    address,
                    city,
                    state,
                    zipCode,
                    country,
                    latitude,
                    longitude
                }
            });

            if(props.markerContactDetails != null){
                commonDataLoad();
            }
        }else if(props.markerContactDetails != null){
            let firstName = Helper.isEmpty(props.markerContactDetails.FirstName) ? "" : props.markerContactDetails.FirstName;
            let lastName = Helper.isEmpty(props.markerContactDetails.LastName) ? "" : props.markerContactDetails.LastName;

            let number = "";

            try {
                if (props.markerContactDetails.PhoneNumbers !== undefined && Array.isArray(props.markerContactDetails.PhoneNumbers) && props.markerContactDetails.PhoneNumbers.length > 0) {
                    number = Helper.isEmpty(props.markerContactDetails.PhoneNumbers[0].PhoneNumber) ? "" : props.markerContactDetails.PhoneNumbers[0].PhoneNumber;
                }
                else if (props.markerContactDetails.CellPhones !== undefined && Array.isArray(props.markerContactDetails.CellPhones) && props.markerContactDetails.CellPhones.length >0) {
                    number = Helper.isEmpty(props.markerContactDetails.CellPhones[0].PhoneNumber) ? "" : props.markerContactDetails.CellPhones[0].PhoneNumber;
                    if(props.setMarkerContactDetails){
                        let tempMarkerContactDetails = [...props.markerContactDetails];
                        tempMarkerContactDetails.PhoneNumbers = props.markerContactDetails.CellPhones;
                        props.setMarkerContactDetails(tempMarkerContactDetails)
                    }
                }
            } catch (e) {}

            let email = Helper.isEmpty(props.markerContactDetails.EmailAddress) ? "" : props.markerContactDetails.EmailAddress;

            let address = Helper.isEmpty(props.markerContactDetails.Property_FullAddress)? "" : props.markerContactDetails.Property_FullAddress;

            let city = Helper.isEmpty(props.markerContactDetails.City) ? "" : props.markerContactDetails.City;

            let state = Helper.isEmpty(props.markerContactDetails.State) ? "" : props.markerContactDetails.State;

            let zipCode = Helper.isEmpty(props.markerContactDetails.ZipCode) ? "" : props.markerContactDetails.ZipCode;

            let country = "US";

            let latitude = Helper.isEmpty(props.markerContactDetails.latitude) ? "" : props.markerContactDetails.latitude;

            let longitude = Helper.isEmpty(props.markerContactDetails.longitude) ? "" : props.markerContactDetails.longitude;

            dispatch({
                type: Actions.SET_STATE,
                payload: {
                    firstName,
                    lastName,
                    email,
                    number,
                    address,
                    city,
                    state,
                    zipCode,
                    country,
                    latitude,
                    longitude
                }
            });

            commonDataLoad();
        }
    },[]);

    const getContactEmail = () => {
        let contact = props.markerContactDetails;

        if(contact.EmailAddress !== undefined && contact.EmailAddress !== null){
            return contact.EmailAddress;
        }

        return "";
    }
console.log(props.markerContactDetails)
    const handleMakeCall = () => {
        if (window.initiateGlobalDialer){
            window.initiateGlobalDialer({
                dialToNumber : true,
                number : state.number
            });
        }
    }

    const handleSendSms = () => {
        if(window.handleGlobalSmsSendModal !== undefined){
            window.handleGlobalSmsSendModal({
                open: true,
                onClose:() => {},
                id: null,
                from: "map-preview-data",
                fetchContactDetails: false,
                contactInfo: props.markerContactDetails
            });
        }
    }

    const handleSendVoiceMail = () => {
        if(window.handleGlobalVoiceSendModal){
            window.handleGlobalVoiceSendModal({
                open: true,
                onClose:() => {},
                id: null,
                contactInfo: props.markerContactDetails,
                from: "map-preview-data",
                fetchContactDetails: false
            });
        }
    }

    const handleSendVideoMail = () => {
        window.openVideoEmailSend({
          open: true,
          contactId:null,
          contactDetails:state,
          from: 'map-preview-data',
      })
      
  }

    const handleSendEmail = () => {
        if(window.handleGlobalEmailSendModal !== undefined){
            window.handleGlobalEmailSendModal({
                open: true,
                onClose: () => {},
                id: null,
                contactInfo: props.markerContactDetails,
                from: "map-preview-data",
                primaryEmail: getContactEmail(),
                fetchContactDetails: false
            })
        }
    }

    const handleSendDirectMail = (type) => {
        setMailOptionVisible(false)

        window.openGlobalDirectMailModal({
            messageType: type,
            contactId: null,
            contactDetails: {
                ...props.markerContactDetails,
                address: props.markerContactDetails.Property_FullAddress
            },
            from: "map-preview-data",
            callBack: props.addSuccess
        })
    }

    const classes=useStyles()
    const renderHeader = () => {
        return (
          <div className="modify_modal_title_container">
            <div className={ContactDetailsV2Styles.headerLeftSide}>
              <div className={ContactDetailsV2Styles.tabWrapper}>
                <div
                  onClick={() => {
                    setActiveTab(0);
                  }}
                  className={`${ContactDetailsV2Styles.tabTitle} ${
                    activeTab === 0 ? ContactDetailsV2Styles.tabActive : ""
                  }`}
                >
                  Contact Info
                </div>
                {isSkipTraced && (
                  <div
                    onClick={() => {
                      setActiveTab(1);
                    }}
                    className={`${ContactDetailsV2Styles.tabTitle} ${
                      activeTab === 1 ? ContactDetailsV2Styles.tabActive : ""
                    }`}
                  >
                    SkipTrace Results
                  </div>
                )}
              </div>
              {/* {
                        (Utils.getAccountData("carrierLookup") ||
                            Utils.getAccountData("dnc") ||
                            Utils.getAccountData("skipTrace")
                        ) && <BootstrapTooltip arrow title="Click here for data enhancement">
                            <span className={ContactDetailsV2Styles.enhanceDataButton} onClick={()=>{
                                window.globalEnhancedData(true, {
                                    contact_additional_informations: null,
                                    email: state.email,
                                    number: state.number,
                                    address: state.address,
                                    city: state.city,
                                    state: state.state,
                                    zip: state.zipCode,
                                    requestModule: "CLEAN_DATA",
                                    callback: (res) => {
                                        if(res && res.data){
                                            setValidationInfos(res.data);

                                            if (res["skip-trace-lookup-status"] === "REQUEST_DONE"){
                                                setIsSkipTraced(true);
                                            }
                                        }
                                    }
                                });
                            }} >
                              <Button className={classes.enhanceData} variant="contained"> Enhance Data</Button>
                            </span>
                        </BootstrapTooltip>
                    } */}
            </div>
            <div className={classes.headerRightSide}>
              {(Utils.getAccountData("carrierLookup") ||
                Utils.getAccountData("dnc") ||
                Utils.getAccountData("skipTrace")) && (
                <BootstrapTooltip arrow title="Click here for data enhancement">
                  <span
                    className={ContactDetailsV2Styles.enhanceDataButton}
                    onClick={() => {
                      window.globalEnhancedData(true, {
                        contact_additional_informations: null,
                        email: state.email,
                        number: state.number,
                        address: state.address,
                        city: state.city,
                        state: state.state,
                        zip: state.zipCode,
                        requestModule: "CLEAN_DATA",
                        callback: (res) => {
                          if (res && res.data) {
                            setValidationInfos(res.data);

                            if (
                              res["skip-trace-lookup-status"] === "REQUEST_DONE"
                            ) {
                              setIsSkipTraced(true);
                            }
                          }
                        },
                      });
                    }}
                  >
                    <Button
                      disableElevation
                      className={classes.enhanceData}
                      variant="contained"
                    >
                      {" "}
                      Enhance Data
                    </Button>
                  </span>
                </BootstrapTooltip>
              )}
              {state.number !== null && state.number !== "" && (
                <>
                  <GlobalDropdown
                    className="add__action_dropdown"
                    placement="top"
                  >
                    <GlobalDropDownButton>
                    <span
                    title="Call"
                    className={`action-icon-call ${classes.headerRightSideSpan}`}
                    
                    // onClick={handleMakeCall}
                  >
                    {icons.callIconInList}
                  </span>
                    </GlobalDropDownButton>
                    <GlobalDropDownItem
                      // onClick={handleAddToConnectModalOpen}
                      title="Call Now"
                      onClickHide={true}
                      onClick={handleMakeCall}
                      // icon={IconList.addToContactSVG}
                    />
                    <GlobalDropDownItem
                      title="Add To Power Dialer"
                      onClick={()=>setOpenAddToPowerDialerModal(true)}
                      onClickHide={true}
                      // icon={IconList.addToListSVG}
                    />
                  </GlobalDropdown>
                
                </>
              )}
              {state.number !== null && state.number !== "" && (
                <span
                  title="Text"
                  className={`action-icon-sms ${classes.headerRightSideSpan}`}
                  onClick={handleSendSms}
                >
                  {icons.messageIcon}
                </span>
              )}
              {state.email !== null && state.email !== "" && (
                <span
                  title="Email"
                  className={`action-icon-email ${classes.headerRightSideSpan}`}
                  onClick={handleSendEmail}
                >
                  {icons.emailIconInListForSingle}
                </span>
              )}
              {state.number !== null && state.number !== "" && (
                <span
                  title="Voicemail"
                  className={`action-icon-voice ${classes.headerRightSideSpan}`}
                  onClick={handleSendVoiceMail}
                >
                  {icons.speakerIcon}
                </span>
              )}
              {state.email !== null && state.email !== "" && (
                <span
                  title="Video mail"
                  style={{border:'1px solid #546376',padding:'3px',borderRadius:'50%'}}
                  className={`action-icon-voice ${classes.headerRightSideSpan}`}
                  onClick={handleSendVideoMail}
                >
                  
                  <VideoCallIcon style={{color:'#546376'}}/>
                </span>
              )}
              {Utils.getAccountData("agencyId") === 1 &&
                ((state.number !== null && state.number !== "") ||
                  (state.email !== null && state.email !== "")) && (
                  <span
                    title="Direct mail"
                    className={`action-icon-direct-email ${classes.headerRightSideSpan}`}
                    onClick={(e) => setMailOptionVisible(e.currentTarget)}
                  >
                    {icons.directMailiconInContactDetails}
                  </span>
                )}
              <Menu
                id="more-menu"
                anchorEl={mailOptionVisible}
                keepMounted
                open={Boolean(mailOptionVisible)}
                onClose={() => {
                  setMailOptionVisible(null);
                }}
              >
                <MenuItem
                  onClick={() => handleSendDirectMail(POSTCARD)}
                  className="dropdownhelper-menuitem-class"
                >
                  <span>{icons.postCard}</span>&nbsp; Post Card
                </MenuItem>
                <MenuItem
                  onClick={() => handleSendDirectMail(GREETING_CARD)}
                  className="dropdownhelper-menuitem-class"
                >
                  <span>{icons.greetingCard}</span>&nbsp; Greeting Card
                </MenuItem>
                <MenuItem
                  onClick={() => handleSendDirectMail(LETTER)}
                  className="dropdownhelper-menuitem-class"
                >
                  <span>{icons.letter}</span>&nbsp; Letter
                </MenuItem>
                <MenuItem
                  onClick={() => handleSendDirectMail(GIFT)}
                  className="dropdownhelper-menuitem-class"
                >
                  <span>{icons.gift}</span>&nbsp; Gift
                </MenuItem>
              </Menu>
              {openAddToPowerDialerModal && (
          <ModalAssignToPowerDialer
            open={openAddToPowerDialerModal}
            markerContactDetails={props.markerContactDetails}
            onClose={() => setOpenAddToPowerDialerModal(false)}
          />
        )}
            </div>
          </div>
        );
    };

    const commonDataLoad=()=>{

        let dateOfBirth= Helper.isEmpty(props.markerContactDetails.DateOfBirth) ? "N/A" : props.markerContactDetails.DateOfBirth;

        let lengthOfResidence= Helper.isEmpty(props.markerContactDetails.LengthOfResidence) ? "N/A" : props.markerContactDetails.LengthOfResidence;

        let income = Helper.isEmpty(props.markerContactDetails.Income) ? "N/A" : Helper.incomeConvert(props.markerContactDetails.Income);

        let homeOwnerFlag = Helper.isEmpty(props.markerContactDetails.HomeownerConfirmed) ? "N/A" : Helper.homeOwnerFlag(props.markerContactDetails.HomeownerConfirmed);

        let estimatedHomeValue = Helper.isEmpty(props.markerContactDetails.EstimatedHomeValue) ? "N/A" : props.markerContactDetails.EstimatedHomeValue;

        let livingSquareFeet= Helper.isEmpty(props.markerContactDetails.Property_LivingSquareFeet) ? "N/A" : props.markerContactDetails.Property_LivingSquareFeet;

        let landSquareFootage= Helper.isEmpty(props.markerContactDetails.LandSquareFootage) ? "N/A" : props.markerContactDetails.LandSquareFootage;

        let occupation= Helper.isEmpty(props.markerContactDetails.Financial_Occupation) ? "N/A" : props.markerContactDetails.Financial_Occupation;

        let occupationCode= Helper.isEmpty(props.markerContactDetails.Financial_OccupationCode) ? "N/A" : props.markerContactDetails.Financial_OccupationCode;

        let subdivisionName= Helper.isEmpty(props.markerContactDetails.SubdivisionName) ? "N/A" : props.markerContactDetails.SubdivisionName;

        let propertyPool= Helper.isEmpty(props.markerContactDetails.Property_OwnSwimmingPool) ? "N/A" : Helper.propertyPool(props.markerContactDetails.Property_OwnSwimmingPool);

        let boatOwner= Helper.isEmpty(props.markerContactDetails.HasBoat) ? "N/A" : props.markerContactDetails.HasBoat;

        let motorcycleOwner= Helper.isEmpty(props.markerContactDetails.HasMotorcycle) ? "N/A" : props.markerContactDetails.HasMotorcycle;

        let rvOwner= Helper.isEmpty(props.markerContactDetails.HasRV) ? "N/A" : props.markerContactDetails.HasRV;

        let autoXDate= Helper.isEmpty(props.markerContactDetails.AutoXDate) ? "N/A" : props.markerContactDetails.AutoXDate;

        let autoXDateSource = Helper.isEmpty(props.markerContactDetails.AutoXDateSource) ? "N/A" : props.markerContactDetails.AutoXDateSource;

        let purchaseAmount= Helper.isEmpty(props.markerContactDetails.PurchaseAmount) ? "N/A" : props.markerContactDetails.PurchaseAmount;

        let propertySaleDate= Helper.isEmpty(props.markerContactDetails.Property_SaleDate) ? "N/A" : props.markerContactDetails.Property_SaleDate;

        let financialCreditRating= Helper.isEmpty(props.markerContactDetails.Financial_CreditRating) ? "N/A" : props.markerContactDetails.Financial_CreditRating;

        let residentialPropertiesOwned= Helper.isEmpty(props.markerContactDetails.Financial_ResidentialPropertiesOwned) ? "N/A" : props.markerContactDetails.Financial_ResidentialPropertiesOwned;

        let dwellingType= Helper.isEmpty(props.markerContactDetails.Property_DwellingType) ? "N/A" : Helper.dwellingType(props.markerContactDetails.Property_DwellingType);

        let maritalStatus = Helper.isEmpty(props.markerContactDetails.Family_Marital_Status) ? "N/A" : Helper.maritalStatus(props.markerContactDetails.Family_Marital_Status);

        let mortgageLoanTypeCode = Helper.isEmpty(props.markerContactDetails.Financial_MortgageLoanTypeCode) ? "N/A" : props.markerContactDetails.Financial_MortgageLoanTypeCode;

        let mortgageAmount = Helper.isEmpty(props.markerContactDetails.Financial_MortgageAmount) ? "N/A" : props.markerContactDetails.Financial_MortgageAmount;

        let secondMortgageAmount = Helper.isEmpty(props.markerContactDetails.Financial_SecondMortageAmount) ? "N/A" : props.markerContactDetails.Financial_SecondMortageAmount;

        let mortgageInterestRate = Helper.isEmpty(props.markerContactDetails.Financial_MortgageInterestRate) ? "N/A" : props.markerContactDetails.Financial_MortgageInterestRate;

        let lenderName = Helper.isEmpty(props.markerContactDetails.Financial_LenderName) ? "N/A" : props.markerContactDetails.Financial_LenderName;

        dispatch({
            type: Actions.SET_STATE,
            payload: {
                homeOwnerFlag,
                lengthOfResidence,
                income,
                financialCreditRating,
                maritalStatus,
                occupation,
                boatOwner,
                rvOwner,
                motorcycleOwner,
                propertySaleDate,
                estimatedHomeValue,
                livingSquareFeet,
                landSquareFootage,
                occupationCode,
                subdivisionName,
                autoXDate,
                autoXDateSource,
                purchaseAmount,
                propertyPool,
                residentialPropertiesOwned,
                dateOfBirth,
                dwellingType,
                mortgageLoanTypeCode,
                mortgageAmount,
                secondMortgageAmount,
                mortgageInterestRate,
                lenderName
            }
        });
    };
const handleCloseModal=()=>{
  props.onClose()
}

    return(
        <CustomGlobalModal {...props}
         onSubmit={()=>{

         }}
         closeButtonIcon=""
         buttonText="Save contact"
         buttonIcon={<Save/>}
         modalClass="mpf-contact-details-modal bg-white modify-modal-title"
         className={`mpf-contact-details-modal bg-white modify-modal-title  ${classes.contactDetailsModal}`}
         title={renderHeader()}
         extra_props_active={true}
         hideFooter={false}
         footerHeight={'70px'}
         footer={activeTab !== 1?<Footer
             addSuccess={props.addSuccess}
             validationInfos={validationInfos}
             markerContactDetails={props.markerContactDetails}
             state={state}
             handleCloseModal={handleCloseModal}
         />:''}
        >
            {
                activeTab === 1 ? <SkipTraceResultsTab addSuccess={props.addSuccess} markerContactDetails={props.markerContactDetails} validationInfos={validationInfos} handleCloseModal={handleCloseModal} state={state}/> : <ContactDetailsTab {...props} handleCloseModal={handleCloseModal}/>
            }
        </CustomGlobalModal>
    );
};

const Index = (props) => {
    return (
        <ContactDetailsV2Provider>
            <App {...props}/>
        </ContactDetailsV2Provider>
    );
};

export default Index;

