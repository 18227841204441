import React from 'react'
import { IconList } from './IconList';
import "./customAudioPlayer.css";

class CustomAudioPlayer extends React.PureComponent {

    constructor(){
        super()
        this.state = {
            fileUrl: '',
            currentTime: 0,
            exactCurrentTime: 0,
            duration: 0,
            exactDuration: 0,
            isPlay: false,
            isMute: false,
            interval: '',
            click: false,
            showToolTip: false
        }
    }

    componentDidMount() {
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.src = this.props.src
        audioFile.addEventListener("loadedmetadata", () => {
            this.setState({duration: Math.ceil(audioFile.duration), exactDuration:audioFile.duration})
        })
        // audioFile.addEventListener("canplaythrough", () => {
        //     console.log("file loaded",audioFile.duration)
        // })
    }

    componentWillUnmount(){
        this.stopInterval()
    }

    componentDidUpdate(prevProps){
        let id = 'custom-audio-player'+this.props.unique_key;
        if(this.props.activePlayerId != prevProps.activePlayerId && !this.state.click){
            this.handlePause()
        }
        else{
            this.setState({click: false})
        }
    }

    // helper function
    calculateTime = (time) => {
        let seconds = "00", minutes = "00";
        seconds = this.pad(time % 60)
        minutes = this.pad(parseInt(time / 60))
        return minutes + ":" + seconds;
    }

    pad = (val) => {
        var valString = val.toString();
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    // action function
    handlePause = () => {
        this.setState({isPlay: false, click: false})
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.pause()
        audioFile.muted = true
        this.stopInterval()
    }

    handlePlay = () => {
        this.setState({isPlay: true, click: true})
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.play()
        audioFile.muted = false
        audioFile.currentTime = this.state.currentTime
        this.setState({isMute: true})
        this.startInterval()
        // this.props.hanldePlayer('custom-audio-player'+this.props.unique_key)
        this.props.hanldePlayer(this.props.unique_key)
    }

    handleStop = () => {
        this.setState({isPlay: false, click: false})
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.pause()
        audioFile.muted= false
        audioFile.currentTime= 0
        this.stopInterval()
        this.setState({currentTime: 0})
    }

    handleMute = () => {
        this.setState({isMute: false})
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.muted = true
    }

    handleUnMute = () => {
        this.setState({isMute: true})
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.muted = false
    }

    // interval function
    startInterval = () => {
        // if(this.state.interval != ''){
        //     return false
        // }
        let interval = setInterval(() => {
            this.setState({currentTime : this.state.currentTime + 1}, () => {
                if(this.state.currentTime >= this.state.duration){
                    this.handleStop()
                }
            })
        }, 1000);
        this.setState({interval})
    }

    stopInterval = () => {
        clearInterval(this.state.interval)
    }

    // download function
    downloadFile = () => {
        let link = document.createElement('a')
        link.href = this.props.src
        link.style.display = 'none'
        link.setAttribute('download', "download-audio-file"+this.props.unique_key+"."+this.props.src.split('.').pop())
        link.setAttribute('target', "_blank")
        link.setAttribute('id', "download-audio-file"+this.props.unique_key)
        document.getElementsByTagName('body')[0].appendChild(link)
        // firefox
        if(document.createEvent){
            let event = document.createEvent('MouseEvents')
            event.initEvent('click', true, true)
            link.dispatchEvent(event)
        }
        // IE
        else if(link.click){
            link.click()
        }
        link.parentNode.removeChild(link)
    }

    handleToolTip = () => {
        this.setState({showToolTip: true})
        setTimeout(() => {
            this.setState({showToolTip: false})
        }, 1500);
    }

    handleOnChangeRange = (event) => {
        this.setState({currentTime: event.target.value})
        let audioFile = document.getElementById('custom-audio-player'+this.props.unique_key)
        audioFile.currentTime = event.target.value
        this.stopInterval()
        this.handlePlay()
    }

    render(){
        return(
            <>
                <audio className='d-none' id={`custom-audio-player${this.props.unique_key}`} controls muted src="" />
                <div className="custom_audio_player_independent audio-setup">
                    <ul>
                        {this.state.isPlay &&
                        <li className="play-pause-btn" onClick={this.handlePause}>
                            <span>{IconList.pauseIconBlue}</span>
                        </li>
                        }
    
                        {!this.state.isPlay &&
                        <li className="play-pause-btn" onClick={this.handlePlay}>
                            <span>{IconList.playIcon}</span>
                        </li>
                        }
    
                        <li className="duration">
                            <span>{this.calculateTime(this.state.currentTime)}/{this.calculateTime(this.state.duration)}</span>
                        </li>

                        <li className="lg-range-field range-field message_audio">
                            {/* onInput={(event)=>console.log(event)} 
                                onChange={this.handleOnChangeRange}
                                onClick={this.handleToolTip}
                            */}
                            <input id={`range-input-${this.props.unique_key}`} min={'0'} max={this.state.exactDuration} type="range" value={this.state.currentTime}/>
                            {/* {this.state.showToolTip &&
                            <span className="thumb active" style={{height:'30px', width:'30px',top: '-30px',marginLeft:'-7px', left: `${(this.state.currentTime*(100/this.state.exactDuration))}px`}}><span className="value">{this.state.currentTime}</span></span>
                            } */}
                        </li>

                        {this.state.isMute &&
                        <li className="sound" onClick={this.handleMute}>
                            <span>{IconList.unmuteIcon}</span>
                        </li>
                        }
    
                        {!this.state.isMute &&
                        <li className="sound" onClick={this.handleUnMute}>
                            <span>{IconList.muteIcon}</span>
                        </li>
                        }
    
                        {(typeof this.props.showDownloadOption != 'undefined' && this.props.showDownloadOption) &&
                        <li className="download-icon" onClick={this.downloadFile}>
                            <span>{IconList.downloadIcon}</span>
                        </li>
                        }

                        {typeof this.props.showDownloadOption == 'undefined' &&
                        <li className="download-icon" onClick={this.downloadFile}>
                            <span>{IconList.downloadIcon}</span>
                        </li>
                        }
    
                    </ul>
                </div>
            </>
        )
    }

    
}
export default CustomAudioPlayer;