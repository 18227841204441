import React, { useState } from "react";
import {
	FormGroup, 
	Button, 
	Select,
} from "@material-ui/core";
import { TextField } from "@material-ui/core"; 
import DateRangeIcon from "@material-ui/icons/DateRange";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function LeadProcess(props) {
	const [age, setAge] = useState(10);
	const selectHandleChange = (event) => {
		setAge(event.target.value);
	};

	 const [startDate, setStartDate] = useState(new Date());
	 
	return (
		<React.Fragment>
			<div className="contact__steps_wrapper">
				<div className="contact__first_steps alternative">
					<FormGroup className="d-flex align-items-center threeColumns">
						<label>
							Batch Quantity <span>*</span>
						</label>
						<div className="contacts__datePicker"> 
							<DatePicker placeholder="06/03/2021" selected={startDate} onChange={(date) => setStartDate(date)} />
							<DateRangeIcon />
						</div>
						<div>
							<Select
								native
								value={age}
								onChange={selectHandleChange}
								name="select_option">
								<option aria-label="None" value="12" />
								<option value={10}>07:15 AM</option>
								<option value={20}>Twenty</option>
								<option value={30}>Thirty</option>
							</Select>
						</div>
						<div>
							<Button className="ppc__blue_btn">
								Mountain Time
							</Button>
						</div>
					</FormGroup>

					<FormGroup className="d-flex align-items-center">
						<label>Batch Quantity <span>*</span></label>
						<TextField type="number" value="123456789212" />
					</FormGroup>

					<FormGroup className="d-flex align-items-center">
						<label>Repeat After (Days) <span>*</span></label>
						<TextField type="number" value="123456789212" />
					</FormGroup>
					<FormGroup className="sendOn d-flex align-items-center">
						<label>Send On *</label>
						<div className="sendOn__buttons_group">
							<Button className="active">Mon</Button>
							<Button>Mon</Button>
							<Button>Mon</Button>
							<Button>Mon</Button>
							<Button>Mon</Button>
							<Button>Mon</Button>
						</div>
					</FormGroup>
				</div>
			</div>
		</React.Fragment>
	);
}
