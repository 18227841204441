import React, {useContext} from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import {ContactDetailsV2Context} from "../../ContactDetailsV2Reducer";

const MortgageInfo = (props) => {
    const {state, dispatch} = useContext(ContactDetailsV2Context);
    const { flexCenterBetween, flexCenter, secondaryButtonRounded, textBlueMapContactColor, titleIcon, textBold600 } = ContactDetailsStyle();

    const makeMortgageInfo = () => {
        let additionalDetails = "";

        if (state.mortgageLoanTypeCode){
            additionalDetails += "Mortgage Loan Type (conventional, FHA, etc): ";
            additionalDetails += state.mortgageLoanTypeCode === 'C' ? "Conventional" : state.mortgageLoanTypeCode;
        }else{
            additionalDetails += "Mortgage Loan Type (conventional, FHA, etc): N/A";
        }

        if (state.mortgageAmount){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Mortgage Amount in Thousands (indicates amount of 1st mortgage): $";
            additionalDetails += state.mortgageAmount;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Mortgage Amount in Thousands (indicates amount of 1st mortgage): N/A";
        }

        if (state.secondMortgageAmount){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "2nd Mortgage Amount: $";
            additionalDetails += state.secondMortgageAmount;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "2nd Mortgage Amount: N/A";
        }

        if (state.mortgageInterestRate){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Recent Mortgage Interest Rate: ";
            additionalDetails += state.mortgageInterestRate + "%";
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Recent Mortgage Interest Rate: N/A";
        }

        if (state.lenderName){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Purchase Lender Code (lender associated with purchase): ";
            additionalDetails += state.lenderName;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Purchase Lender Code (lender associated with purchase): N/A";
        }

        return additionalDetails;
    };

    return (
        <Box sx={{width:'100% !important'}}>
            <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
                <CustomTitle title={"Mortgage Info"} sx={{ display: "flex", flex: 1, alignItems: "center" }} />
            </Box>

            <Paper variant='outlined' elevation={0} style={{ padding: 15 }}>
                <Box className={flexCenterBetween} sx={{ alignItems: "normal !important", flexWrap: "wrap", gap: 15 }}>
                    <Box>
                        <Typography variant='body1' className={`${textBlueMapContactColor} ${textBold600}`} dangerouslySetInnerHTML={{ __html: makeMortgageInfo() }}>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default MortgageInfo;