import * as ACTION from "./videoEmailActionTypes";

const initialState = {
    messageTemplates: null,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    customFieldData : [],
    user : null
};

const inboxReducer = (state = initialState, action) => {
    switch (action.type) {

        /* Video Email Start */
        case ACTION.FETCH_VIDEOS:
            return {...state, videoLoader: true}

        case ACTION.STORE_VIDEOS:
            console.log("---------->", action.payload)
            return {...state, ...action.payload, videoLoader: false}

        case ACTION.STORE_VIDEO_FOLDERS:
            return {...state, videoFolders: action.payload, videoLoader: false}

        case ACTION.UPDATE_FOLDER_ID:
            return {...state, folderId: action.payload}
        /* Video Email End */

        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return {...state, messageTemplates: action.payload}
        /* Message Template End */

        case ACTION.STORE_CUSTOM_FIELD:
            return {...state, customFieldData: action.payload}

        case ACTION.SET_USER:
            return {...state, user: action.payload}

        default:
            return state;
    }
}

export default inboxReducer;
