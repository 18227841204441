import { combineReducers } from 'redux'
import contactDetailReducer from '../components/contactDetails/redux/contactDetailReducer';
import cardBroadcastReducer from '../components/DirectCardSend/redux/cardBroadcastReducer';
import inboxReducer from '../components/Video/redux/videoEmailReducer';
import mapReducer from "./mapReducer";
import tagReducer from "./tagReducer";

export default combineReducers({
   mapReducer: mapReducer,
   tagReducer: tagReducer,
   inboxReducer : inboxReducer,
   contactDetailReducer: contactDetailReducer,
   cardBroadcastReducer: cardBroadcastReducer
})