import React from "react";
import {
    Box
} from "@material-ui/core";
import Address from "./contactDetails/address/Address";
import ContactInfo from "./contactDetails/contactInfo/ContactInfo";
import AdditionalDetails from "./contactDetails/additionalDetails/AdditionalDetails";
import RelatedContacts from "./contactDetails/relatedContacts/RelatedContacts";
import PropertyInfo from "./contactDetails/propertyInfo/PropertyInfo";
import MortgageInfo from "./contactDetails/mortgageInfo/MortgageInfo";

const ContactDetailsTab = (props) => {
    return(
        <Box sx={{ display: "flex", flexDirection: "column", gap: 25, pb: 3 }}>
            <Address/>
            <ContactInfo markerContactDetails={props.markerContactDetails} handleCloseModal={props.handleCloseModal}/>
            <AdditionalDetails/>
            <RelatedContacts markerContactDetails={props.markerContactDetails}  handleCloseModal={props.handleCloseModal}/>
            <PropertyInfo/>
            <MortgageInfo/>
        </Box>
    );
};

export  default ContactDetailsTab;