import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import EachPhoneNumber from "../../common/EachPhoneNumber";
import { ContactDetailsV2Context } from "../../ContactDetailsV2Reducer";
import Icons from "../../../Icons";
import ValidationModal from "../../common/ValidationModal";
import SkipTraceActionButton from "../skipTraceActionButton/SkipTraceActionButton";

const useStyles = makeStyles({
  checkDNC: {
    color: "black !important",
    background: "rgb(0, 255, 145) !important",
    padding: "1px 14px !important",
    borderRadius: "20px !important",
    "&:hover , &:focus": {
      background: "rgb(0, 255, 145) !important",
    },
  },
});

const ContactInfo = (props) => {
  const { state } = useContext(ContactDetailsV2Context);
  const { flexCenterBetween, flexCenter, textDarkBlue, textBold600 } =
    ContactDetailsStyle();
  const [skipTrace, setSkipTrace] = useState(true);
  const [validateEmail, setValidateEmail] = useState(false);
  const [openValidationModal, setOpenValidationModal] = React.useState(false);
  const [showEmailValidationIcon, setShowEmailValidationIcon] = useState(null);
  const handleOpenValidationModal = () => {
    setOpenValidationModal(true);
    setValidateEmail(true);
  };
  const makeFullname = () => {
    let fullname = "";

    if (props.contactdata?.firstName) {
      fullname += props.contactdata?.firstName;
    }
    if (props.contactdata?.middleName) {
      if (fullname) {
        fullname += " ";
      }
      fullname += props.contactdata?.middleName;
    }

    if (props.contactdata?.lastName) {
      if (fullname) {
        fullname += " ";
      }

      fullname += props.contactdata?.lastName;
    }

    return fullname;
  };

  const makeEmail = () => {
    let email = "";

    if (props.contactdata?.email) {
      email = "Email: ";
      email += props.contactdata?.email;
    }

    return email;
  };

  const handleCloseValidationModal = () => {
    setOpenValidationModal(false);
  };
  const classes = useStyles();
  return (
    <Box>
      <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
        <CustomTitle
          title={"Contact Info"}
          sx={{ display: "flex", flex: 1, alignItems: "center" }}
        />
      </Box>
        <Paper variant="outlined" elevation={0} style={{ padding: 15 }}>
      {props.contactdata?  <Box>
        <Box
            className={flexCenterBetween}
            sx={{ alignItems: "normal !important", flexWrap: "wrap", gap: 15 }}
          >
            <Box>
              <Typography
                variant="body1"
                style={{ height: "auto" }}
                className={`${textDarkBlue} ${textBold600}`}
              >
                {makeFullname()} <br />
              </Typography>
              {props.contactdata?.email && (
                <Box sx={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <Typography
                    variant="body1"
                    className={`${textDarkBlue} ${textBold600}`}
                  >
                    {makeEmail()}
                  </Typography>
                  {showEmailValidationIcon === "VALID" ? (
                    <IconButton disableElevation> {Icons.valid}</IconButton>
                  ) : showEmailValidationIcon === "INVALID" ? (
                    <IconButton disableElevation> {Icons.invalid}</IconButton>
                  ) : (
                    <Button
                      disableElevation
                      className={classes.checkDNC}
                      variant="contained"
                      onClick={handleOpenValidationModal}
                    >
                      {" "}
                      Validate
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {props.contactdata?.number && (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 10, mt: 2 }}
            >
              <EachPhoneNumber
                markerContactDetails={props.markerContactDetails}
                skipTrace={skipTrace}
                number={props.contactdata.number}
                status={state.numberValidationStatus}
                dnc={state.dncStatus}
              />
            </Box>
          )}

          {(props.contactdata?.number||props.contactdata?.email) && <SkipTraceActionButton
            cleanContactId={props.contactdata?.cleanContactId}
            addSuccess={props.addSuccess}
            state={props.state}
            markerContactDetails={props.markerContactDetails}
            handleCloseModal={props.handleCloseModal}
          />}
        </Box>:<Typography>No Data Found</Typography>}
          
        </Paper>
      <ValidationModal
        skipTrace={skipTrace}
        email={props.contactdata?.email}
        handleCloseModal={props.handleCloseModal}
        validateEmail={validateEmail}
        handleClose={handleCloseValidationModal}
        openValidationModal={openValidationModal}
        markerContactDetails={props.markerContactDetails}
        setShowEmailValidationIcon={setShowEmailValidationIcon}
      />
    </Box>
  );
};

export default ContactInfo;
