import React, { useEffect, useState } from "react";
import _ApiList from "../../SendModules/api/ApiList";
import VoiceSend from "../../SendModules/voice";
import ModalGlobalModal from "../ModalGlobalModal";

const VoiceSendModal = (props) => {

    const [totalContacts,setTotalContacts] = useState(0);
    const [voiceMailCost,setVoicemailCost] = useState(0);

    useEffect(() => {
        if(props.from === 'map') {
            _ApiList.getOrderSummaryForMap({type:'Voicemail'}).then(res => {
                if(res.data.success) {
                    setTotalContacts(res.data.data.recipientsCount);
                    setVoicemailCost(res.data.data.perCost);
                } else {
                    window.showNotification("ERROR",res.data.message);
                    props.onClose(false);
                }
            });
        }
    },[]);

    return(
        <ModalGlobalModal
            open={props.open}
            title="Send Voice Mail"
            onClose={() => props.onClose(false)}
            width={800}
            hideFooter={true}
            className="global_modal_overflow_hidden activity__modal_overflow"
            modalClass="fixed-height-100-80 global__send_modal_wrapper global__send_module_modal v3__send_voice_mail"
        >
            <VoiceSend
                showTimeSetting = {true}
                showSelectedContact= {false}
                selectedContactItem= {(<div>contact information</div>)}
                contact_id={props.data.id}
                contact_data={props.data}
                onClose={() => props.onClose(false)}
                onSubmit={()=>props.onSubmit!== undefined ? props.onSubmit() : console.log("submit") }
                from={props.from}
                perVoicemailCost={voiceMailCost}
                totalContacts={totalContacts}
            />
        </ModalGlobalModal>
    )

}
export default VoiceSendModal;