import {sendRequest} from "../../../api/rootApi";
import Api from "./Api";

export const fetchVideoFolders = async (payload) => {
    return sendRequest("post", Api.fetchVideoFolders, payload);
}

export const fetchVideos = async (payload) => {
    return sendRequest("post", Api.fetchVideos, payload);
}

export const deleteVideo = async (payload) => {
    return sendRequest("post", Api.deleteVideo, payload);
}

export const getMessageTemplate = async (payload) => {
    return sendRequest("post", Api.getMessageTemplate, payload);
}

export const fetchCustomField = async () => {
    return sendRequest("post", Api.customFields);
}

export const getUserEmailList = async (payload) => {
    return sendRequest("post", Api.userEmailList, payload);
}

export const sendVideoEmail = async (payload) => {
    return sendRequest("post", Api.sendBulkMessage, payload);
}

export const getUserInfo = async (payload) => {
    return sendRequest("post", Api.getUserInfo, payload);
}