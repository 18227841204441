import React, { useEffect, useState, useRef } from "react";
import { Person } from "@material-ui/icons";
import { connect } from 'react-redux'
import DatePicker from "react-datepicker";
import moment from 'moment'

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';


/* icons */
import icons from "../../assets/icons";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { getContactDetails, _updateStateField } from "../../redux/contactDetailAction";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../assets/CustomAccordion";
import { CustomFieldType } from "../../assets/constant";
import { fetchCustomFieldApi, updateContactCustomFieldApi } from "../../redux/contactDetailApi";


const mapStateToProps = state => ({
  contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    getContactDetails: params => dispatch(getContactDetails(params))  
})

const App = (props) => {

    const {
        contactDetails, contactCustomFieldData
    } = props.contactDetailReducer

    const [expand, setExpand] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [conactCustomFields, setConactCustomFields] = useState([])
    const [fetchingData, setFetchingData] = useState(false)

    useEffect(() => {
        if(expand) {
            setConactCustomFields(contactCustomFieldData)
            setFetchingData(true)
            fetchCustomField()
        }
    }, [expand])

    useEffect(() => {
        if(expand) {
            setConactCustomFields(contactCustomFieldData)
        }
    }, [contactDetails])

    const fetchCustomField = () => {
        fetchCustomFieldApi().then(res => {
            let response = res.data
            setCustomFields(response.data)
            setFetchingData(false)
        })
    }

    return(
        <div className="left__column columnItem">
            <CustomAccordion expand={expand} className={"collapsible defaultToggle trigger__agency"}>
                <CustomAccordionHeader onClick={() => setExpand(!expand)} className={`collapsible-header trigger__header border__bottom flex content-between items-center  ${expand ? 'expand' : ''}`}>
                <span className="trigger__subtitle"><Person /> Custom Fields </span>
                {icons.caretIcon}
                </CustomAccordionHeader>
                <CustomAccordionBody className="collapsible-body ">
                {fetchingData ? 
                <div className="empty-container-contact-details">loading...</div>
                :
                <ul className="templateItems ds__templateItems awesome__scroll_bar maxH100" style={{display: 'block'}}>
                    {customFields.map((item, index) => {
                        return(
                            <EachField 
                                item = {item}
                                index={index}
                                key={index}
                                conactCustomFields={conactCustomFields}
                            />
                        )
                    })}
                </ul>
                }
                </CustomAccordionBody>
            </CustomAccordion>
        </div> 
    )
}
const CustomField = connect(mapStateToProps, mapDispatchToProps)(App);
export default CustomField;

const EachFieldApp = (props) => {

    const [showInput, setShowInput] = useState(false)
    const [value, setValue] = useState('')
    let inputData = ''

    const renderValue = () => {
        let valueText = ''
        props.conactCustomFields.map((item) => {
            if(item['user_custom_fields'].personalize_tag == props.item.personalize_tag){
                valueText = item.value
            }
        })
        inputData=valueText
        return valueText;
    }

    const handleShowInput = () => {
        setShowInput(true)
        setValue(inputData)
    }

    const onChangeValue = (value) => {
        setValue(value)
    }

    const handleSubmit = () => {
        updateContactCustomFieldApi({
            contact_id: props.contactDetailReducer.contactId,
            custom_field_id: props.item.id,
            value: value
        }).then(res => {
            props.getContactDetails({
                id: props.contactDetailReducer.contactId
            })
            setShowInput(false)
        })
    }

    return(
        <div>
            <li className="ds__templateItems_li common__round_circle">
                <div className="ds__template_topbar">
                    <span style={{marginRight: 50}}>{props.item.title}</span>
                    {!showInput &&
                    <button className="cursor-pointer" onClick={handleShowInput}>edit</button>
                    }
                </div>
                
                <div className="ds__value_wrapper">
                    
                    {!showInput &&
                    <>
                    <span>{renderValue()}</span>
                    </>
                    }
                    {showInput &&
                    <>
                    <div className="ds__value_item" onClick={handleSubmit}><CheckIcon /></div>
                    <div className="ds__value_item" onClick={() => setShowInput(false)}><ClearIcon /></div>
                    </>
                    }
                </div>

                {showInput && 
                    <div className="ds__templateItems_li_edit">
                        <RenderEachCustomField
                            data={props.item}
                            index={props.index}
                            onChange={onChangeValue}
                            previousData={value}
                        />
                    </div>
                }
            </li>
        </div>
    )
}
export const EachField = connect(mapStateToProps, mapDispatchToProps)(EachFieldApp);

export const RenderEachCustomField = (props) => {
    const {data, index, onChange, previousData} = props

    const [checkBox, setCheckBox] = useState(previousData)

    useEffect(() => {
        console.log(previousData)
        setCheckBox(previousData)
    }, [previousData])

    const checkValueExit = (value) => {
        if(checkBox.includes(value)){
            return true
        }
        return false
    }

    const handleOnChange = (e, self) => {
        if(type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.ZIP_CODE){
            onChange(e.target.value, data?.max_length)
        }
        else if(type_id == CustomFieldType.CHECKBOX ){
            let new_value = ''
            if(checkValueExit(e)){
                if(checkBox.indexOf(e) !== -1){
                    let value_array = checkBox.split(",")
                    value_array.forEach((each_data, each_index) => {
                        if(each_data != e){
                            if(new_value == ''){
                                new_value += each_data
                            }
                            else{
                                new_value = new_value + "," + each_data
                            }
                        }
                    });
                }
                
            }
            else{
                if(checkBox.trim() == ''){
                    new_value = e
                }
                else{
                    new_value = checkBox + "," + e
                }
            }
            setCheckBox(new_value)
            onChange(new_value)
        }
        else if(type_id == CustomFieldType.RADIO){
            onChange(e, index, data.id)
        }
        else if(type_id == CustomFieldType.DATE){
            onChange(moment(e).format('YYYY-MM-DD'))
            // moment(new Date()).format("YYYY-MM-DD")
        }
        else{
            onChange(e.target.value)
        }
    }

    const type_id = data.type_id;

    if(type_id == CustomFieldType.TEXT || type_id == CustomFieldType.MULTILINE_TEXT || 
        type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.DATE || 
        type_id == CustomFieldType.PHONE || type_id == CustomFieldType.ZIP_CODE ||
        type_id == CustomFieldType.WEBSITE || type_id == CustomFieldType.CHECKBOX ||
        type_id == CustomFieldType.RADIO || type_id == CustomFieldType.SELECT ){
        return (
            <div className="input-field col s12 m12 custom_input_forms custom_contact_field" key={index}>

                {(type_id == CustomFieldType.TEXT || type_id == CustomFieldType.PHONE) &&
                    <input className="global-contact-details-input-custom-field" type="text" id={`custom-field-${index}`} value={previousData} placeholder={data?.title} maxLength={data?.max_length} onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.MULTILINE_TEXT &&
                    <textarea rows="5" className={'custom-field-textarea global-contact-details-input-custom-field'} id={`custom-field-${index}`} placeholder={data?.title}
                        onChange={(e) => handleOnChange(e)}
                    >{previousData}</textarea>
                }

                {(type_id == CustomFieldType.NUMERIC || type_id == CustomFieldType.ZIP_CODE) &&
                    <input className="global-contact-details-input-custom-field" type="number" id={`custom-field-${index}`} value={previousData} placeholder={data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.DATE &&
                    // <DatePicker id={`custom-field-${index}`} className="trigger__modal_textField dealValue setFullWidth global-contact-details-input-custom-field" placeholderText={'dd/mm/yyyy'} selected={new Date(previousData)} onChange={(date) => handleOnChange(date)} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                            format="MM/dd/yyyy"
                            value={(previousData == '') ? moment(new Date()).format("YYYY-MM-DD") : moment(new Date(previousData)).format("YYYY-MM-DD")}
                            // onChange={(date)=>setActivityDate(date)}
                            onChange={(date) => handleOnChange(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            // className="trigger__modal_textField dealValue setFullWidth global-contact-details-input-custom-field" 
                            // className="add_activity_text_field radius-5 white" 
                        />
                        </Grid>
                    </MuiPickersUtilsProvider>
                }

                {type_id == CustomFieldType.WEBSITE &&
                    <input className="global-contact-details-input-custom-field" type="url" id={`custom-field-${index}`} value={previousData} placeholder={data?.title} maxLength={data?.max_length} pattern="[0-9]{11}" onChange={(e) => handleOnChange(e)} />
                }

                {type_id == CustomFieldType.CHECKBOX &&
                    <div className="check-box-input-groups">
                    {data.default_values.map((each, each_index) => {
                        return(
                            <div key={each_index} className="custom_checkbox">
                                <div onClick={() => {handleOnChange(each)}} className="d-inline-flex" style={{color: '#525252bd'}}>
                                    {checkValueExit(each) ? <CheckIcon color="secondary"/> : <CheckBoxOutlineBlankIcon />}
                                    {each}
                                </div>
                            </div>
                        )
                    })}
                    </div>
                }

                {type_id == CustomFieldType.RADIO &&
                    <div className="radio-button-input-groups">
                    {data.default_values.map((each, each_index) => {
                        return(
                            <div key={each_index} className="custom_checkbox">
                                <div onClick={() => {handleOnChange(each)}} className="d-inline-flex" style={{color: '#525252bd'}}>
                                    {checkValueExit(each) ? <FiberManualRecordIcon color="secondary" /> : <RadioButtonUncheckedIcon />}
                                    {each}
                                </div>
                            </div>
                        )
                    })}
                    </div>
                }

                {type_id == CustomFieldType.SELECT &&
                    <div className="contact-details-custom-select">
                        <select className="form-control h-100" value={previousData} style={{display: 'block'}} placeholder={data.title} name={`add_contact_custom_field${data.personalize_tag}`} onChange={(e) => handleOnChange(e)}>
                            <option value="" key={-1} disabled>{data.title}</option>
                            {data.default_values.map((each, each_index) => {
                                return(
                                    <option value={each} key={`custom-select-${each_index}`}>{each}</option>
                                )
                            })}
                        </select>
                    </div>
                }
            </div>
        )
    }

    return null
}