import React, {useContext} from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import {ContactDetailsV2Context} from "../../ContactDetailsV2Reducer";

const calculateAge = (birthDate) => {
    const currentDate = new Date();
    const birthDateTime = new Date(birthDate);

    let age = currentDate.getFullYear() - birthDateTime.getFullYear();

    if (currentDate.getMonth() < birthDateTime.getMonth() || (currentDate.getMonth() === birthDateTime.getMonth() && currentDate.getDate() < birthDateTime.getDate())) {
        age--;
    }

    return age;
}

const AdditionalDetails = (props) => {
    const {state, dispatch} = useContext(ContactDetailsV2Context);
    const { flexCenterBetween, flexCenter, secondaryButtonRounded, textBlueMapContactColor, titleIcon, textBold600 } = ContactDetailsStyle();

    const makeAdditionalDetails = () => {

      let additionalDetails = "";

        if (state.homeOwnerFlag){
            additionalDetails += "Homeowner Flag: ";
            additionalDetails += state.homeOwnerFlag;
        }else{
            additionalDetails += "Homeowner Flag: N/A";
        }

        if (state.residentialPropertiesOwned){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Residential Properties Owned: ";
            additionalDetails += state.residentialPropertiesOwned;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Residential Properties Owned: N/A";
        }

        if (state.income){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Income: ";
            additionalDetails += state.income;
        }if (additionalDetails){
            additionalDetails += "<br/>";
        }
        additionalDetails += "Income: N/A";

        if (state.financialCreditRating){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Financial Credit Rating: ";
            additionalDetails += state.financialCreditRating;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Financial Credit Rating: N/A";
        }

        if (state.maritalStatus){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Marital Status: ";
            additionalDetails += state.maritalStatus;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Marital Status: N/A";
        }

        if (state.occupation){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Occupation: ";
            additionalDetails += state.occupation;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Occupation: N/A";
        }

        // if (state.occupationCode){
        //     if (additionalDetails){
        //         additionalDetails += "<br/>";
        //     }
        //     additionalDetails += "Occupation Code: ";
        //     additionalDetails += state.occupationCode;
        // }

        if (state.dateOfBirth){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Age: ";
            additionalDetails += calculateAge(state.dateOfBirth);
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Age: N/A";
        }

        if (state.Property_DwellingType){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Dwelling type: ";
            additionalDetails += state.Property_DwellingType;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Dwelling type: N/A";
        }

        if (state.childrenUpto18){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Children (indicate children up to 18) present in household : ";
            additionalDetails += state.childrenUpto18;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Children (indicate children up to 18) present in household : N/A";
        }

        if (state.children0to3){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Children 0 to 3 in household : ";
            additionalDetails += state.children0to3;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Children 0 to 3 in household : N/A";
        }

        if (state.boatOwner){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Boat Owner: ";
            additionalDetails += state.boatOwner;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "Boat Owner: N/A";
        }

        if (state.rvOwner){
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "RV Owner: ";
            additionalDetails += state.rvOwner;
        }else{
            if (additionalDetails){
                additionalDetails += "<br/>";
            }
            additionalDetails += "RV Owner: N/A";
        }

        // if (state.lengthOfResidence){
        //     if (additionalDetails){
        //         additionalDetails += "<br/>";
        //     }
        //     additionalDetails += "Length Of Residence: ";
        //     additionalDetails += state.lengthOfResidence;
        // }

        // if (state.autoXDate){
        //     if (additionalDetails){
        //         additionalDetails += "<br/>";
        //     }
        //     additionalDetails += "Auto XDate: ";
        //     additionalDetails += state.autoXDate;
        // }

        // if (state.autoXDateSource){
        //     if (additionalDetails){
        //         additionalDetails += "<br/>";
        //     }
        //     additionalDetails += "Auto XDate Source: ";
        //     additionalDetails += state.autoXDateSource;
        // }

      return additionalDetails;
    };

    return (
        <Box sx={{width:'100% !important'}}>
            <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
                <CustomTitle title={"Additional Details"} sx={{ display: "flex", flex: 1, alignItems: "center" }} />
            </Box>

            <Paper variant='outlined' elevation={0} style={{ padding: 15 }}>
                <Box className={flexCenterBetween} sx={{ alignItems: "normal !important", flexWrap: "wrap", gap: 15 }}>
                    <Box>
                        <Typography variant='body1' className={`${textBlueMapContactColor} ${textBold600}`} dangerouslySetInnerHTML={{ __html: makeAdditionalDetails() }}>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default AdditionalDetails;