import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';

window.renderMapFinderModule = (containerId, history) => {

    //To access sibling module from parent module. Like this module already loaded.
    if(document.getElementById(containerId)) {
        ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
    }

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App history={history} />
            </Provider>
        </React.StrictMode>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

window.unmountMapFinderModule = containerId => {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('MapFinderModule-root')) {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('MapFinderModule-root'));
    serviceWorker.unregister();
}