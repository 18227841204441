import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Button, Chip, FormControl, Input, MenuItem, Select } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import { Search } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    //For Loader
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const ShowSelectedDisposition = (props) => {

    const classes = useStyles();
    const theme = useTheme();

    const searchByDisposition = () => {

        if (props.selectedDispositionId.length > 0) {
            props.SearchMapByDisposition(props.selectedDispositionId, props.dispositionList);
        } else {

            props.clearSelection()
            window.showNotification('ERROR', "no disposition selected")
        }
    }

    return (
        <FormControl variant="outlined" margin="none" focused="false" className="email__pophover__wr map__finder__search__wr drp_locations">
            <Select
                labelId="multiple-custom-select"
                id="multiple-custom-select"
                multiple
                displayEmpty
                value={props.selectedDispositionName}
                onChange={(event) => props.updateSelectedMultipleValue(event)}
                input={<Input id="multiple-custom-select" />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>{"Select Disposition"}</em>;
                    }
                    return (
                        <div className={classes.chips}>
                            {selected.map((value, index) => (
                                <Chip key={index} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem disabled value="">
                    <em>{"---Select Disposition---"}</em>
                </MenuItem>
                {/* {dispositionList.map((each, index) => (
                // <MenuItem key={index} value={each} style={getStyles(each.name, dispositionList, theme)} >
                //     {each.name}
                // </MenuItem>*/}

                {(props.update || !props.update) && props.dispositionList.map((each, indexId) => {
                    if (!props.selectedDispositionId.includes(each.id)) {
                        return (
                            <MenuItem className={'dropdownhelper-menuitem-class'} key={`disposition-${indexId}`} value={each} style={getStyles(each.name, props.dispositionList, theme)}>
                                {each.name}
                            </MenuItem>
                        )
                    }

                    if (props.selectedDispositionId.includes(each.id)) {
                        return (
                            <MenuItem className={'dropdownhelper-menuitem-class'} key={`disposition-${indexId}`} value={each} style={getStyles(each.name, props.dispositionList, theme)}>
                                {each.name} <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-6yvdg3" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffTwoToneIcon" tabindex="-1" title="HighlightOffTwoTone"><path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm4 10.59L14.59 16 12 13.41 9.41 16 8 14.59 10.59 12 8 9.41 9.41 8 12 10.59 14.59 8 16 9.41 13.41 12 16 14.59z" opacity=".3"></path><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>
                            </MenuItem>
                        )
                    }

                })}
                {/* ))} */}
            </Select>

            <Button variant="contained" color="primary" onClick={() => searchByDisposition()}>
                <Search /> Search
            </Button>
        </FormControl>

    )
}

export default ShowSelectedDisposition;