import React, { useEffect, useState } from "react";
import {
    Box
} from "@material-ui/core";
import MailingAddress from "./skipTraceResults/mailingAddress/MailingAddress";
import PropertyAddress from "./skipTraceResults/propertyAddress/PropertyAddress";
import ContactInfo from "./skipTraceResults/contactInfo/ContactInfo";
import RelatedContacts from "./skipTraceResults/relatedContacts/RelatedContacts";
import SkipTraceActionButton from "./skipTraceResults/skipTraceActionButton/SkipTraceActionButton";

const SkipTraceResultsTab = (props) => {
    const [contactdata,setContactData]=useState([])
    const [relatedContactdata,setRelatedContactData]=useState([])
    useEffect(()=>{
      const  cleanDataInfos = props.validationInfos.filter((validationInfo)=>(validationInfo.source === "SKIP_TRACE"));
      setContactData(cleanDataInfos)
      cleanDataInfos?.map((data,index)=>{
        if(index!==0){
            setRelatedContactData([...relatedContactdata,data])
        } 
      })
    },[props.validationInfos])

    return(
        <Box sx={{ display: "flex", flexDirection: "column", gap: 25, pb: 3 }}>
            <PropertyAddress contactdata={contactdata[0]}/>
            <MailingAddress contactdata={contactdata[0]}/>
            <ContactInfo contactdata={contactdata[0]} markerContactDetails={props.markerContactDetails} addSuccess={props.addSuccess} handleCloseModal={props.handleCloseModal} />
            <RelatedContacts relatedContactdata={relatedContactdata} markerContactDetails={props.markerContactDetails} addSuccess={props.addSuccess} handleCloseModal={props.handleCloseModal}/>
            
        </Box>
    );
};

export  default SkipTraceResultsTab;