const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const inboxUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
const profileUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`
const leadFlowUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/leadFlow`
const mapFinderUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/map`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`

const Api = {
    campaigns: `${rootUrl}/campaigns`,
    addSingleContactToCampaign: `${rootUrl}/campaigns/add/single-contact`,
    campaignDetails: `${rootUrl}/details`,
    campaignTimeline: `${rootUrl}/campaign/timelines`,
    customFields: `${inboxUrl}/get-custom-fields`,
    updateCampaignDetails: `${rootUrl}/update-details`,
    campaignLeadSourceDetails: `${rootUrl}/get-campaign-leadsource-details`,
    getUserData: `${profileUrl}/get-user-data`,
    fetchLeadGroups: `${rootUrl}/get-lead-groups`,
    updateLeadGroups: `${rootUrl}/update-lead-groups`,
    fetchLeadFlows: `${rootUrl}/get-lead-flows`,
    updateLeadFlows: `${rootUrl}/update-lead-flows`,
    shareCampaign: `${rootUrl}/campaigns/share-link-with-friend`,
    deleteCampaign: `${rootUrl}/campaign/delete`,

    getPreRecording: `${rootUrl}/voice-template`,
    storeCampaignStepSetting: `${rootUrl}/add/campaign-setting-step`,
    storeCampaignStepSettingPreTemp: `${rootUrl}/add/campaign-setting-step/pre-temp`,
    updateCampaignActivitySetting: `${rootUrl}/update-campaign-activity`,
    updateCampaignSetting: `${rootUrl}/update-campaign-settings`,
    updateCampaignStepSettingWithMultimedia: `${rootUrl}/upload-multimedia-file-edit`,
    updateCampaignStepSettingWithPreviousFile: `${rootUrl}/multimedia-update-previous-file`,
    updateCampaignStepSettingWithPreTemp: `${rootUrl}/edit/campaign-setting-step/pre-temp`,
    fetchStages: `${rootUrl}/fetch-stages`,
    fetchTags: `${rootUrl}/get-all-tags`,
    fetchTeamMembers: `${rootUrl}/get-all-team-members`,
    fetchCampaignsExceptCurrent: `${rootUrl}/get-all-campaign-except-current`,
    saveFollowup: `${rootUrl}/campaign/followup/save`,
    saveActionFollowup: `${rootUrl}/campaign/action-followup/save`,
    saveActivityFollowup: `${rootUrl}/campaign/activity-followup/save`,
    fetchCampaignContactStat: `${rootUrl}/get-campaign-contact-stat`,
    fetchVideoFolders: `${rootUrl}/video/folders`,
    fetchVideos: `${rootUrl}/videos`,
    deleteVideo: `${rootUrl}/video/delete`,
    uploadVideo: `${rootUrl}/video/upload`,
    getMessageTemplate: `${rootUrl}/message-templates`,
    messageTemplateDelete: `${rootUrl}/message-template-delete`,
    getSingleMessageTemplate: `${rootUrl}/get-single-message-template`,
    updateMessageTemplate: `${rootUrl}/update-message-template`,
    addToBroadcast: `${rootUrl}/add-to-broadcast`,

    updateCampaignSmsStepSetting: `${rootUrl}/update-campaign-settings/sms/step`,
    updateCampaignEmailStepSetting: `${rootUrl}/update-campaign-settings/email/step`,
    updateCampaignVideoEmailStepSetting: `${rootUrl}/update-campaign-settings/video-email/step`,
    updateCampaignCallBridgeStepSetting: `${rootUrl}/update-campaign-settings/call-bridge/step`,

    deleteCampaignetting: `${rootUrl}/timeline-settings/delete`,

    getMailCategory: `${rootUrl}/get-card-basic-setting`,
    getMailTemplate: `${rootUrl}/get-card-templates`,
    createMailTemplate: `${rootUrl}/card-template-create`,
    producePDF: `${rootUrl}/generate-pdf`,
    addCardBroadcastToContact: `${rootUrl}/add-card-broadcast-to-contact`,
    getThirdPartyInfo: `${rootUrl}/get-third-party-info`,
    getCustomFields: `${rootUrl}/get-custom-fields`,

    // for lead source
    fetchLeadSource: `${rootUrl}/lead-sources`,

    fetchContactListForDataTable: `${rootUrl}/contacts/datatable`,
    contactMoveToCampaign: `${rootUrl}/contact/move/campaign`,

    // copy campaign
    copyCampaign: `${rootUrl}/campaign/copy`,
    campaignEnableToStart: `${rootUrl}/enable-to-start`,
    getCampaignBasicInfo: `${rootUrl}/get-campaign-basic-info`,
    campaignCheckToStart: `${rootUrl}/check-able-to-start`,
    campaignStart: `${rootUrl}/campaign/start`,
    campaignPause: `${rootUrl}/campaign/pause`,

    addQuickReplyTemplate: `${rootUrl}/msg-temp-add-submit`,
    createNewCampaign: `${rootUrl}/campaign/create`,

    userEmailList: `${rootUrl}/user-email-list`,
    userNumberList : `${rootUrl}/virtual-number-list`,

    // lead group
    updateOrCreateLeadGroup: `${profileUrl}/update-or-create-lead-group`,
    getTeamUsers: `${profileUrl}/get-team-users`,

    /* campagin templates */
    getCampaginTemplateCategory: `${rootUrl}/campaign/wizard/setup`,
    getCampaignTemplateByCategory: `${rootUrl}/agency/campaign/templates/list`,
    getCampaignTemplateDetails: `${rootUrl}/system/campaign/campaign-info`,
    createCampaignFromTemplate: `${rootUrl}/system/campaign-from-scratch`,

    getSmsTemplate: `${rootUrl}/get/sms/template`,

    createLeadFlow: `${leadFlowUrl}/create`,

    getBroadcastOrderSummaryForMap: `${mapFinderUrl}/get-broadcast-order-summary-for-map`,

    senBroadcastFromMap: `${containerUrl}/send-broadcast-for-map`
}

export default Api;