// export const FETCH_CALENDARS = 'FETCH_CALENDARS';
// export const STORE_CALENDAR = 'STORE_CALENDAR';

export const GET_ALL_CONTACTS_POSITION_ARRAY = 'GET_ALL_CONTACTS_POSITION_ARRAY';
export const SET_ALL_CONTACTS_POSITION_ARRAY = 'SET_ALL_CONTACTS_POSITION_ARRAY';

export const GET_TAG_LIST = 'GET_TAG_LIST';
export const SET_TAG_LIST = 'SET_TAG_LIST';


export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST';
export const SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST';

export const GET_DISPOSITION_LIST = 'GET_DISPOSITION_LIST';
export const SET_DISPOSITION_LIST = 'SET_DISPOSITION_LIST';

export const SET_CAMPAIGN_FOR_MAPSIDEBAR = 'SET_CAMPAIGN_FOR_MAPSIDEBAR';
export const SET_DISPOSITION_FOR_MAPSIDEBAR = 'SET_DISPOSITION_FOR_MAPSIDEBAR';

export const GET_SEND_MESSAGE_CREDIT = 'GET_SEND_MESSAGE_CREDIT';
export const SET_CARRIER_LOOKUP_PRICE = 'SET_CARRIER_LOOKUP_PRICE';