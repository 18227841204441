import * as ACTION from "../constants/mapActionTypes";
import React from "react";

const initialState = {
    allContactPositionArray: null,
    campaignList: null,
    dispositionList: null,
    selectedCampaignForMapSidebar: null,
    selectedDispositionForMapSidebar: null,
    carrierLookupPrices: null
};

const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_ALL_CONTACTS_POSITION_ARRAY:
            return {...state, allContactPositionArray: action.payload}
        case ACTION.SET_CAMPAIGN_LIST:
            return {...state, campaignList: action.payload}
        case ACTION.SET_DISPOSITION_LIST:
            return {...state, dispositionList: action.payload}
        case ACTION.SET_CAMPAIGN_FOR_MAPSIDEBAR:
            return {...state, selectedCampaignForMapSidebar: action.payload}
        case ACTION.SET_DISPOSITION_FOR_MAPSIDEBAR:
            return {...state, selectedDispositionForMapSidebar: action.payload}
        case ACTION.SET_CARRIER_LOOKUP_PRICE:
            return {...state, carrierLookupPrices: action.payload}
        default:
            return state;
    }
}

export default mapReducer;
