const checkEmptyJson = data => {
     if(JSON.stringify(data) == '{}'){
        return false
     }
    return true
}

const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {
    if(mobileNumber != '' && mobileNumber.length < 10){
        return false;
    }
    else if (mobileNumber != '' && mobileNumber.length > 11){
        return false
    }else if(mobileNumber.length == 11 && mobileNumber.substring(0,1) != 1){
        return false;
    }
    return true    
}

const checkLetterExistInText = (text) => {
    var regExp = /[a-zA-Z]/g;
    let result = false
    if(regExp.test(text)){
        result = true
    }
    return result
}

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

const isEmpty = (text) => {
    if(text != undefined && text!= null && text.trim() != ''){
        return false
    }else{
        return true
    }
}

const isValidText = (text) => {
    if(text != undefined && text!= null){
        return true
    }
    return false
}

const getAvaterText = (firstName, lastName) => {
    var FN = _Helper.isEmpty(firstName);
    var LN = _Helper.isEmpty(lastName);
    if (FN && LN) {
      return false;
    }
    else if (!FN && !LN) {
      return firstName.substring(0, 1).toUpperCase() + "" + lastName.substring(0, 1).toUpperCase()
    }
    else if (FN && !LN) {
      return lastName.substring(0, 2).toUpperCase()
    } else if (!FN && LN) {
      return firstName.substring(0, 2).toUpperCase()
    } else {
      return false;
    }
}
const getContactName= (contact)=>{

    let firstName = (contact.first_name !== undefined && contact.first_name != null) ? contact.first_name.trim() : ''
    let lastName = (contact.last_name !== undefined && contact.last_name != null) ? contact.last_name.trim() : ''
    if(firstName ==='' && lastName ===''){

        if(!isEmpty(contact.number)){
            return contact.number
        }else if(!isEmpty(contact.email)){
            return contact.email
        }else{
            return null
        }
    }else{
        return (firstName+' '+lastName).trim()
    } 
}

const getCookie = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return atob(c.substring(name.length, c.length));
        }
    }
    return "";
}

const getCookieLogin = cname => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            let str = c.substring(name.length, c.length).replace(/\s/g, ''); 
            return decodeURIComponent(window.atob(str))
        }
    }
    return "";
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 */
 export const getAccountData = (needle) => {
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

const _Helper = {
    checkEmptyJson,
    validateEmail,
    validateMobileNumber,
    checkLetterExistInText,
    formatPhoneNumber,
    isEmpty,
    isValidText,
    getAvaterText,
    getContactName,
    getAccountData,
    getCookie,
    getCookieLogin
}
export default _Helper;