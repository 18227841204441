export const AlertIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={120.03}
            height={102.79}
            {...props}
            viewBox="0 0 120 120"
        >
            <g>
                <path
                    d="M118.44 89.42l-25.2-41.1L67.8 5.13c-4.08-6.78-11.88-6.78-15.84-.3l-25.2 41-25.2 43.58c-4.08 6.8.36 13.37 8.4 13.37h100.08c8.4-.32 12.48-6.9 8.4-13.38zM66.84 29.1l-1.92 36.37h-9.6l-1.8-36.38h13.32zM60 87.87c-4.68 0-7.8-3.5-7.8-7.8 0-4.64 3.12-7.72 7.8-7.72s7.44 3.08 7.8 7.7c0 4.33-3.12 7.82-7.8 7.82z"
                    stroke="#000"
                    strokeOpacity={0}
                    fill="#0078d7"
                />
            </g>
        </svg>
    );
};