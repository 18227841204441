import React from "react";
import { Box, Divider, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ContactDetailsStyle } from "../ContactDetailsStyle";
import AddIcon from '@material-ui/icons/Add';

const customTitleStyles = makeStyles((theme) => ({
    titleStyle: {
        fontSize: "16px !important",
        fontWeight: "600 !important",
        color: "var(--dark_blue) !important",
        textTransform: "uppercase"
    },
    titleIconStyle:{
        background:'var(--light_blue) !important',
        color: "white !important",
        "&:hover,&:focus":{
            background:'var(--light_blue) !important',
        }
    }
}));

const CustomTitle = ({ title = "Default Title", sx, children ,setIsOpen,isOpen,isContact}) => {
    const { flexCenterBetween, flexCenter } = ContactDetailsStyle();
    const { titleStyle ,titleIconStyle} = customTitleStyles();
    return (
      <Box sx={sx}>
        <Box className={flexCenterBetween} sx={{ flexWrap: "wrap" ,marginTop:'0px!important'}}>
          <Typography variant="body2" className={titleStyle}>
            {title}
          </Typography>

          {children && (
            <Box className={flexCenter} sx={{ gap: 10 }}>
              {children}
            </Box>
          )}
        </Box>

        <Box sx={{ flex: 1, mx: 1,width:'100% !important',marginBottom:"0px !important",paddingBottom:"0px !important"  }}>
          <Divider width='100%'/>
        </Box>
      {
        isContact&&  <Box sx={{paddingTop:'0px !important'}}>
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={titleIconStyle}
          size="small"
        >
          <AddIcon /> 
        </IconButton>
      </Box>
      }
      </Box>
    );
};

export default CustomTitle;