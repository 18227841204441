import { FormGroup, Checkbox, FormControlLabel, Button } from "@material-ui/core";
import { IndeterminateCheckBox } from "@material-ui/icons";
import React, { useState,useEffect } from "react";
import {connect} from 'react-redux';
import { fetchAllTagList } from "../../actions/mapAction";
import GlobalModal from '../globals/Modal/GlobalModal';
import DynamicSkeleton from "./contactDetailsV2/common/DynamicSkeleton";

const LOAD_TAGS = 2;
const EXCLUDE_TAGS = 3;
const TagModalComponent=(props)=>{

	const [componentLoading, setComponentLoading] = useState(true)
	const [tagList, setTagList] = useState([])
	const [selectedTags, setSelectedTags] = useState([])

	useEffect(() => {
		getAllTagsForDropdown()
	},[])

	useEffect(() => {
		
	},[])

	const getAllTagsForDropdown = async () => {

		if(props.userTagList != null){
			setTagList(props.userTagList)
			setComponentLoading(false)
		}else{
			await props.fetchUserTagList({}, (callback)=>{

				setTagList(callback)
				setComponentLoading(false)
			})        
		}
		}
        

	const updateTagList=(tag)=>{

		let indexOf = selectedTags.indexOf(tag.id);

		let tempSelectedTagList = [...selectedTags];
		if(indexOf < 0){
			tempSelectedTagList.push(tag.id);
		}else{
			tempSelectedTagList.splice(indexOf,1)

		}
		setSelectedTags(tempSelectedTagList)
	}

	const changeAllTags=()=>{
		if(tagList.length === selectedTags.length){
			setSelectedTags([])
		}else{
			let idArray = [];
			for(let i = tagList.length; i--; ){
				idArray.push(tagList[i].id)
			}
			setSelectedTags(idArray)
		}
	}

	return (
		<React.Fragment>
			<GlobalModal {...props}
				placement="right" 
				title="Tag List" 
				modalClass="ppm_tags__modal" 
				className="map__finder__tag__lists__wr__v2"
			>

			{!componentLoading &&
			<FormGroup className="awesome__scroll_bar map__finder__tag__lists__container__v2 ppc__searchSelect_with_input ppc__customCheckbox">
				{(tagList && tagList.length >0) ?
				<div className="mpf-tag-select-all">
				  <FormControlLabel control={<Checkbox 
				 	checked={tagList.length === selectedTags.length ? true : false} 
				  color="primary" onChange={()=>changeAllTags()} />} label="Select All Tag" />
				</div> :
				<div className="mpf-tag-select-all">
					<p>You don't have any tags</p>
				</div>
				}

				<div className="tagList__checkbox_wrapper  ">
					<ul className="m-nav  pb-0">
	
						{tagList.map((tag)=>(
							<li style={{background:"#f2efef",
								marginBottom:"10px",
								borderRadius:"5px",
							  }}>
								<FormControlLabel
									key={`tagId-${selectedTags.length}${tag.id}`}
	
									control={
										<Checkbox
											checked={selectedTags.includes(tag.id)}
											onChange={() => updateTagList(tag)}
											name="checkedB"
											color="primary"
										/>
									}
									label={tag.name}
								/>
							</li>
						))
						}
					</ul>
				</div>
			</FormGroup>
			}
			{componentLoading &&
				// <div className="tag-component-loading" style={{"height": "calc(100vh - 200px)"}}>
        		// 	<NewLoader loading={true} onlyLoader={true} size={25} message={""} />
				// </div>
				<DynamicSkeleton  count={12}/>
			}
			{(tagList && tagList.length >0) &&
            <div className="map__finder__tag__lists__footer__v2 d-flex align-align-items-center justify-content-center ">
                <Button className="ppc__blue_btn search__contracts accent--bg--color" onClick={()=>window.searchByTagInMapModule(selectedTags, props.type)}>
					{props.type ===EXCLUDE_TAGS ? "Exclude tags" : "Load Tag contacts"}
				</Button>
            </div>
			}
			</GlobalModal>
		</React.Fragment>
	);
}
const mapStateToProps = state => {
    return {
        userTagList: state.tagReducer.tagList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserTagList: (params, callback) => dispatch(fetchAllTagList(params, callback)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagModalComponent);