import React, { useState } from "react";
import {
	FormControl, 
	TextField, Button
} from "@material-ui/core"; 
import Utils from "../../helpers/Utils";
const SetOrderName=(props)=> {

	const [orderName, setOrderName] = useState('')

	const submitData=()=>{
		if(orderName == ''){
			Utils.showNotification("List Name required","ERROR");
		}else{
			props.onSubmit(orderName)
		}
	}
	return (
		<React.Fragment>
			<div className="ppc__search_modal_wrapper">
				<form>
					<FormControl component="fieldset">
						<label className="global__modal_subtitle">List Title</label>
						<TextField
							className="global__input_text_field"
							id="outlined-basic" 
							variant="outlined"
							value={orderName}
							onChange={(event)=>setOrderName(event.target.value)}
							placeholder="Type your List title"
						/>
					</FormControl>
				</form>
				<div className="d-flex align-align-items-center justify-content-center">
                <Button className="ppc__blue_btn search__contracts accent--bg--color" onClick={submitData}>
					{"Next"}
				</Button>
            </div>
			</div>
		</React.Fragment>
	);
}

export default SetOrderName;
