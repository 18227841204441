import React, { useEffect, useState } from 'react';
import _ApiList from '../api/ApiList';
import _label from '../assets/_label';
import DynamicSkeleton from '../../../map/contactDetailsV2/common/DynamicSkeleton';

const SmsTemplates = props => {
    const [templates, setTemplates] = useState([])
    const [fetchingTemplate, setFetchingTemplate] = useState(false)

    useEffect(() => {
        getTemplate()
    }, [])

    const getTemplate = () => {
        setFetchingTemplate(true)
        _ApiList.getTemplates().then(res => {
            setFetchingTemplate(false)
            setTemplates(res.data.html)
        })
    }
    return(
        <div className="global-send-module-sms-template-container">
            <div className="topOption d-flex align-items-center justify-content-between mb-2">
                <h6>SMS Quick Reply list</h6>
                <p className="back_button" onClick={props.handleOnBack}>{'<<'} Back</p>
            </div>

            {fetchingTemplate ?
                    <DynamicSkeleton count={8} height="80px"/> 
                    :
                <>
                {templates.length == 0 ?
                    _label.emptySmsTemplate
                    :
                    templates.map((item, index) => {
                        return(
                            <div className="global_send_module_singleItem" key={index} onClick={() => props.handleSelect(item.message)}>
                                <p className="sms-template-title">Title: {item.title}</p>
                                <p className="sms-template-message" dangerouslySetInnerHTML={{ __html: item.message }} />
                            </div>
                        )
                    })
                }
                </>
            } 
        </div>
    )
}
export default SmsTemplates;