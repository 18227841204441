import React, { useState, useEffect } from "react";
import { Save } from "@material-ui/icons";
import {
  MenuItem,
  Select,
  InputBase,
  withStyles,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import {
  cleanContactsActionsApi,
  saveContactFromMapSidebar,
} from "../../../api/mapApi";
import GlobalModal from "../../globals/Modal/GlobalModal";
import NewLoader from "../../Common/NewLoader";
import {
  getPipelineList,
  getStageListWithPagination,
} from "../../../api/enhanceData";
import "./enhanceDataAssignStage.css";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    fontSize: "17px !important",
    borderRadius: "4px !important",
    position: "relative",
    color: "#707070 !important",
    border: "1px solid #ced4da !important",
    padding: "16px 26px 16px 12px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
    },
  },
}))(InputBase);
const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    color: "var(--dark_blue)",
    fontSize: "16px !important",
    margin: "0 !important",
    height: "3.4rem !important",
    padding: "16px 12px 16px 15px !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

const ModalListForStageAdd = (props) => {
  const [selectedPipelineId, setSelectedPipelineId] = useState("");
  const [selectedStageId, setSelectedStageId] = useState("");
  const [submittingData, setSubmittingData] = useState(false);
  const [stageListForAction, setStageListForAction] = useState([]);
  const [pipelineList, setPipelineList] = useState([]);
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [closingDate, setClosingDate] = useState("");

  useEffect(() => {
    getPipelineList()
      .then((res) => {
        if (res && res.data && res.data.data) {
          setPipelineList(res.data.data);
        } else {
          setPipelineList([]);
        }
      })
      .catch((err) => {
        setPipelineList([]);
      });
  }, []);

  const updatePipeline = async (pipelineId) => {
    setSelectedPipelineId(pipelineId);
    if (setSelectedStageId !== "") {
      setSelectedStageId("");
    }
    await getStageListForDropdown(pipelineId, "action");
  };

  const updateStage = (stageId) => {
    setSelectedStageId(stageId);
  };

  const handleSubmit = () => {
    const payload = {
      dealTitle: title,
      dealValue: value,
      closing_date: closingDate,
      selectedPipelineId,
      stage: selectedStageId,
      contactDetails: props.markerContactDetails,
    };
    if (
      title ||
      value ||
      selectedPipelineId ||
      selectedStageId ||
      closingDate
    ) {
      saveContactFromMapSidebar(payload)
        .then((res) => {
          if (res && res.data.success) {
            window.showNotification("success", res.data.message);
            props.handleCloseModal()
            this.props.onClose();
          } else {
            window.showNotification(
              "error",
              "Something went wrong try again later"
            );
          }
        })
        .finally(() => {
          this.setState({
            submittingContact: false,
          });
        });
    }
  };

  const submitContactInStage = async () => {
    if (selectedStageId === "") {
      window.showNotification("ERROR", "Please select stage");
      return;
    }

    const payload = {
      cleanContactIds: [props.cleanContactId],
      filterType: "SEGMENT",
      action: "ADD_TO_PIPELINE_STAGE",
      pipelineStageIds: [parseInt(selectedStageId)],
    };

    setSubmittingData(true);
    cleanContactsActionsApi(payload)
      .then((res) => {
        if (res && res.success) {
          window.showNotification("success", res.message);
          props.onClose();
          props.callback({
            success: true,
            removeItem: props.cleanContactId,
            index: props.index,
          });
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          );
        }
      })
      .finally(() => {
        setSubmittingData(false);
      });
  };

  const getStageListForDropdown = async (
    pipelineId,
    type = "",
    perPage = 20,
    pageNo = 1,
    scroll = false
  ) => {
    let params = { pipeline_id: pipelineId };
    if (scroll) {
      params["perPage"] = perPage;
      params["pageNo"] = pageNo;
    }
    try {
      const response = await getStageListWithPagination(params);
      if (
        response &&
        response.data &&
        response.data.stages &&
        type === "action"
      ) {
        setStageListForAction(response.data.stages);
      }
    } catch (err) {
      setStageListForAction([]);
    }
  };
  console.log(selectedPipelineId, selectedStageId, title, value, closingDate);
  return (
    <GlobalModal
      {...props}
      title="Assign To Stage"
      buttonText="Add"
      buttonIcon={<Save />}
      onSubmit={() => handleSubmit()}
      hideFooter={submittingData}
      className="manage-tag-modal modal-assign-campaign enhanceDataModal"
    >
      {submittingData && (
        <NewLoader
          loading={submittingData}
          onlyLoader={true}
          size={26}
          message={""}
        />
      )}

      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <Typography className="mb-1">Deal Title</Typography>
          <BasicInput
            name="title"
            placeholder="Enter title"
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography className="mb-1">Deal Value</Typography>
          <BasicInput
            name="value"
            placeholder="Enter Value"
            fullWidth
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 2 }} width={"100%"}>
          <Typography className="mb-1">Select Pipeline</Typography>
          <Select
            fullWidth
            labelId="pl-select-label"
            id="pl-select"
            name="pipeline"
            value={selectedPipelineId}
            displayEmpty
            onChange={(e) => {
              updatePipeline(e.target.value).then(() => {});
            }}
            input={<BootstrapInput />}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem
              className="dropdownhelper-menuitem-class"
              value={""}
              disabled
            >
              Select Pipeline
            </MenuItem>
            {pipelineList.map((pipelineListItem) => (
              <MenuItem
                className="dropdownhelper-menuitem-class"
                value={pipelineListItem.id}
              >
                {pipelineListItem.title}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {selectedPipelineId !== "" && (
          <Box sx={{ mt: 2 }} width={"100%"}>
            <Typography className="mb-1">Select Stage</Typography>
            <Select
              fullWidth
              labelId="stg-select-label"
              id="stg-select"
              name="stage"
              value={selectedStageId}
              displayEmpty
              onChange={(e) => {
                updateStage(e.target.value);
              }}
              input={<BootstrapInput />}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem
                className="dropdownhelper-menuitem-class"
                value={""}
                disabled
              >
                Select Stage
              </MenuItem>
              {stageListForAction.map((stageListItem) => (
                <MenuItem
                  className="dropdownhelper-menuitem-class"
                  value={stageListItem.id}
                >
                  {stageListItem.stage}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Typography className="mb-1">Closing Date</Typography>
          <BasicInput
            name="closingDate"
            type="date"
            placeholder="Enter title"
            fullWidth
            onChange={(e) => setClosingDate(e.target.value)}
          />
        </Box>
      </div>
    </GlobalModal>
  );
};

export default ModalListForStageAdd;
