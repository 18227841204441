import React, { useEffect, useState } from "react";
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import DraftsIcon from '@material-ui/icons/Drafts'; 
import { Button } from "@material-ui/core";
import { connect } from 'react-redux'
import { getSendMessageCredit } from "../../actions/mapAction";
import Utils from "../../helpers/Utils";


const mapDispatchToProps = dispatch => {
    return {
        getSendMessageCredit: (params, callback) => dispatch(getSendMessageCredit(params, callback)),
    };
}

const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63'] 
    && Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true :false

const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61'] 
    && Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true :false


const InstanceSendComponentApp = (props) => {
	// const [credits, setCredits] = useState([])
	// useEffect(() => {
	// 	props.getSendMessageCredit({}, (res) => {
	// 		setCredits(res)
	// 	})
	// }, [])

	// const renderCredit = () => {
	// 	let type = props.sendType
	// 	let filterData = credits.filter(item => item.type)
	// }

	return (
		<React.Fragment>
			<div className="instance__first_steps">
				{/* <div className="instance__header d-flex align-items-center justify-content-between">
					<h5>
						Mailing Type
						<span>Select Your mailing Type Below</span>
					</h5>
					<div className="credit_each d-flex align-items-center flex-column">
						${renderCredit()}
						<span>Credit Each</span>
					</div>
				</div> */}
				<div className="instance__body">
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'email' ? 'active' : ''}`} onClick={() => props.handleSendType('email')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<EmailIcon />
						</span>
						<span className="instance__text">Email</span>
					</div>
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'sms' ? 'active' : ''}`} onClick={() => props.handleSendType('sms')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<SmsIcon />
						</span>
						<span className="instance__text">SMS</span>
					</div>
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'voice' ? 'active' : ''}`} onClick={() => props.handleSendType('voice')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<MicIcon />
						</span>
						<span className="instance__text">Ringless Vmail</span>
					</div>
					
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'video' ? 'active' : ''}`} onClick={() => props.handleSendType('video')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<VideocamIcon />
						</span>
						<span className="instance__text">Video Email</span>
					</div>
					{thanksIoActive &&
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'postcard' ? 'active' : ''}`} onClick={() => props.handleSendType('postcard')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<PhotoLibraryIcon />
						</span>
						<span className="instance__text">Post Card</span>
					</div>
					}
					{thanksIoActive && 
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'greetingcard' ? 'active' : ''}`} onClick={() => props.handleSendType('greetingcard')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<CardGiftcardIcon />
						</span>
						<span className="instance__text">Greeting Card</span>
					</div>
					}
					{zenDirectActive && 
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'giftcard' ? 'active' : ''}`} onClick={() => props.handleSendType('giftcard')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<CardGiftcardIcon />
						</span>
						<span className="instance__text">Gift</span>
					</div>
					}
					{thanksIoActive &&
					<div className={`instance__item d-flex flex-column align-items-center justify-content-center ${props.sendType === 'letter' ? 'active' : ''}`} onClick={() => props.handleSendType('letter')}>
						<span className="instance__round_icon circle d-flex align-items-center justify-content-center">
							<DraftsIcon />
						</span>
						<span className="instance__text">Letter</span>
					</div>
					}
				</div>
			</div>
			<div className="instance__second_steps d-none">
				<p>Send a message from message templates that you have already created. </p>
				<Button className="instance__btn instance__gray_btn accent--bg--color">Choose template</Button>
			</div>
		</React.Fragment>
	);
}
const InstanceSendComponent = connect(null, mapDispatchToProps)(InstanceSendComponentApp);
export default InstanceSendComponent;