import { MenuItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useRef, useState } from 'react';
import "../../assets/global__send_modal.css";
import _ApiList from '../api/ApiList';
import CustomSelect from '../assets/components/CustomSelect';
import TimeSetting from '../assets/components/TimeSettings';
import '../assets/smsSend.css';
import { withOutCustomsFields } from '../assets/_constants';
import _Helper from '../assets/_Helper';
import _icons from '../assets/_icons';
import _label from '../assets/_label';
import _messages from '../assets/_messages';
import SmsTemplates from '../templates/SmsTemplates';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import DynamicSkeleton from '../../../map/contactDetailsV2/common/DynamicSkeleton';

const SmsSend = props => {

    const textArea = useRef(null)
    const vnSelectRef = useRef(null)
    const uploderInputRef = useRef(null);
    const previewRef = useRef(null);

    const [virtualNumbers, setVirtualNumbers] = useState([])
    const [selectedVn, setselectedVn] = useState('')
    const [fetchingVn, setFetchingVn] = useState(true)
    const [isValidVn, setIsValidVn] = useState(true)

    const [personalizedTag, setPersonalizedTag] = useState([])

    const [message, setMessage] = useState('')
    const [isValidMessage, setIsValidMessage] = useState(true)

    const [showTemplate, setShowTemplate] = useState(false)

    const [submitting, setSubmitting] = useState(false)
    const [nextStepMap, setNextStepMap] = useState(false)

    /* time setting data */
    const [sendTime, setSendTime] = useState('1') //1 = immediate, 2 = custom
    const [date, setDate] = useState(new Date())
    const [time, setTime] = useState(null)
    const [isValidTime, setIsValidTime] = useState(true)

    const [saveAsTemplate, setSaveAsTemplate] = useState(false)

    /* for mms */
    const [messageType, setMessageType] = useState('1') //2 for mms, 1 for sms

    const [selectedFile,setSelectedFile] = useState(null);

    useEffect(() => {
        _ApiList.getVittualNumbers().then(res => {
            setVirtualNumbers(res.data.virtual_number)
            if(res.data.virtual_number.length > 0){
                setselectedVn(res.data.virtual_number[0].value)
            }
            setFetchingVn(false)
        })
        let data = [];
        _ApiList.getPersonalizedTag().then(res => {
            let customFields = [];
            res.data.data.forEach((row) => {
                customFields.push({
                    label : row.title,
                    value : row.personalize_tag,
                });
            });
            data  = withOutCustomsFields.concat(customFields);

            let personalizedFields = [];
            personalizedFields.push({
                value: 'blue',
                label: 'User Customize Fields',
                color: '#cdcdcd',
                isDisabled: true
            });
            res.data.personalizedData.forEach((row) => {
                personalizedFields.push({
                    label : row.title,
                    value : row.personalize_tag,
                });
            });
            data  = data.concat(personalizedFields);
            setPersonalizedTag(data)
        })
    }, [])

    const handlePersonalizedSelect = (event) => {
        let start = textArea.current.selectionStart;
        let end = textArea.current.selectionEnd;
        let text = textArea.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event.target.value + after);
        setMessage(newText);
        textArea.current.selectionStart = textArea.current.selectionEnd = start + newText.length;
        textArea.current.focus();
    }

    const handleUseTemplate = (value) => {
        setShowTemplate(value)
    }

    const handleNextButton = () => {
        if(typeof props.from != 'undefined' && props.from == 'map'){
            if(selectedVn == ''){
                setIsValidVn(false)
                vnSelectRef.current.scrollIntoView({behavior: "smooth"});
                return;
            }

            if(message == ''){
                setIsValidMessage(false)
                textArea.current.scrollIntoView({behavior: "smooth"});
                return;
            }

            if(props.showTimeSetting){
                if(sendTime == '2' && time == null){
                    setIsValidTime(false)
                    return
                }
            }

            setNextStepMap(true)
        }
        else{
            if(selectedVn == ''){
                setIsValidVn(false)
                vnSelectRef.current.scrollIntoView({behavior: "smooth"});
                return;
            }

            if(message == ''){
                setIsValidMessage(false)
                textArea.current.scrollIntoView({behavior: "smooth"});
                return;
            }

            if(props.showTimeSetting){
                if(sendTime == '2' && time == null){
                    setIsValidTime(false)
                    return
                }
            }
            handleDefault()
        }
    }

    const handleTimesetting = (type, value) => {
        if(type === 'date'){
            setDate(value)
        }
        else if(type === 'time'){
            setTime(value)
        }
        else if(type === 'tab'){
            setSendTime(value)
        }
    }

    const handleSaveAsTemplate = (event) => {
        setSaveAsTemplate(event.target.checked)
    };

    const hanleFileChange = () => {
        setMessageType('2')
        let input_field = uploderInputRef.current;
        let preview_file = previewRef.current
        preview_file.src = URL.createObjectURL(input_field.files[0]);
        setSelectedFile(input_field.files[0]);
    }

    const handleRemoveImage = () => {
        setMessageType('1')
        let preview_file = previewRef.current
        preview_file.src = "";
        setSelectedFile(null);
    }

    const renderSelectedContact = () => {
        if(!props.showSelectedContact){
            return null;
        }
        return(
            <div className="selected-contact-container awesome__scroll_bar">
                {/* pass a element with proper design */}
                {props.selectedContactItem}
            </div>
        )
    }

    const renderTimeSetting = () => {
        if(!props.showTimeSetting){
            return null;
        }
        return(
            <div className="gloabal-send-module-time-setting-container">
                <TimeSetting
                    isValidTime={(!isValidTime && time == null)}
                    timeValidationMessage={'Date and Time is required'}
                    handleTimesetting={handleTimesetting}
                />
            </div>
        )
    }
    const renderMain = () => {
        if(showTemplate){
            return <SmsTemplates
                        handleSelect={(value) => {setMessage(value); setShowTemplate(false)}}
                        handleOnBack={() => setShowTemplate(false)}
                    />
        }
        return(
        <div className="global-sms-send-form">
            {fetchingVn ? 
                <>
                    <DynamicSkeleton count={1} /> 
                    <DynamicSkeleton count={1} height="80px"/> 
                    <DynamicSkeleton count={1} />
                    <DynamicSkeleton count={1} height="120px"/> 
                </>
                :
              <>
                {renderSelectedContact()}
    
                <div className="virtual-number-container d-flex align-items-start ds__row mb-2" ref={vnSelectRef}>
                    <p className="vn-label mr-3">{_label.vnTitle}</p>
                        <div className="vn-select-container">
                            <CustomSelect
                                formControlClass={'alt customFormControl border-none'}
                                id='gloabal-sms-send-vn-selector'
                                value={selectedVn}
                                handleChange={(event) => setselectedVn(event.target.value)}
                                placeholder={(<MenuItem value="" disabled>{_label.vnPlaceholder}</MenuItem>)}
                                item={virtualNumbers}
                                value_field={'value'}
                                titel_field={'label'}
                                customClass={'vn-select'}
                                modifyTitle={(title) => {
                                	return _Helper.formatPhoneNumber(title)
                                }}
                            />
                        </div>
                    {(!isValidVn && selectedVn === '') &&
                     <p style={{color: '#d00', fontSize: "13px"}}>{_messages.vnRequired}</p>
                    }
                </div>
    
                <div className="global_send_template v3">
                    <div className="template_topbar sms-template-options">
                        <CustomSelect
                            formControlClass={'alt customFormControl border-none'}
                            id='gloabal-sms-send-personalized-selector'
                            value={''}
                            handleChange={handlePersonalizedSelect}
                            placeholder={(<MenuItem value="" disabled>Personalized</MenuItem>)}
                            item={personalizedTag}
                            value_field={'value'}
                            titel_field={'label'}
                            customClass={'personalized-select'}
                            checkDisabledValue={true}
                            disabledIndex={'isDisabled'}
                        />
                        <div className="quick_reply_template">
                            <p onClick={() => handleUseTemplate(!showTemplate)} className="sms-send-module-template " >Template</p>
                        </div>
                        
                        <div className="file-field input-field upload_bitton alt sms-send-module-template ">
                            <div className="btn d-flex align-items-center">
                                {_icons.uploadIcon}											
                                <span className="ml-2">Upload Attachment <small style={{color: '#d00'}}>Image file only</small></span>
                                <input ref={uploderInputRef} type="file" id="add-quick-reply-mms-input" accept="image/png, image/gif, image/jpeg" onChange={hanleFileChange}/>
                            </div>
                        </div>
                    </div>
                    <textarea ref={textArea} className="message-content" value={message} placeholder="Enter message" onChange={(event) => setMessage(event.target.value)}>{message}</textarea>
                    {(!isValidMessage && message === '') &&
                        <p style={{color: '#d00', fontSize: "13px"}}>{_messages.messsgeRequired}</p>
                    }
                    <div className={`global__message_img_wrapper ${messageType == '2' ? '' : 'd-none'}`}>
                        <img alt="" ref={previewRef} className={`global__message_img ${messageType == '2' ? '' : 'd-none'}`} id="add-quick-reply-image-preview" src=""/>
                        <Button className="remove__icon" type="button" onClick={handleRemoveImage}><CloseIcon /></Button>
                    </div>
    
                    <FormControl className="v3__save_as_template" component="fieldset">
                        <FormControlLabel
                            control={<Checkbox checked={saveAsTemplate} onChange={handleSaveAsTemplate} name="save-as-template" />}
                            label="Save as a Quick Reply"
                        />
                    </FormControl>
                </div>
    
                {renderTimeSetting()}
    
                <div className="d-flex justify-content-end modal__footer_btn mt-2">
                 <button className="accent--bg--color" style={{color: '#FFF'}} onClick={handleNextButton}>{(typeof props.from != 'undefined' && props.from === 'map') ? 'Next' : submitting ? 'Sending' : 'Send'}</button>
                </div>
              </>
            }
        </div>
        
        )
    }

    const renderNextStepMap = () => {
        if(props.from === 'map'){
            return(
                <div>
                    <h5>Order Summary</h5>
                    <p>Below is a summary of your order. Click Send to confirm.</p>
                    <p>Sending to {props.totalContacts} address(es)</p>

                    <table>
                        <tr><th>Item</th><th>Sending</th></tr>
                        <tr><td>SMS</td><td>{sendTime === '2'? `Scheduled` : 'Immediately'}</td></tr>
                    </table>

                    <h6>Cost Summary</h6>
                    <table>
                        <tr><th>Per Send Cost</th><th>Number of recipients</th><th>Estimated Total Cost</th></tr>
                        <tr><td>{props.perSmsPrice}</td><td>{props.totalContacts}</td><td>{props.perSmsPrice*props.totalContacts}</td></tr>
                    </table>

                    <div className="d-flex align-items-center justify-content-center py-3">
                        <button className="sendModulesButton cancelButton" onClick={props.onClose}>Cancel</button>
                        <button className="sendModulesButton submitButton" onClick={handleSubmit}>{submitting ? <MoreHorizIcon fontSize="small"/> : ''} Confirm & Send</button>    
                    </div>		
                </div>
            )
        }
        return null;
    }

    /* submit functions */
    const handleSubmit = () => {
        if(props.from === 'map'){
            handleMapSubmit()
        }
    }
    const handleMapSubmit = () => {
        if(submitting)return;
        setSubmitting(true);
        if (messageType === 1) {
            let data = {
                type: 2,
                subject: '',
                origin: 6,
                message: message,
                virtualNumberId: selectedVn,
                schedule_type: parseInt(sendTime),
                date: sendTime === '2' ? date : null,
                time: time,
                message_type: messageType,
                saveTemplate : saveAsTemplate
            }
            _ApiList.submitSendFromMap(data).then(res => {
                setSubmitting(false)
                let response = res.data
                if(response.status == 'error'){
                    window.showNotification("ERROR","Something went wrong")
    
                }
                else{
                    window.showNotification("SUCCESS","SMS Sent successfully")
                    if(props.onSubmit !== undefined){
                        props.onSubmit()
    
                    }
                    if(props.onClose !== undefined){
                        props.onClose()
    
                    }
                }
            })
        } else {
            let formData = new FormData();
            formData.append('message_type', messageType);
            formData.append('saveTemplate', saveAsTemplate);
            formData.append('message', message);
            formData.append('virtual_number_id', selectedVn);
            formData.append('origin', 6);
            formData.append('schedule_type', sendTime);
            formData.append('date', sendTime === 2 ? moment(date).format('YYYY-MM-DD') : null);
            // formData.append('date', sendTime == 2 ? date : null);
            formData.append('time', time);
            formData.append('qqfile', selectedFile);

            _ApiList.submitSendFromMap(formData, true).then(res => {
                setSubmitting(false)
                let response = res.data
                if(response.status === 'error'){
                    window.showNotification("ERROR","Something went wrong")
                }
                else{
                    window.showNotification("SUCCESS","SMS Sent successfully")
                    if(props.onClose !== undefined){
                        props.onClose();
                    }
                    if(props.onSubmit !== undefined){
                        props.onSubmit();
    
                    }
                }
            })

        }
    }

    const handleDefault = () => {
        if(submitting)return;
        setSubmitting(true)
        if(messageType === 1){
            let data = {
                contact_id: props.contact_id,
                date: sendTime === '2' ? date : null,
                message: message,
                message_type: messageType,//1 for sms, 2 for mms
                origin: 6,
                saveTemplate: saveAsTemplate,
                schedule_type: parseInt(sendTime),
                time: time,
                virtual_number_id: selectedVn
            }
            _ApiList.submitSendDefault(data).then(res => {
                setSubmitting(false)
                let response = res.data
                if(response.status === 'error'){
                    window.showNotification("ERROR","Something went wrong")
                }
                else{
                    window.showNotification("SUCCESS","SMS Sent successfully")
                    if(props.onClose !== undefined){
                        props.onClose()
                    }
                    if(props.onSubmit !== undefined){
                        props.onSubmit()
    
                    }
                }
            })
        }
        else{
            let formData = new FormData();
            formData.append('message_type', messageType);
            formData.append('saveTemplate', saveAsTemplate);
            formData.append('message', message);
            formData.append('virtual_number_id', selectedVn);
            formData.append('origin', 6);
            formData.append('schedule_type', sendTime);
            // formData.append('date', sendTime == 2 ? moment(date).format('YYYY-MM-DD') : null);
            formData.append('date', sendTime === 2 ? date : null);
            formData.append('time', time);
            formData.append('contact_id', props.contact_id);
            formData.append('qqfile', uploderInputRef.current.files[0]);

            _ApiList.submitSendDefault(formData, true).then(res => {
                setSubmitting(false)
                let response = res.data
                if(response.status === 'error'){
                    window.showNotification("ERROR","Something went wrong")
                }
                else{
                    window.showNotification("SUCCESS","SMS Sent successfully")
                    if(props.onClose !== undefined){
                        props.onClose()
                    }
                    if(props.onSubmit !== undefined){
                        props.onSubmit()
    
                    }
                }
            })

        }        
    }

    return(
        <div className="global-sms-send-module global__send_modal_wrapper global-send-overflow-y"style={{minHeight:"700px"}}>
            {!nextStepMap ? 
                renderMain()
                :
                renderNextStepMap()
            }
        </div>
    )
}
export default SmsSend;



/* 
props:
-----------
showNotification(message, type) //for notification alert
from: map,
showTimeSetting: true, false

showSelectedContact : true, false
selectedContactItem: an element with design

contact_id: '' required if from is not 'map'

*/