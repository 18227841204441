// import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
/* icons */
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _Helper from "../assets/Helper";
import icons from "../assets/icons";
import { getContactDetails, _updateStateField } from "../redux/contactDetailAction";
import { updateContactFieldApi } from "../redux/contactDetailApi";
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';



const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params)),
    getContactDetails : (params, callback) => dispatch(getContactDetails(params, callback)),
})

const BasicInfoApp = (props) => {
    const {
        contactId, contactDetails, fetchingContactDetail, deletingContact,
        showingSmsSendModal, showingEmailSendModal, showingUpdateContactModal,
        reloadCotactDetails
    } = props.contactDetailReducer

    const [contactData, setContactData] = useState({
        number: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zip: '',
    })

    useEffect(() => {
        if(JSON.stringify(contactDetails) != '{}'){
            setContactData({
                ...contactData, 
                ...{
                    number: contactDetails.number,
                    email: contactDetails.email,
                    address: contactDetails.address,
                    city: contactDetails.city,
                    state: contactDetails.state,
                    country: contactDetails.country,
                    zip: contactDetails.zip
                }
            })   
        }
    }, [contactDetails])

    useEffect(() => {
        if(reloadCotactDetails){
            props._updateStateField({reloadCotactDetails: false})

            /* call api */
        }
    }, [reloadCotactDetails])

    const handleUpdateContactModal = (status) => {
        props._updateStateField({showingUpdateContactModal: status})
    }

    const handleSendModal = (modalType) => {
        if(modalType == 'sms'){
            if(_Helper.isEmpty(contactDetails.number)){
                window.showNotification('ERROR','Can not send sms. This contact has no number')
                return
            }
            props._updateStateField({showingSmsSendModal: true})
        }
        else if(modalType == 'email'){
            if(_Helper.isEmpty(contactDetails.email)){
                window.showNotification('ERROR','Can not send email. This contact has no email address')
                return
            }
            props._updateStateField({showingEmailSendModal: true})
        }
        else if(modalType == 'voice'){
            if(_Helper.isEmpty(contactDetails.number)){
                window.showNotification('ERROR','Can not send voice mail. This contact has no number')
                return
            }
            props._updateStateField({showingVoiceSendModal: true})
        }
    }

    return (
        <div className="d-flex contact__info_wrapper ColGap20px">

            {(fetchingContactDetail || JSON.stringify(contactDetails) == '{}') &&
            <div className="empty-container">
                Please wait...
            </div>
            }

            {(!fetchingContactDetail && JSON.stringify(contactDetails) != '{}') &&
            <>
            <div className="left_contact contactItem">
                <h5 className="contact__info_title">Basic Info</h5>
                <ul className="contact__info_lists alter">
                    <EachItem
                        data={contactData.number}
                        icon={icons.blackCallIcon}
                        field={'number'}
                        title="Add Number"
                        checkValidation={(value) => {
                            let invalid = ''
                            if(/^\d+$/.test(value)){
                                if(!_Helper.validateMobileNumber(value)){
                                    invalid = 'Invalid number'
                                }
                            }
                            else{
                                invalid = 'Invalid number'
                            }
                            return invalid;
                        }} 
                        updateFieldData={(value) => {
                            setContactData({...contactData, ...{number: value}})
                        }}
                    />

                    <EachItem
                        data={contactData.email}
                        icon={icons.blackEnvIcon}
                        field={'email'}
                        title="Add Email"
                        checkValidation={(value) => {
                            let invalid = ''
                            if(!_Helper.validateEmail(value)){
                                invalid = 'Invalid email'
                            }
                            return invalid;
                        }}
                        updateFieldData={(value) => {
                            setContactData({...contactData, ...{email: value}})
                        }}
                    />

                    <li className="d-flex align-items-center">
                        <span>{icons.mapMarkerIcon}</span>
                        {_Helper.isEmpty(contactData.address) ? 'Add Address' : contactData.address}
                        <span className="each-item-icon" onClick={()=> handleUpdateContactModal(true)}>
                        {_Helper.isEmpty(contactData.address) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>
                </ul>
                <ul className="contact__info_lists alt">
                    <li className="d-flex align-items-center" onClick={() => handleSendModal('sms')}><span>{icons.SmsIcon}</span></li>
                    <li className="d-flex align-items-center" onClick={() => handleSendModal('email')}><span>{icons.blackEnvIcon}</span></li>
                    <li className="d-flex align-items-center" onClick={() => handleSendModal('voice')}><span><KeyboardVoiceIcon fontSize="small" /></span></li>
                    {/* <li className="d-flex align-items-center"><span>{icons.mapMarkerIcon}</span></li> */}
                    <li className="d-flex align-items-center"><Link to={`/contacts/${contactId}`} target="_blank"><span>Go to Profile</span></Link></li>
                </ul>
            </div>
            <div className="right_contact contactItem">
                <h5 className="contact__info_title">Additional Info</h5>
                <ul className="contact__info_lists alter">
                    <li className="d-flex align-items-center">
                        <span>{icons.mapMarkerIcon}</span>
                        {_Helper.isEmpty(contactData.city) ? 'Add City' : contactData.city}
                        <span className="each-item-icon" onClick={()=> handleUpdateContactModal(true)}>
                        {_Helper.isEmpty(contactData.city) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="d-flex align-items-center">
                        <span>{icons.mapMarkerIcon}</span>
                        {_Helper.isEmpty(contactData.state) ? 'Add State' : contactData.state}
                        <span className="each-item-icon" onClick={()=> handleUpdateContactModal(true)}>
                        {_Helper.isEmpty(contactData.state) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="d-flex align-items-center">
                        <span>{icons.mapMarkerIcon}</span>
                        {_Helper.isEmpty(contactData.country) ? 'Add Country' : contactData.country}
                        <span className="each-item-icon" onClick={()=> handleUpdateContactModal(true)}>
                        {_Helper.isEmpty(contactData.country) ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>

                    <li className="d-flex align-items-center">
                        <span>{icons.mapMarkerIcon}</span>
                        {_Helper.isEmpty(contactData.zip) ? 'Add zip' : contactData.zip}
                        <span className="each-item-icon" onClick={()=> handleUpdateContactModal(true)}>
                        {_Helper.isEmpty(contactData.zip)  ? <AddIcon /> : <EditIcon />}
                        </span>
                    </li>
                </ul>
            </div>
            </>
            }
        </div>
    );
}
const BasicInfo = connect(mapStateToProps, mapDispatchToProps)(BasicInfoApp);
export default BasicInfo;


const EachItemApp = (props) => {
    const [edit, setEdit] = useState(false)
    const [submitEditing, setSubmitEditing] = useState(false)
    const [text, setText] = useState(props.data)

    useEffect(() => {
        setText(props.data)
    }, [])
  
    const handleEditSubmit = () => {
        if(props.checkValidation != undefined){
            let validation = props.checkValidation(text)
            if(validation != ''){
            window.showNotification('ERROR',validation)
            return
            }
        }
        setSubmitEditing(true)
        let formData = {
            'contact_id': props.contactDetailReducer.contactId,
            'text': text,
            'field' : props.field
        }
        updateContactFieldApi(formData).then(res => {
            let response = res.data
            if(response.status == 'success'){
                props.updateFieldData(text)
                props.getContactDetails({
                    id: props.contactDetailReducer.contactId
                })
                setEdit(false)
            }
            else{
                if(response.status == 'validation-error'){
                    let msg = response.html.number != undefined ? response.html.number[0] : (response.html.email != undefined ? response.html.email[0] : '')
                    if(msg != ''){
                        window.showNotification('ERROR',msg)
                    }
                }
                else if(response.status == 'error'){
                    window.showNotification('ERROR',response.html)
                }
            }
            setSubmitEditing(false)
        })
    }
  
    let icon = (props.data === undefined || props.data== null || props.data.trim() ==='') ? <AddIcon /> : <EditIcon />
  
    const handleCloseEdit = () => {
      setText(props.data)
      setEdit(false)
    }
    
    return(
      <>
        <li className="d-flex align-items-center">
            <span>{props.icon}</span> 
            {!edit &&
            <>
            {(props.data === undefined || props.data== null || props.data.trim() ==='') ? props.title : props.data}
            <span className="each-item-icon" onClick={() => setEdit(!edit)}>{icon}</span>
            </>
            }
            {edit &&
            <div className="each-item-edit">
                <input type="text" defaultValue={props.data} onChange={(event) => setText(event.target.value)} />
                {!submitEditing && 
                <>
                <span onClick={handleEditSubmit}><CheckIcon /></span>
                <span onClick={handleCloseEdit}><CloseIcon /></span>
                </>
                }
    
                {submitEditing && 
                <span ><MoreHorizIcon fontSize="small" /></span>
                }
            </div>
            }
        </li>
      </>
    )
}
export const EachItem = connect(mapStateToProps, mapDispatchToProps)(EachItemApp);
  