import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Call as CallIcon,
  CheckBox as CheckBoxIcon,
  PhoneDisabled as PhoneDisabledIcon,
} from "@material-ui/icons";
import { BasicInputSelect, ContactDetailsStyle } from "../ContactDetailsStyle";
import ValidationModal from "./ValidationModal";
import Icons from "../../Icons";

const useStyles = makeStyles({
  checkDNC: {
    color: "black !important",
    background: "rgb(0, 255, 145) !important",
    padding: "1px 14px !important",
    borderRadius: "20px !important",
    "&:hover , &:focus": {
      background: "rgb(0, 255, 145) !important",
    },
  },
});

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) {
    return "N/A";
  }

  if (phoneNumberString.length < 11) {
    phoneNumberString = "1" + phoneNumberString;
  }
  return phoneNumberString
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
};

const EachPhoneNumber = ({
  number,
  dnc = false,
  markerContactDetails,
  handleCloseModal,
  skipTrace,
}) => {
  const [phoneType, setPhoneType] = useState(1);
  const { titleIcon, flexCenter, primaryButtonRounded } = ContactDetailsStyle();
  const [validatePhoneNumber, setValidatePhoneNumber] = useState(false);
  const [checkDNC, setCheckDNC] = useState(false);
  const [openValidationModal, setOpenValidationModal] = React.useState(false);
  const [dncStatus, setDncStatus] = useState("NOT_VERIFIED");
  const [showValidationIconForPhone,setShowValidationIconForPhone]=useState("NOT_VALID")

  const handleOpenValidationModalForPhone = () => {
    setOpenValidationModal(true);
    setValidatePhoneNumber(true);
    setCheckDNC(false);
  };
  const handleOpenValidationModalForDNC = () => {
    setOpenValidationModal(true);
    setCheckDNC(true);
    setValidatePhoneNumber(false);
  };

  const handleCloseValidationModal = () => {
    setOpenValidationModal(false);
  };
  const handlePhoneType = (value) => {
    setPhoneType(value);
  };
  
  const handleMakeCall = () => {
    if (window.initiateGlobalDialer){
        window.initiateGlobalDialer({
            dialToNumber : true,
            number : number
        });
    }
}

  const classes = useStyles();
  return (
    <Box className={flexCenter} sx={{ gap: 10 }}>
      <Box className={flexCenter} sx={{ gap: 10, flexWrap: "wrap" }}>
        <Box>
          <Button
            className={primaryButtonRounded}
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            startIcon={<CallIcon />}
            onClick={handleMakeCall}
          >
            <Typography style={{ fontSize: ".7vw" }}>
              {" "}
              {formatPhoneNumber(number)}
            </Typography>
          </Button>
        </Box>

        <Box sx={{ flex: 1 }}>
          <FormControl fullWidth>
            <Select
              style={{ width: "150px" }}
              labelId="vn-select-label"
              id="vn-select"
              name="virtual_number_id"
              value={phoneType}
              displayEmpty
              onChange={(e) => {
                handlePhoneType(e.target.value);
              }}
              input={<BasicInputSelect />}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className="dropdownhelper-menuitem-class" value={1}>
                Mobile
              </MenuItem>
              <MenuItem className="dropdownhelper-menuitem-class" value={2}>
                Landline
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box className={flexCenter} sx={{ gap: 7 }}>
        {dncStatus === "DO_NOT_CALL" ? (
          <IconButton disableElevation> {Icons.doNotCall}</IconButton>
        ) : dncStatus === "VERIFIED_OK" ? (
          <IconButton disableElevation> {Icons.verifiedOk}</IconButton>
        ) : (
          <Button
            disableElevation
            className={classes.checkDNC}
            variant="contained"
            onClick={handleOpenValidationModalForDNC}
          >
            {" "}
            Check DNC
          </Button>
        )}

        {showValidationIconForPhone === "VALID" ? (
          <IconButton disableElevation> {Icons.valid}</IconButton>
        ) :  (
          <Button
            disableElevation
            className={classes.checkDNC}
            variant="contained"
            onClick={handleOpenValidationModalForPhone}
          >
            {" "}
            Validate
          </Button>
        )}
      </Box>

      <ValidationModal
        setDncStatus={setDncStatus}
        skipTrace={skipTrace}
        handleCloseModal={handleCloseModal}
        validatePhoneNumber={validatePhoneNumber}
        handleClose={handleCloseValidationModal}
        openValidationModal={openValidationModal}
        markerContactDetails={markerContactDetails}
        checkDNC={checkDNC}
        phoneNumber={number}
        setShowValidationIconForPhone={setShowValidationIconForPhone}
      />
    </Box>
  );
};

export default EachPhoneNumber;
