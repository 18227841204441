import React from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";

const PropertyAddress = (props) => {
    console.log()
    const { flexCenterBetween, flexCenter, secondaryButtonRounded, textDarkBlue, titleIcon, textBold600 } = ContactDetailsStyle();
    const makeAddress = () => {
        let address = "";

        if (props.contactdata?.address){
            address += props.contactdata?.address + "<br/>";
        }

        if (props.contactdata?.city){
            address += props.contactdata?.city;
        }

        if (props.contactdata?.state){
            if (address !== ""){
                address += ", ";
            }

            address += props.contactdata?.state;
        }

        if (props.contactdata?.zip){
            if (address !== ""){
                address += " ";
            }

            address += props.contactdata?.zip;
        }

        return address;
    };
    return (
      <Box>
        <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
          <CustomTitle
            title={"Property Address"}
            sx={{ display: "flex", flex: 1, alignItems: "center" }}
          />
        </Box>

        <Paper variant="outlined" elevation={0} style={{ padding: 15 }}>
          {props.contactdata ? (
            <Box
              className={flexCenterBetween}
              sx={{
                alignItems: "normal !important",
                flexWrap: "wrap",
                gap: 15,
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{ height: "auto" }}
                  className={`${textDarkBlue} ${textBold600}`}
                  dangerouslySetInnerHTML={{ __html: makeAddress() }}
                ></Typography>
              </Box>
            </Box>
          ) : (
            <Typography>No Data Found</Typography>
          )}
        </Paper>
      </Box>
    );
};

export default PropertyAddress;