import { Box, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from "reactstrap";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: "20px",
      textAlign: "center",
      maxWidth: "650px",
      borderRadius: '6px',
      outline: 0,
      position: "relative",
    },
    pinpoint_logo: {
        border: "1px solid #333",
        borderRadius: '4px',
        padding: "10px",
        marginTop: "-60px",
        maxWidth: "120px",
        backgroundColor: "#fff"
    },
    pinpoint_title: {
        fontSize: "36px",
        lineHeight: "42px",
        marginTop: "30px",
        marginBottom: "15px",
        fontWeight: "500"
    },
    pinpoint_subtitle: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400"
    },
    pinpoint_buttons: {
        marginTop: "20px",
        "& button": {
            margin: "10px",
            padding: "5px 15px",
            textTransform: "capitalize",
            borderRadius: "4px",
            height: "auto",
            fontWeight: "500",
            boxShadow: "unset",
            "&:hover": {
                boxShadow: "unset",
            }
        }
    },
    pinpoint_close_button: {
        position: "absolute",
        top: "-10px",
        right: "-10px",
        background: "#fff",
        padding: "0",
        border: "1px solid #ddd",
        "&:hover, &:focus" : {
            background: "#fff",
            color: "#f00",
        }
    }
  }));


const InformationPopup=(props)=>{
    const classes = useStyles();

    return (
        <Modal
            open={true}
            onClose={()=>props.onClose()}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className={classes.modal}
            BackdropProps={{
                timeout: 1000,
            }}
            onBackdropClick ={()=>props.onClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
        >
        <Fade in={true}>
            <div className={classes.paper}>
                <IconButton className={classes.pinpoint_close_button}>
                    <HighlightOffIcon fontSize="large" onClick={()=>props.onClose()}/>
                </IconButton>
                <img className={classes.pinpoint_logo} src="https://pypepro-static-resources.s3.amazonaws.com/asset/images/releases/1.0.0/mappopupimage.png" alt="Map Pin"/>
                <Typography variant="h3" className={classes.pinpoint_title}>Pinpoint your ideal Prospects</Typography>
                <Typography className={classes.pinpoint_subtitle}>Geographic farming is a proven method of marketing Your business to a specific geographic region to raise awareness and generate  leads</Typography>

                <div className={classes.pinpoint_buttons}>
                    <Link to="/geo-farming-user" target={`_blank`}><Button variant="contained" color="primary">Learn More</Button></Link>
                    <Button variant="contained" color="secondary" onClick={()=>{props.onClose(); props.routeLink('/map/integration'); }}>Buy Subscription</Button>
                </div>

            </div>
        </Fade>
        
    </Modal>
    );
}

export default InformationPopup;