import React, {useContext, useState} from "react";
import { Box, Typography, Paper, Button, makeStyles } from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import EachPhoneNumber from "../../common/EachPhoneNumber";
import {ContactDetailsV2Context} from "../../ContactDetailsV2Reducer";
import ValidationModal from "../../common/ValidationModal";
const useStyles=makeStyles({
  checkDNC:{
    color:'black !important',
    background:'rgb(0, 255, 145) !important',
    padding:'1px 14px !important',
    borderRadius:'20px !important',
    "&:hover , &:focus":{
        background:'rgb(0, 255, 145) !important',
    }
}
})

const ContactInfo = (props) => {
    const {state, dispatch} = useContext(ContactDetailsV2Context);
    const { flexCenterBetween, flexCenter, textDarkBlue, textBlueMapContactColor, textBold600 ,checkDNC} = ContactDetailsStyle();
    const [isOpen,setIsOpen]=useState(false)
    const [isContact,setIsContact]=useState(true)
    const [validateEmail,setValidateEmail]=useState(false)
    const [openValidationModal, setOpenValidationModal] = React.useState(false);
    const handleOpenValidationModal = () => {
      setOpenValidationModal(true);
      setValidateEmail(true)
      };
    
      const handleCloseValidationModal = () => {
        setOpenValidationModal(false);
      };

    const makeFullname = () => {
        let fullname = "";

        if (state.firstName){
            fullname += state.firstName;
        }

        if (state.lastName){
            if (fullname){
                fullname += " ";
            }

            fullname += state.lastName;
        }

        return fullname;
    };

    const makeEmail = () => {
      let email = "";

      if (state.email){
          email = "Email: ";
          email += state.email;
      }else{
          email = "Email: N/A";
      }

      return email;
    };

  




    const classes=useStyles()
    return (
      <Box sx={{width:'100% !important',padding:'0px',gap: '0px !important',display:'block'}}>
        <Box className={flexCenter} sx={{width:'100% !important'}} mb={1}>
          <CustomTitle
            title={"Contact Info"}
            sx={{ display: "flex", flex: 1, alignItems: "center",border:' none !important' ,width:'100% !important',padding:0}}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isContact={isContact}
          />
        </Box>
          <Paper variant="outlined" elevation={0} style={{ padding: 15 }}>
            <Box
              className={flexCenterBetween}
              sx={{
                alignItems: "normal !important",
                flexWrap: "wrap",
                gap: 15,
                position:'relative',
                top:'unset',
                right:'unset'
              }}
            >
              <Box display={'block !important'}>
                <Typography
                  variant="body1"
                  style={{ height: "auto" }}
                  className={`${textBlueMapContactColor} ${textBold600}`}
                >
                  {makeFullname()}
                </Typography>
                {state.email && (
                  <Box sx={{ display: "flex", gap: 10 }}>
                    <Typography
                      variant="body1"
                      className={`${textBlueMapContactColor} ${textBold600}`}
                    >
                      {makeEmail()}
                    </Typography>
                    <Button
                      disableElevation
                      className={classes.checkDNC}
                      variant="contained"
                      onClick={handleOpenValidationModal}
                    >
                        Validate
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            {state.number && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  mt: 2,
                  paddingTop:'0px !important'
                }}
              >
                <EachPhoneNumber
                  number={state.number}
                  status={state.numberValidationStatus}
                  dnc={state.dncStatus}
                  markerContactDetails={props.markerContactDetails}
                  handleCloseModal={props.handleCloseModal}
                />
              </Box>
            )}
          </Paper>
        <ValidationModal handleCloseModal={props.handleCloseModal} validateEmail={validateEmail} handleClose={handleCloseValidationModal} openValidationModal={openValidationModal} markerContactDetails={props.markerContactDetails}/>
      </Box>
    );
};

export default ContactInfo;