import {sendRequest, sendRequestBackEnd} from './rootApi';
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const fetchMapFinderData = async (payload) => {
    return sendRequest("POST", Api.mapFinder, payload);
}

export const loadAllContactsInMap = async (payload) => {
    return sendRequest("POST", Api.loadAllContactsInMap, payload);
}

export const getMapDataWithDisposition = async (payload) => {
    return sendRequest("POST", Api.getMapDataWithDisposition, payload);
}

export const getTagList = async (payload) => {
    return sendRequest("post", Api.tagList, payload);
}


export const loadContactsByTag = async (payload) => {
    return sendRequest("post", Api.loadContactsByTagForMap, payload);
}

export const getSingleContactFromMap = async (payload) => {
    return sendRequest("post", Api.fetchSingleContactForMap, payload);
}

export const getAllUserCampaign = async (payload) => {
    return sendRequest("post", Api.fetchCampaignList, payload);
}

export const saveContactFromMapSidebar = async (payload) => {
    return sendRequest("post", Api.saveContactFromMapSidebar, payload);
}

export const submitMapFinderContactCampaignSubmit = async (payload) => {
    return sendRequest("post", Api.mapLeadFinderProcessCampaignSubmit, payload);
}

export const mapLeadProcessAction= async (payload)=>{
    return sendRequest("post", Api.mapLeadFinderProcessAction, payload);
}

export const mapOrderNameEdit= async (payload)=>{
    return sendRequest("post", Api.mapOrderTitleEditFormSubmit, payload);
}

export const getSendMessageCreditApi= async (payload)=>{
    return sendRequest("post", Api.getSendMessageAllCredits, payload);
}

export const fetchUserDispositions= async (payload)=>{
    return sendRequest("post", Api.getUserDispositions, payload);
}

export const fetchContactsWithSpecificDisposition= async (payload)=>{
    return sendRequest("post", Api.fetchContactsWithSpecificDisposition, payload);
}
export const fetchContactInfoByLatlng= async (payload)=>{
    return sendRequest("post", Api.fetchContactInfoByLatlng, payload);
}

export const checkMapPackageExist= async (payload)=>{
    return sendRequest("post", Api.checkMapPackageExist, payload);
}

export const changeContactDisposition= async (payload)=>{
    return sendRequest("post", Api.changeContactDisposition, payload);
}

export const cleanContactsActionsApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.cleanContactsActions, payload);
}

export const getUserMysPackageStatus = async () => {
    return HttpRequest.get(Api.getUserMysPackageStatus);
}