import React, { createContext } from "react";
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import Utils from "./helpers/Utils";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});

function App({ history = defaultHistory }) {

    const generateClassName = createGenerateClassName({
        productionPrefix: 'map_' + Utils.generateRandomString(5),
    });

    return (
        <HistoryProvider.Provider value={history}>
            <StylesProvider generateClassName={generateClassName}>
                <Router history={history}>
                    <Routes history={history} />
                </Router>
            </StylesProvider>
        </HistoryProvider.Provider>
    );
}

export default App;

