import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {getVideoFolders} from "./redux/videoEmailAction";
import {MenuItem, Select} from "@material-ui/core";

const LikeVideo = props => {
    const [title, setTitle] = useState((props.videoObj && props.videoObj.title) ? props.videoObj.title : '');
    const [description, setDescription] = useState((props.videoObj && props.videoObj.description) ? props.videoObj.description : '');
    const [folderId, setFolderId] = useState((props.videoObj && props.videoObj.campaign_video_folder_id) ? props.videoObj.campaign_video_folder_id : '');
    const [folders, setFolders] = useState(props.videoFolders);

    useEffect(() => {
        if (props.videoFolders == null) {
            props.getVideoFolders();
        } else {
            setFolders(props.videoFolders)
        }
    }, [props.videoFolders]);

    const handleSubmit = () => {
        props.selectVideo({
            title: title,
            description: description,
            folderId : folderId
        });
    }

    const renderFolderOptions = () => {
        let folderOptions = []
        if (folders != null) {
            folders.map(function(data, index){
                folderOptions.push(
                    <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                )
            });
        }

        return folderOptions;
    }

    return (
        <div>
            <div className="likeArea">
                {/* <If condition={this.props.videoSection != 2}> */}
                    <input name="title" autofocus onChange={(e) => setTitle(e.target.value)} defaultValue={title} placeholder="* Type your video title"/>
                    <textarea name="description" onChange={(e) => setDescription(e.target.value)} defaultValue={description} placeholder="* Type a short description"></textarea>
                    <Select name="folderId" defaultValue={folderId} onChange={(e) => setFolderId(e.target.value)}>
                        <MenuItem value=''>Default Folder</MenuItem>
                        {renderFolderOptions()}
                    </Select>
                {/* </If> */}

                <div className="buttons">
                    <div className="button-set" id="button-set-select-video" onClick={() => handleSubmit()}>
                        <CheckCircleOutlineIcon color='primary' fontSize='large'/>
                        <p>Yes</p>
                    </div>
                    <div className="button-set" onClick={() => props.selectVideo(0)}>
                        <HighlightOffIcon color='primary' fontSize='large'/>
                        <p>No</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        videoFolders: state.inboxReducer.videoFolders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getVideoFolders: () => dispatch(getVideoFolders())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeVideo);