import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/mapActionTypes';
import {getTagList} from "../api/mapApi";



function* getContactTagWatcher() {
    yield takeEvery(ACTION.GET_TAG_LIST, attemptGetContactTag)
}

function* attemptGetContactTag(action) {
    const { payload } = action;

    try {
        const response = yield call(getTagList, payload.payload);
        let responseData = response.data;
        console.log(responseData)
        if (response.status === 200) {
            yield put({type: ACTION.SET_TAG_LIST, payload: responseData});
        } else {
        }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }

}

export default function* tagMiddleware() {
    yield all([
        getContactTagWatcher()
    ])
}