import react from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';



export const LastSendStep=(props)=>{


    return (
        <div>
					<div className="order_summery_topbar">
					    <h5>Order Summary</h5>
					    <p>Below is a summary of your order. Click Send to confirm.</p>
					    <p>Sending to {props.totalContacts} address(es)</p>
					</div>

                    <table>
                        <tr><th>Item</th><th>Sending</th></tr>
                        <tr><td>SMS</td><td>{props.states.sendTime === '2'? `Scheduled` : 'Immediately'}</td></tr>
                    </table>

                    <h6>Cost Summary</h6>
                    <table>
                        <tr><th>Per Send Cost</th><th>Number of recipients</th><th>Estimated Total Cost</th></tr>
                        <tr><td>{props.cost}</td><td>{props.totalContacts}</td><td>{props.cost*props.totalContacts}</td></tr>
                    </table>
                    
                    {(props.from !== undefined && props.from != null && props.from !== 'direct-mail') &&
                    <div className="d-flex align-items-center justify-content-center py-3">
                        <button className="sendModulesButton cancelButton" onClick={props.onClose}>Cancel</button>
                        <button className="sendModulesButton submitButton" onClick={props.handleSubmit}>{props.states.sending ? <MoreHorizIcon fontSize="small"/> : ''} Confirm & Send</button>    
                    </div>
                    }		
                </div>
    )
}