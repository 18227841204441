import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as API from './contactDetailApi';
import * as ACTION from "./contactDetailActionTypes";

function* contactDetailWatcher() {
    yield takeEvery(ACTION.FETCH_CONTACT_DETAILS, getCotactDetailMiddleware);

}

function* getCotactDetailMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(API.getContactDetailsApi, payload.payload);
        yield put({type: ACTION.UPDATE_CONTACT_DETAILS, payload: response.data});
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        console.log("---error from api---",error)
    }
}

export default function* contactDetailMiddleware() {
    yield all([
        contactDetailWatcher()
    ])
}
