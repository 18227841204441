import React, { Component } from "react";
import { Save, SearchOutlined } from "@material-ui/icons";
import { icons } from "./icon";
import GlobalModal from "../../globals/Modal/GlobalModal";
import NewLoader from "../../Common/NewLoader";
import { getContactListForDropdown } from "../../../api/enhanceData";
import { saveContactFromMapSidebar } from "../../../api/mapApi";
import "./enhancedDataActionList.css";

const ListNotFound = () => {
  return (
    <div>
      <div className="no-results">
        <span className="icon text-danger">
          <i className="la la-exclamation-triangle"></i>
        </span>
        <span className="text text-danger">Sorry no results found</span>
      </div>
    </div>
  );
};

const EachList = (props) => {
  const checkSelect = (id) => {
    return !!props.selectedLists.includes(id);
  };

  if (
    (props.data.contact_ids !== undefined &&
      props.data.contact_ids != null &&
      props.data.contact_ids.length > 0) ||
    (props.data.filter_params.operation ===
      '[{"filter":{},"sortField":"id","sortOrder":"desc"}]' &&
      props.data.filter_params.columnFilterData === "{}" &&
      props.data.filter_params.columnFilterData === "{}")
  ) {
    return (
      <li>
        <div className="custom-checkbox-wrapper">
          <span
            className="custom-checkbox-icon"
            onClick={() => {
              props.selectList(props.data.id);
            }}
            key={`id_${props.data.id};`}
          >
            {checkSelect(props.data.id) ? icons.check : icons.unCheck}
          </span>
          <span>{props.data.title}</span>
        </div>
      </li>
    );
  } else {
    return null;
  }
};

class ModalAssignToList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      success: false,
      errorMsg: "",
      successMsg: "",
      allTags: [],
      allListToShow: [],
      storedListGroup: [],
      submitEnable: false,
      selectedLists: [],
      submittingContact: false,
      searchKey: null,
      new: false,
      contactTags: [],
      newTagName: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.getAllContactList();
  }

  getAllContactList = async () => {
    try {
      this.setState({
        loading: true,
      });
      const response = await getContactListForDropdown();
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.list
      ) {
        this.setAllShowList(response.data.data.list);
      } else {
        this.setAllShowList([]);
      }
      this.setState({
        loading: true,
      });
    } catch (err) {
      this.setAllShowList([]);
      this.setState({
        loading: true,
      });
    }
  };

  setAllShowList = (initialListGroup) => {
    if (
      this.props.contactSavedList !== undefined &&
      this.props.contactSavedList != null &&
      this.props.contactSavedList.length > 0
    ) {
      this.props.contactSavedList.map(
        (item, index) =>
          (initialListGroup = initialListGroup.filter(
            (each) => each.id !== item.list_id
          ))
      );
    }
    this.setState({
      allListToShow: initialListGroup,
      storedListGroup: initialListGroup,
    });
  };

  addToList = () => {
    const payload = {
      contactDetails: this.props.markerContactDetails,
      listIds: this.state.selectedLists,
    };
    saveContactFromMapSidebar(payload)
      .then((res) => {
        if (res && res.data.success) {
          window.showNotification("success", res.data.message);

          this.props.onClose();
          this.props.handleCloseModal()
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          );
        }
      })
      .finally(() => {
        this.setState({
          submittingContact: false,
        });
      });
  };

  handleSubmit = () => {
    if (this.state.selectedLists[0]) {
      this.setState({
        submittingContact: true,
      });
      this.addToList();
    } else {
      window.showNotification("ERROR", "No list selected");
    }
  };

  handelRadio = (value) => {
    let selectedLists = this.state.selectedLists;
    selectedLists[0] = value;
    this.setState({
      selectedLists: selectedLists,
    });
  };

  changeSearch = (e) => {
    const searchKey = e.target.value.toUpperCase().trim();

    if (searchKey === "") {
      this.setState({
        allListToShow: this.state.storedListGroup,
        searchKey: "",
      });
      return;
    }
    let listData = [];
    listData = this.state.storedListGroup.filter((data) => {
      if (data.title.toUpperCase().includes(searchKey)) {
        return data;
      } else return null;
    });

    this.setState({
      allListToShow: listData,
      searchKey: searchKey,
    });
  };

  render() {
    return (
      <GlobalModal
        {...this.props}
        title="Add to List"
        buttonText="Add"
        buttonIcon={<Save />}
        onSubmit={() =>
          this.state.submittingContact ? "" : this.handleSubmit()
        }
        submitButtonDisable={this.state.submittingContact}
        hideFooter={false}
        className="manage-tag-modal modal-assign-campaign update create-new-list global-xs-modal enhanceDataModal"
      >
        {this.state.submittingContact && (
          <NewLoader
            loading={this.state.submittingContact || this.state.loading}
            onlyLoader={true}
            size={26}
            message={""}
          />
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="campaign-search-list">
              <div className="campaign-search form">
                <div className="form-group m-0">
                  <div className="m-input-icon m-input-icon--right assign__tag_search_wrapper">
                    <input
                      onChange={this.changeSearch}
                      type="text"
                      className="form-control m-input customFormControlField custom-popup-search"
                      placeholder="Type and search"
                    />
                    <SearchOutlined className="assign__searchIcon" />
                    <span className="m-input-icon__icon m-input-icon__icon--right">
                      <span>
                        <i className="flaticon-search-1" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="campaign-search-results upload-single-item-main">
                <ul className="m-nav height200px awesome__scroll_bar update__list_item">
                  {(this.state.allListToShow === undefined ||
                    this.state.allListToShow == null ||
                    !Array.isArray(this.state.allListToShow) ||
                    this.state.allListToShow.length === 0) &&
                  !this.state.loading ? (
                    <ListNotFound />
                  ) : (
                    this.state.allListToShow.map((data, index) => (
                      <EachList
                        selectList={this.handelRadio}
                        data={data}
                        key={index}
                        selectedLists={this.state.selectedLists}
                      />
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </GlobalModal>
    );
  }
}
export default ModalAssignToList;
