import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux'
import Loading from './assets/Loading';
import { getCategoryList } from './redux/cardBroadcastAction';
import SkeletonLoader from '../Common/SkeletonLoader';

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const NEXTSTEPNO = 2;
const FirstStep = (props) => {

    const [giftList, setGiftList] = useState([])
    const [hasGiftInSystem, setHasGiftInSystem] = useState(true)

    const [loadingGift, setLoadingGIFT] = useState(true)


    useEffect(() => {
        // updateSettings();
        getGiftList();
    }, []);

    const getGiftList = async() => {
        if (props.messageType === GIFT) {
            await props.getCategoryList({ 'message_type': props.messageType }, (response) => {
                setGiftList(response.data.gifts)
                props.setSelectedGift(response.data.gifts[0])
                setHasGiftInSystem(response.data.has_system_gift)
                setLoadingGIFT(false)
                props.setUseThanksIo(response.data.doUseThanksIOGateway);
            });
        }
    }
    return (
        <React.Fragment>

            <div className={"fieldset__fixed__height "+ (props.loadingThirdPartyInfo? 'fieldset__fixed__dynamic__height':'')}>
            <fieldset>
                <div className="custom-form-card form-card">
                    <div className="row">
                        <div className="col s12">
                            <div className="first-step-page-main2 first-step-page-main">
                                <div className="first-page-inner-item">
                                <span>Select Your {props.messageType === POSTCARD ? 'Postcard Size' : 'GIFT'}</span>
                                </div>
                                <div className="select__photo__size__box"> 
                                    <div className="select__photo__size__box_one">

                                    {props.cardSizes.length > 0 && (props.messageType == POSTCARD || props.messageType == LETTER) &&

                                        props.cardSizes.map((postcardSize, index) => (
                                            <div className={"select-photo-size-single " + (props.selectedCardSize == postcardSize ? "is_select" : "")}
                                                onClick={() => props.setSelectedCardSize(postcardSize)} key={`postcardSize${postcardSize}`}>
                                                <a href="javascript:void(0)">
                                                    <svg width="111" height="74" viewBox="0 0 111 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="1" y="1" width="109" height="72" fill="white" stroke="#546376" strokeWidth="2" />
                                                        <rect x="17" y="55" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                        <rect x="17" y="46" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                        <rect x="17" y="37" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                        <rect x="72" y="16" width="29" height="22" fill="#E5E8EF" />
                                                    </svg>
                                                </a>
                                                <span>{postcardSize}</span>
                                            </div>
                                        ))
                                        }
                                        {props.messageType == GIFT && loadingGift && hasGiftInSystem &&
                                            <div style={{paddingLeft:'60px'}}>
                                                <SkeletonLoader />
                                            </div>
                                        }
                                        {props.messageType == GIFT && !loadingGift && hasGiftInSystem &&
                                            giftList.map((giftInfo, index) => (
                                                // index <4 &&
                                                    // <div className={"select-photo-size-single " + (props.selectedGift.gift_product_sku == giftInfo.gift_product_sku ? "is_select" : "")}
                                                    // onClick={() => props.setSelectedGift(giftInfo)}>

                                                    <div className={"select-photo-size-single " + (props.selectedGift.gift_product_sku == giftInfo.gift_product_sku ? "is_select" : "")}
                                                    onClick={() => props.setSelectedGift(giftInfo)}>
                                                        <img src={giftInfo.gift_image_url}/>
                                                    {/* <a href="#">
                                                        <svg width="111" height="74" viewBox="0 0 111 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="1" y="1" width="109" height="72" fill="white" stroke="#546376" strokeWidth="2" />
                                                            <rect x="17" y="55" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                            <rect x="17" y="46" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                            <rect x="17" y="37" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                            <rect x="72" y="16" width="29" height="22" fill="#E5E8EF" />
                                                        </svg>
                                                    </a> */}
                                                    {/* <a href="#">
                                                        <svg width="111" height="74" viewBox="0 0 111 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="1" y="1" width="109" height="72" fill="white" stroke="#546376" strokeWidth="2" />
                                                            <rect x="17" y="55" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                            <rect x="17" y="46" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                            <rect x="17" y="37" width="27" height="2" fill="white" stroke="#E5E8EF" strokeWidth="2" />
                                                            <rect x="72" y="16" width="29" height="22" fill="#E5E8EF" />
                                                        </svg>
                                                    </a> */}
                                                    <span>{giftInfo.gift_product_sku}</span>
                                                </div>
                                                
                                                
                                            ))
                                        }
                                    </div>                                  
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* <!-- common button multi --> */}
            </fieldset>
           
            </div>

            {/* <!-- common button multi --> */}
            {/* <button className="custom-next next action-button accent--bg--color" onClick={(e) => props.moveNextStep(e, NEXTSTEPNO)}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26 13C26 5.82063 20.1794 0 13 0C5.82063 0 0 5.82063 0 13C0 20.1794 5.82063 26 13 26C20.1794 26 26 20.1794 26 13ZM12.7094 18.71C12.6161 18.6175 12.542 18.5076 12.4913 18.3864C12.4405 18.2653 12.4141 18.1353 12.4136 18.004C12.4131 17.8726 12.4384 17.7425 12.4882 17.6209C12.538 17.4994 12.6112 17.3889 12.7038 17.2956L15.9738 14H7.625C7.35978 14 7.10543 13.8946 6.91789 13.7071C6.73036 13.5196 6.625 13.2652 6.625 13C6.625 12.7348 6.73036 12.4804 6.91789 12.2929C7.10543 12.1054 7.35978 12 7.625 12H15.9738L12.7038 8.70437C12.6113 8.61105 12.538 8.50043 12.4883 8.37881C12.4385 8.2572 12.4132 8.12698 12.4138 7.99558C12.4144 7.86419 12.4409 7.73419 12.4917 7.61302C12.5425 7.49185 12.6167 7.38188 12.71 7.28938C12.8033 7.19687 12.9139 7.12366 13.0356 7.07392C13.1572 7.02417 13.2874 6.99886 13.4188 6.99944C13.5502 7.00002 13.6802 7.02648 13.8014 7.0773C13.9225 7.12812 14.0325 7.2023 14.125 7.29563L19.0869 12.2956C19.2727 12.4829 19.377 12.7361 19.377 13C19.377 13.2639 19.2727 13.5171 19.0869 13.7044L14.125 18.7044C14.0325 18.7978 13.9225 18.8721 13.8012 18.923C13.6799 18.9738 13.5498 19.0003 13.4184 19.0008C13.2869 19.0013 13.1566 18.9759 13.0349 18.926C12.9132 18.8761 12.8026 18.8027 12.7094 18.71Z" fill="white" />
                    </svg>
                    <span>Save & Continue</span>
                </button>
                <button className="custom-next custom-close modal-close action-button" onClick={(e) => props.closeModal(e)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white" />
                    </svg>
                    <span>Close</span>
                </button> */}
        </React.Fragment>

    )
}

const mapStateToProps = state => {
    return {
       categoryList: state.cardBroadcastReducer.categoryList,
    };
 };
 
 const mapDispatchToProps = dispatch => {
    return {
       getCategoryList: (params, callback) => dispatch(getCategoryList(params, callback)),
    };
 }
 
 export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);
