import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { ContactDetailsStyle } from "../../ContactDetailsStyle";
import CustomTitle from "../../common/CustomTitle";
import EachPhoneNumber from "../../common/EachPhoneNumber";
import { ContactDetailsV2Context } from "../../ContactDetailsV2Reducer";
import ValidationModal from "../../common/ValidationModal";
import Icons from "../../../Icons";
import SkipTraceActionButton from "../skipTraceActionButton/SkipTraceActionButton";

const useStyles = makeStyles({
  checkDNC: {
    color: "black !important",
    background: "rgb(0, 255, 145) !important",
    padding: "1px 14px !important",
    borderRadius: "20px !important",
    "&:hover , &:focus": {
      background: "rgb(0, 255, 145) !important",
    },
  },
});

const RelatedContacts = (props) => {
  const { flexCenterBetween, flexCenter, textDarkBlue, textBold600 } =
    ContactDetailsStyle();
  const [skipTrace, setSkipTrace] = useState(true);
  const [validateEmail, setValidateEmail] = useState(false);
  const [openValidationModal, setOpenValidationModal] = React.useState(false);
  const [showEmailValidationIcon, setShowEmailValidationIcon] = useState(null);

  const handleCloseValidationModal = () => {
    setOpenValidationModal(false);
  };
 
  const handleOpenValidationModal = () => {
    setOpenValidationModal(true);
    setValidateEmail(true);
  };


  const classes = useStyles();

  return (
    <Box>
      <Box className={flexCenter} sx={{ gap: 10 }} mb={1}>
        <CustomTitle
          title={"Related Contacts"}
          sx={{ display: "flex", flex: 1, alignItems: "center" }}
        />
      </Box>
      <Paper variant="outlined" elevation={0} style={{ padding: 15 }}>
        {props.relatedContactdata?.map((contactData, i) => (
          <Box key={i}>
            <Box
              className={flexCenterBetween}
              sx={{
                alignItems: "normal !important",
                flexWrap: "wrap",
                gap: 15,
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{ height: "auto" }}
                  className={`${textDarkBlue} ${textBold600}`}
                >
                  {contactData?.firstName} {contactData?.middleName}{" "}
                  {contactData?.lastName} <br />
                </Typography>
                {contactData?.email && (
                  <Box
                    sx={{ display: "flex", gap: 10, alignItems: "center" }}
                  >
                    <Typography
                      variant="body1"
                      className={`${textDarkBlue} ${textBold600}`}
                    >
                      Email:&nbsp;{contactData.email}
                    </Typography>
                    {showEmailValidationIcon === "VALID" ? (
                      <IconButton disableElevation> {Icons.valid}</IconButton>
                    ) : showEmailValidationIcon === "INVALID" ? (
                      <IconButton disableElevation>
                        {" "}
                        {Icons.invalid}
                      </IconButton>
                    ) : (
                      <Button
                        disableElevation
                        className={classes.checkDNC}
                        variant="contained"
                        onClick={handleOpenValidationModal}
                      >
                        {" "}
                        Validate
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            {contactData?.number && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  mt: 2,
                }}
              >
                <EachPhoneNumber
                  markerContactDetails={props.markerContactDetails}
                  skipTrace={skipTrace}
                  number={contactData.number}
                  status={contactData.numberValidationStatus}
                  dnc={contactData.dncStatus}
                />
              </Box>
            )}
            <SkipTraceActionButton addSuccess={props.addSuccess} state={props.state} markerContactDetails={props.markerContactDetails} handleCloseModal={props.handleCloseModal}/>
            <ValidationModal
              skipTrace={skipTrace}
              email={contactData.email}
              handleCloseModal={props.handleCloseModal}
              validateEmail={validateEmail}
              handleClose={handleCloseValidationModal}
              openValidationModal={openValidationModal}
              markerContactDetails={props.markerContactDetails}
              setShowEmailValidationIcon={setShowEmailValidationIcon}
            />
          </Box>
        ))}
        {
          props.relatedContactdata.length===0 && <Typography>No Data Found</Typography>
        }
      </Paper>
    </Box>
  );
};

export default RelatedContacts;
