import { Button } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import moment from "moment";
import { useState } from "react";
import { submitMapFinderContactCampaignSubmit } from "../../api/mapApi";
import Utils from "../../helpers/Utils";
import DateTimeSetting from "../Common/DateTimeSetting";
import GlobalModal from "../globals/Modal/GlobalModal";

const TYPE_MINUTE = 1
const TYPE_HOUR = 2
const TYPE_DAY = 3

const QUANTITY_MINUTE_MAX = 20;
const QUANTITY_HOUR_MAX = 200;
const QUANTITY_DAY_MAX = 1000;

const process_type = [
    {id: 1, value: TYPE_MINUTE, label: 'Minute'},
    {id: 1, value: TYPE_HOUR, label: 'Hour'},
    {id: 1, value: TYPE_DAY, label: 'Day'}
]

const DripModeModal = (props) => {

    const [date,setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [time,setTime] = useState(new Date());
    const [batchQuantity,setBatchQuantity] = useState(1);
    const [repeatAfterDays,setRepeatAfterDays] = useState(1);
    const [sendOn,setSendOn] = useState({'Mon':true,'Tue':true,'Wed':true,'Thu':true,'Fri':true,'Sat':true,'Sun':true});

    /* drip process new changes */
    const [processType, setProcessType] = useState(TYPE_MINUTE)

    /* for quantity limit */
    const [maxQuantity, setMaxQuantity] = useState(QUANTITY_MINUTE_MAX)

    const submit = () => {
        let batchForm  = {
            'start_from' : moment(date).format('YYYY-MM-DD') + ' ' +  moment(time).format("HH:mm"),
            'quantity' : batchQuantity,
            'repeat_after_days' : repeatAfterDays,
            'send_on' : sendOn,
            'campaign_id' : props.campaignId,
            'batch_process_type': processType
        }
        submitMapFinderContactCampaignSubmit(batchForm).then(res =>{
            if(res.data.response.status === 'success') {
                Utils.showNotification(res.data.response.html,'SUCCESS');
                props.onClose();
            } else if (res.data.response.status === 'validation-error') {
                Utils.showNotification(res.data.response.html[Object.keys(res.data.response.html)[0]][0],'ERROR');
            } else {
                Utils.showNotification(res.data.response.html,'ERROR');
            }
        });
    }

    const timeSetup = (value, action) => {
        if(action ==='date'){
            setDate(moment(value).format("MM/DD/YYYY"));
        }else{
            setTime(value);
        }
    }

    const getSelectDays = () => {
        return Object.keys(sendOn).map((item,i) =>
            (<Button 
                className={"mr-1"}
                variant="contained"
                color={sendOn[item] ? "primary" : "default"}
                onClick={() => setSendOn(Object.assign({}, sendOn, {[item]: !sendOn[item]})) }
                >{item}</Button>));
    }

    /* for drip new changes */
    const getProcessTypes = () => {
        let view = [];
        process_type.forEach((item, index) => {
            view.push(
                <option value={item.value} key={index}>{item.label}</option>
            )
        })
        return view;
    }

    const handleProcessTypeChange = (e) => {
        let value = e.target.value;
        if(value == TYPE_HOUR){
            if(repeatAfterDays > 23){
                setRepeatAfterDays(23)
            }
            if(batchQuantity > QUANTITY_HOUR_MAX){
                setBatchQuantity(QUANTITY_HOUR_MAX)
            }
            setMaxQuantity(QUANTITY_HOUR_MAX)
        }
        else if(value == TYPE_DAY){
            if(repeatAfterDays > 365){
                setRepeatAfterDays(365)
            }
            if(batchQuantity > QUANTITY_DAY_MAX){
                setBatchQuantity(QUANTITY_DAY_MAX)
            }
            setMaxQuantity(QUANTITY_DAY_MAX)
        }else{
            if(repeatAfterDays > 59){
                setRepeatAfterDays(59)
            }
            if(batchQuantity > QUANTITY_MINUTE_MAX){
                setBatchQuantity(QUANTITY_MINUTE_MAX)
            }
            setMaxQuantity(QUANTITY_MINUTE_MAX)
        }
        setProcessType(value)
    }

    const handleRepeatTimeChange = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setRepeatAfterDays("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(processType == TYPE_MINUTE){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 59){
                    setRepeatAfterDays(59)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            else if(processType == TYPE_HOUR){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 23){
                    setRepeatAfterDays(23)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            else if(processType == TYPE_DAY){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 365){
                    setRepeatAfterDays(365)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
        }
    }

    const onFocusOutTime = (e) => {
        if(e.target.value === ""){
            setRepeatAfterDays(1)
        }
    }

    const handleBatchQuantity = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setBatchQuantity("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(value > maxQuantity){
                setBatchQuantity(maxQuantity)
            }
            else{
                setBatchQuantity(value)
            }
        }
    }

    return ( 
        <GlobalModal 
            open={props.open} 
            onSubmit={submit} 
            closeButtonIcon={''} 
            buttonIcon={<Send/>} 
            buttonText="Add queue to assign" 
            onClose={() => props.onClose(false)} 
            title="Send in drip mode" 
            className="instance__modal">
            <div className="form-group m-form__group row">
                <div className="col-md-8">
                    <label htmlFor="start_on" className="form-control-label">
                        Start On <span className="text-danger">*</span>
                    </label>
                    <div>
                        <DateTimeSetting timeSettingValue={timeSetup} time={time} date={date}/>
                    </div>
                </div>

                <div className="send__drip__process__type__batch__wr">

                    <div className="col-md-8 text-left send__drip__process__type__batch__single">
                        <label htmlFor="batch_quantity" className="form-control-label"> Process Type </label>
                        <select name="process_type" className="d-block" value={processType} onChange={handleProcessTypeChange}>
                            {getProcessTypes()}
                        </select>
                    </div>

                    <div className="col-md-8 send__drip__process__type__batch__single">
                        <label htmlFor="batch_quantity" className="form-control-label">
                            Batch Quantity <span className="text-danger">*</span>
                        </label>
                        <div>
                            <input className="form-control" id="batch_quantity" name="batch_quantity" value={batchQuantity} onChange={handleBatchQuantity}/>
                        </div>
                        <span><small>Max quantity limit: {maxQuantity}</small></span>
                    </div>

                    <div className="col-md-8 text-left send__drip__process__type__batch__single">
                        <label htmlFor="repeat_after_days" className="form-control-label"> Repeat After ({processType == TYPE_MINUTE ? "Minutes" : (processType == TYPE_HOUR ? "Hours" : "Days")}) <span className="text-danger">*</span> </label>
                        <input className="form-control global__form_control" id="repeat_after_days" name="repeat_after_days" value={repeatAfterDays} onChange={handleRepeatTimeChange} onBlur={onFocusOutTime} />
                    </div>

                </div>

                <div className="col-md-8">
                    <label className="form-control-label">
                        Send On <span className="text-danger">*</span>
                    </label>
                    <div>
                        <div className="contact__assign__modal__get__day__wr">
                            {getSelectDays()}
                        </div>
                    </div>
                </div>
            </div>
        </GlobalModal>
    );
}
 
export default DripModeModal;