/* icons */
import { Person } from "@material-ui/icons";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../../assets/CustomAccordion";
import icons from "../../assets/icons";
import { getContactDetails, _updateStateField } from "../../redux/contactDetailAction";
import { getPeopleActivityApi, markAsDoneApi } from "../../redux/contactDetailApi";
import ActivityModal from "../ActivityModal";
import AddAppointMent from "./AddAppointMent";




const mapStateToProps = state => ({
    contactDetailReducer: state.contactDetailReducer,
    loggedOnUser: state.contactDetailReducer.loggedOnUser,
    fullContactDetails: state.contactDetailReducer.fullContactDetails
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params)),
    getContactDetails: params => dispatch(getContactDetails(params))
})

const App = (props) => {

    const {
        contactId, contactDetails, fetchingContactDetail, deletingContact,
        showingSmsSendModal, showingEmailSendModal, showingUpdateContactModal
    } = props.contactDetailReducer

    const [expand, setExpand] = useState(false)
    const [activity, setActivity] = useState([])
    const [doneActivity, setDoneActivity] = useState([])
    const [loading, setLoading] = useState(false)
    const [showAddAppointModal, setShowAddAppointModal] = useState(false)

    const [editAppointment, setEditAppointment] = useState(null)
    const [editData, setEditData] = useState(null)

    const [showDoneTask, setShowDoneTask] = useState(false)

    useEffect(() => {
        if(expand){
            setLoading(true)
            getPeopleActivityApi({contact_id: contactId}).then(response => {
                let res = response.data
                let filter_data = res.filter(item => (item.task_id != 2 && item.status == 1))
                setActivity(filter_data)

                let filter_done_data = res.filter(item => (item.task_id != 2 && item.status == 2))
                setDoneActivity(filter_done_data)
                setLoading(false)
            })
        }
    }, [expand])

    const refresh = () => {
        getPeopleActivityApi({contact_id: contactId}).then(response => {
            let res = response.data
            let filter_data = res.filter(item => (item.task_id != 2 && item.status == 1))
            setActivity(filter_data)

            let filter_done_data = res.filter(item => (item.task_id != 2 && item.status == 2))
            setDoneActivity(filter_done_data)
        })
    }

    const editActivity = (id, data) => {
        setEditAppointment(id)
        setEditData(data)
        setShowAddAppointModal(true)
    }

    return(
        <div className="left__column columnItem">
            <CustomAccordion expand={expand} className={"collapsible defaultToggle trigger__agency"}>
                <CustomAccordionHeader onClick={() => setExpand(!expand)}  className="collapsible-header trigger__header border__bottom flex content-between items-center">
                <span className="trigger__subtitle"><Person /> Task </span>
                <div className="addicon_collaps">
                    <span className="d-inline-flex mr-2" onClick={(e) => {e.stopPropagation(); setShowAddAppointModal(true);}}><AddCircleIcon /></span>
                    {icons.caretIcon}
                </div>
                </CustomAccordionHeader>
                <CustomAccordionBody className="collapsible-body awesome__scroll_bar">
                     <div className="ds__custom_loading">
                        {loading &&
                        <span>Loading...</span>
                        }
                    </div>

                    {!loading &&
                    <>
                    <ul className="templateItems awesome__scroll_bar">
                        {activity.map((item, index) => {
                            return(
                                <List data={item} key={index} index={index} status={1} expand={() => setExpand(false)}  refresh={refresh} editActivity={editActivity}/>
                            )
                        })}
                        {activity.length == 0 &&
                            <span className="noValid">No active activity found</span>
                        }
                    </ul>

                    {doneActivity.length > 0 &&
                    <ul className="templateItems awesome__scroll_bar">
                        <span className="done_activity_title">Mark as done activity list : <span onClick={() => setShowDoneTask(!showDoneTask)} style={{textDecoration:'underline'}}>{showDoneTask ? 'Hide' : 'Show'}</span></span>
                        {showDoneTask &&
                        <>
                        {doneActivity.map((item, index) => {
                            return(
                                <List data={item} key={index} index={index} status={2}/>
                            )
                        })}
                        </>
                        }
                    </ul>
                    }
                    </>
                    }
                </CustomAccordionBody>
            </CustomAccordion>

            {/* add appointment modal */}
            {showAddAppointModal &&
            //   <AddAppointMent 
            //     open={showAddAppointModal} 
            //     onClose={() => {setShowAddAppointModal(false); setExpand(false)}} 
            //     activityId={editAppointment}
            //     editData={editData}
            //     task={true}
            //     taskId={1} 
            // />
            <ActivityModal
                            openActivityModal={showAddAppointModal}
                            setOpenActivityModal={() => {setShowAddAppointModal(false); setExpand(false)}}
                            showAll={true}
                            slotInfo={[]}
                            fetchTask={() => console.log('task added')}
                            people={props.fullContactDetails.people}
                            from={"modalDetails"}
                        />
            }
        </div> 
    )
}
const Task = connect(mapStateToProps, mapDispatchToProps)(App);
export default Task;

const ListApp = (props) => {
    const {data, index, status, expand, refresh} = props
    const [markAsDone, setMarkAsDone] = useState(false)
    const [submitMarkAsDone, setSubmitMarkAsDone] = useState(false)

    const changeMarkAsDone = (task_id) => {
        if(submitMarkAsDone) return;
        if (props.loggedOnUser != null && props.fullContactDetails != null &&
            props.fullContactDetails.people.user_id != props.loggedOnUser.id
            ) {
                confirmAlert({
                title: 'Alert!',
                message: 'You can not add or update appointment to member user lead.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
            return false;
        }
        else{
            setMarkAsDone(true)
            setSubmitMarkAsDone(true)
            markAsDoneApi({id: data.id}).then(response => {
                setMarkAsDone(false)
                setSubmitMarkAsDone(false)
                props.getContactDetails({
                    id: props.contactDetailReducer.contactId
                })
                refresh()
            })
        }
    }

    const updateActivity = () => {
        return
        if(status == 1){
            props.editActivity(data.id, data)
        }
    }

    return(
        <li className="appointment_list_item d-flex">
            <div className="check_title d-flex">
            {(status == 1 && !markAsDone) &&
                <span onClick={changeMarkAsDone}><CheckBoxOutlineBlankIcon /></span>            
            }
            {(status == 1 && markAsDone) &&
                <CheckBoxIcon />            
            }
            </div>
            <div onClick={updateActivity}  className="date_author_title ml-3">
                <span className="m-widget2__text task">
                    <i className="fa fa-share"/>
                    {data.task_title}
                </span>
                <span className="m-widget2__text m--font-info">
                    <i className="fa fa-clock-o m--font-info"/>
                    {moment(data.date).format('MM/DD/YYYY hh:mm A')}
                </span>
                <span className="m-widget2__user-name">
                    <a href="#" className="m-widget2__link m--font-success">
                        <i className="fa fa-user m--font-success"/>By {props.fullContactDetails?.people?.user?.full_name}
                    </a>
                </span>
            </div>
        </li> 
    )
}
export const List = connect(mapStateToProps, mapDispatchToProps)(ListApp);