/* icons */
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import "../../assets/add_activity_modal.css";
import GetDurationOptions from "../../assets/GetDurationOptions";
import ModalGlobalModal from "../../modals/ModalGlobalModal";
import { getContactDetails, _updateStateField } from "../../redux/contactDetailAction";
import { submitUserActivityApi } from "../../redux/contactDetailApi";



const mapStateToProps = state => ({ 
    contactDetailReducer: state.contactDetailReducer,
    loggedOnUser: state.contactDetailReducer.loggedOnUser,
    fullContactDetails: state.contactDetailReducer.fullContactDetails
});
const mapDispatchToProps = dispatch => ({
    _updateStateField: params => dispatch(_updateStateField(params)),
    getContactDetails: params => dispatch(getContactDetails(params))
})

const App = (props) => {
    const { contactId, contactDetails } = props.contactDetailReducer

    const [date, setDate] = useState(new Date());
    const [taskId, setTaskId] = useState(props.taskId)
    const [title, setTitle] = useState('')
    const [invalidTitleError, setInvalidTitleError] = useState(false)
    const [titleLengthError, setTitleLengthError] = useState(false)

    const [description, setDescription] = useState('')
    const [invalidDescripotionError, setInvalidDescripotionError] = useState(false)
    const [descriptionLengthError, setDescriptionLengthError] = useState(false)

    const [startDate, setStartDate] = useState(new Date())
    const [activityTime, setActivityTime] = useState('07:00 AM')
    const [duration, setDuration] = useState('00:15')

    const [attendence, setAttendence] = useState(false)
    const [notify_by_email, setNotify_by_email] = useState(false)
    const [reminder, setReminder] = useState(false)
    const [reminder_time_period, setReminder_time_period] = useState('30')
    const [submiting, setSubmiting] = useState(false)

    useEffect(() => {
        if(props.editData != undefined && props.editData != null){
            const {editData} = props
            setTitle(editData.task_title)
            setDescription(editData.note)
            setStartDate(editData.date)
            setActivityTime(editData.time)
            setDuration(editData.duration)
            setAttendence(editData.send_invitation_to_attendence)
            setNotify_by_email(editData.notify_by_email)
        }
    }, [props.editData])

    const taskHandler = (task_id) => {
        setTaskId(task_id)
    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
        if(e.target.value != '') {
            if (!(e.target.value.match(/^(?!\.)[-A-Za-z0-9.#_ ]+$/))) {
                setInvalidTitleError(true)
            } else {
                setInvalidTitleError(false)
            }

            if (e.target.value.length > 255) {
                setTitleLengthError(true)
            } else {
                setTitleLengthError(false)
            }
        }else{
            setInvalidTitleError(false)
            setTitleLengthError(false)
        }
    }

    const handleDescription = (e) => {
        setDescription(e.target.value)
        if(e.target.value != '') {
            if (!(e.target.value.match(/(.*[a-zA-Z0-9])/))) {
                setInvalidDescripotionError(true)
            } else {
                setInvalidDescripotionError(false)
            }

            if (e.target.value.length > 1000) {
                setDescriptionLengthError(true)
            } else {
                setDescriptionLengthError(false)
            }
        }else{
            setInvalidDescripotionError(false)
            setDescriptionLengthError(false)
        }
    }

    const timeIntervalOptions = interval => {
        let option=[];
        for (let ap=1;ap<=2;ap++){
            for (let i=0;i<12;i++){
                for(let j=0;j<60/interval;j++){
                    let hv=i;let h=i;
                    if(i==0) h=12
                    if(ap!=1) hv= hv+12;
                    option.push(<option value={('0' + hv).slice(-2)+':'+('0' + interval*j).slice(-2)} data-content="<i class='fa fa-address-book-o' aria-hidden='true'></i>Option1">{('0' + h).slice(-2)+':'+('0' + interval*j).slice(-2)+' '+(ap==1?'AM':'PM')}</option>)
                }
            }
        }
        return option;
    }

    const timeChangeHandler = value => {
        setActivityTime(value)
    }

    const handleSubmit = () => {
        if (props.loggedOnUser != null && props.fullContactDetails != null &&
            props.fullContactDetails.people.user_id != props.loggedOnUser.id
            ) {
            confirmAlert({
                title: 'Alert!',
                message: 'You can not add or update any activity to member user lead.',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
            return false;
        }
        else{
            setSubmiting(true)
            submitUserActivityApi({
                task_title: title,
                note: description,
                time: activityTime,
                contact_number: contactDetails.number,
                contact_id: contactId,
                date: startDate,
                duration: duration,
                task_id: taskId,
                campaign_activity_id: null,
                id: props.activityId || null,
                send_invitation_to_attendence: attendence,
                notify_by_email: notify_by_email,
                closeDate: moment(new Date).format('MM/DD/YYYY'),
                deal_value: 0,
                user_stage_id: null,
                reminder: reminder,
                reminder_time_period: reminder_time_period,
                assigned_to: null
            }).then(response => {
                let res = response.data
                setSubmiting(false)
                if(res.status == 'success'){
                    alert(res.html.html)
                    props.onClose()
                }
                else{
                    alert("Something went wrong")
                }
            })
        }
    }

    return(
        <ModalGlobalModal
            open={props.open} 
            title="Activity" 
            onClose={props.onClose}
            buttonText={submiting ? 'Creating Activity' : 'Create Activity'}
            buttonIcon={''}
            handleSubmit={handleSubmit}
            width={500}
        >     
            <div className="addAppointment_wrapper global__add_appointment_container">
                {typeof props.task != 'undefined' &&
                <div className="row">
                    <div className="col-12">
                        <SelectTask 
                            taskHandler={taskHandler} 
                            task_id={taskId} 
                        />
                    </div>
                </div>
                }

                <div>
                    <label className="trigger__modal_title">
                        Title <span style={{color:'red'}}>*</span>
                    </label>
                    <input name="title" value={title} onChange={handleTitle}  type="text" className="ds__mb0Space form-control m-input m-input--square trigger__modal_textField" placeholder="Enter Title" />
                    {titleLengthError &&
                    <span>Title length should be max 255</span>
                    }
                    {invalidTitleError &&
                    <span>Invalid title [Accept: A-Z a-z 0-9 .-_#]</span>
                    }
                </div>

                <div className="mt20pxSpaceTitle ds__desc">
                    <label className="trigger__modal_title">
                        Description
                    </label>
                    <textarea name="description" value={description} onChange={handleDescription} className="ds__mb0Space form-control m-input min-textarea trigger__modal_textField" rows="2" />
                    {descriptionLengthError &&
                    <span>Description length should be max 1000</span>
                    }
                    {invalidDescripotionError &&
                    <span>Invalid description</span>
                    }
                </div>
            
                 <div>
                    
                    <div className="mt20pxSpaceTitle ds__datePicker">
                       <label className="trigger__modal_title">Date</label>
                       <DatePicker className="trigger__modal_textField trigger__modal_textField" selected={date} onChange={(date) => setDate(date)} />
                    </div>

                    <div className="mt20pxSpaceTitle">
                        <label class="trigger__modal_title">Time</label>
                        <select
                            onChange={(e) => timeChangeHandler(e.target.value)}
                            value={activityTime} 
                        >
                            <option value=''>Select time</option>
                            {timeIntervalOptions(15)}
                        </select>
                    </div>
                
                    <div className="col-4 mt20pxSpaceTitle"> 
                        <label class="trigger__modal_title">Duration (hh:mm)</label>
                        <GetDurationOptions value={duration} durationHandler = {(value) => setDuration(value)} />
                    </div>

                    <div className="ds__test mt20pxSpaceTitle" onClick={() => setAttendence(!attendence)}>
                        {attendence ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} Send email invitation to the attendee
                    </div>

                    <div  className="ds__test" onClick={() => setNotify_by_email(!notify_by_email)}>
                        {notify_by_email ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} Send email of information to me immediately
                    </div>

                    <div className="ds__test" onClick={() => setReminder(!reminder)}>
                        {reminder ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} Remind me
                    </div>
                    <div className="ds__dblock">
                        <select className="form-control m-input m-input--square mx-2" style={{display: 'unset', width: 'unset'}} value={reminder_time_period} onChange={(event) => setReminder_time_period(event.target.value)}>
                            <option value='5'>5 Minutes</option>
                            <option value='15'>15 Minutes</option>
                            <option value='30'>30 Minutes</option>
                            <option value='45'>45 Minutes</option>
                            <option value='60'>1 Hour</option>
                            <option value='120'>2 Hours</option>
                            <option value='360'>6 Hours</option>
                            <option value='720'>12 Hours</option>
                        </select>
                        before the event
                    </div>

                </div>
            </div>
        </ModalGlobalModal>
    )
}
const AddAppointMent = connect(mapStateToProps, mapDispatchToProps)(App);
export default AddAppointMent;

const SelectTask = (props) => {
    const {taskHandler, task_id} = props

    const [taskId, setTaskId] = useState(task_id)

    const handleTaskType = (id) => {
        setTaskId(id)
        taskHandler(id)
    }

    return(
        <div className="ds__activity_task_buttons">
            <div onClick={() => handleTaskType('1')} className={`common__round_circle ${taskId == '1' ? 'active' : ''}`}>
                Call
            </div>

            <div onClick={() => handleTaskType('2')} className={`common__round_circle ${taskId == '2' ? 'active' : ''}`}>
                Appointment
            </div>

            <div onClick={() => handleTaskType('3')} className={`common__round_circle ${taskId == '3' ? 'active' : ''}`}>
                Task
            </div>

            <div onClick={() => handleTaskType('4')} className={`common__round_circle ${taskId == '4' ? 'active' : ''}`}>
                Deadline
            </div>

            <div onClick={() => handleTaskType('5')} className={`common__round_circle ${taskId == '6' ? 'active' : ''}`}>
                Email
            </div>

            <div onClick={() => handleTaskType('6')} className={`common__round_circle ${taskId == '6' ? 'active' : ''}`}>
                Followup
            </div>

            <div onClick={() => handleTaskType('7')} className={`common__round_circle ${taskId == '7' ? 'active' : ''}`}>
                Others
            </div>
        </div>
    )
}